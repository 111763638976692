// import package
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const ViewDetail = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // props
    const { handleEditForm } = props;

    // redux-state
    const accountData = useSelector(state => state.account);
    const { firstName, lastName, phoneNo, emailStatus, phoneStatus, email, blockNo, address, state, city, postalCode, country } = accountData;

    return (
        <div className="profileDetailView">
            <div class="alert alert-info" role="alert">
                Your personal details should be introduced strictly <b>as indicated in your official identification documents</b> And your address should be as indicated in the proof of address that is required for verifying the account. If you have any doubts please write us at <a href="mailto:kyc@jncx.com">kyc@jncx.com</a>
            </div>
            <GridContainer>

                <GridItem xs={12} sm={12} md={6} lg={6}>

                    <h4>{t("PERSONAL_DETAILS")}</h4>

                    <ul>
                        <li>
                            <label>{t("FULL_NAME")}</label>
                            <span>{firstName}{' '}{lastName}</span>
                        </li>
                        {emailStatus && emailStatus == "verified" &&
                            <li>
                                <label>{t("EMAIL_PLACEHOLDER")}</label>
                                <span>{email}</span>
                            </li>}
                        {phoneStatus && phoneStatus == "verified" &&
                            <li>
                                <label>Phone Number</label>
                                <span>{phoneNo}</span>
                            </li>
                        }
                    </ul>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <h4>{t("RESIDENTIAL_ADDRESS")}</h4>
                    <ul>
                        <li>
                            <label>{t("ADDRESS")}</label>
                            <span>{address}</span>
                        </li>
                        <li>
                            <label>{t("STATE_PROVISION")}</label>
                            <span>{state}</span>
                        </li>
                        <li>
                            <label>{t("CITY")} & {t("POSTAL_CODE")}</label>
                            <span>{city}{' '}{postalCode}</span>
                        </li>
                        <li>
                            <label>{t("COUNTRY")}</label>
                            <span>{country}</span>
                        </li>
                    </ul>
                </GridItem>
            </GridContainer>
            <div className="form-group">
                <button
                    className="btn btn-primary text-uppercase py-2"
                    onClick={() => {
                        handleEditForm(accountData)
                    }}
                >
                    {t("EDIT_PROFILE")}
                </button>
            </div>
        </div>
    )
}

export default ViewDetail;