// import package
import React from "react";

// import component
import OpenTrade from "./OpenTrade";
import ClosedTrade from "./ClosedTrade";
import CompletedTrade from "./CompletedTrade";
import DisputeResolved from "./DisputeResolved";
const P2phistory = () => {
  return (
    <div className="dashboard_box dashHistoryTable">
      <h5 class="dash_subtitle">P2P TRADE HISTORY</h5>
      <nav>
        <div class="nav nav-tabs primaryNav" id="nav-tab" role="tablist">
          <a
            class="nav-item nav-link active"
            id="nav-recentTransaction-tab"
            data-toggle="tab"
            href="#nav-p2precentTransaction"
            role="tab"
            aria-controls="nav-recentTransaction"
            aria-selected="true"
          >
            Open Trades
          </a>
          <a
            class="nav-item nav-link"
            id="nav-loginHistory-tab"
            data-toggle="tab"
            href="#p2pnav-loginHistory"
            role="tab"
            aria-controls="nav-loginHistory"
            aria-selected="false"
          >
            Closed Trades
          </a>
          <a
            class="nav-item nav-link"
            id="nav-notificationHistory-tab"
            data-toggle="tab"
            href="#p2pnav-notificationHistory"
            role="tab"
            aria-controls="nav-notificationHistory"
            aria-selected="false"
          >
            Completed Trades
          </a>
          <a
            class="nav-item nav-link"
            id="nav-disputeHistory-tab"
            data-toggle="tab"
            href="#p2pnav-disputeHistory"
            role="tab"
            aria-controls="nav-disputeHistory"
            aria-selected="false"
          >
            Dispute Resolved Trades
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-p2precentTransaction"
          role="tabpanel"
          aria-labelledby="nav-recentTransaction-tab"
        >
          <OpenTrade />
        </div>
        <div
          class="tab-pane fade"
          id="p2pnav-loginHistory"
          role="tabpanel"
          aria-labelledby="nav-loginHistory-tab"
        >
          <ClosedTrade />
        </div>
        <div
          class="tab-pane fade"
          id="p2pnav-notificationHistory"
          role="tabpanel"
          aria-labelledby="nav-notificationHistory-tab"
        >
          <CompletedTrade />
        </div>
        <div
          class="tab-pane fade"
          id="p2pnav-disputeHistory"
          role="tabpanel"
          aria-labelledby="nav-disputeHistory-tab"
        >
          <DisputeResolved />
        </div>
      </div>
    </div>
  );
};

export default P2phistory;
