import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { ExpandMore } from "@material-ui/icons";

// import { useParams } from "react-router-dom";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import component
import LoginForm from '../components/LoginForm';

import {decryptAuthtoken} from "../lib/cryptoJS"

const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const dashboardRoutes = [];
export default function Login(props) {
  // const { authToken } = useParams();

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { ...rest } = props;


// redux
const theme = useSelector(state => state.theme)
  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />
      <div className="login_container">      
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-sm-12 col-md-6">
              <div className="logo_bg_shadow" data-aos="fade-up">
                <img src={require("../assets/images/logo_bg.svg")} alt="logo" className="img-fluid" />
              </div>
            </div> */}
            <div className="col-sm-12 col-md-6 mx-auto">
              <LoginForm />            
            </div>
          </div>
          <p className="text-center mt-3"><Link to="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;<Link to="/contact">Have an issue with Two-factor authentication?</Link></p>
        </div>
      </div>
    </div>
  );
}
