// import package
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ProofForm from './ProofForm';


const AddressProof = () => {
    const { t, i18n } = useTranslation();

    // redux-state
    const { addressProof } = useSelector(state => state.userKyc);

    return (
        <>
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <h3 className="dash_title mb-3">{t("ADDRESS_DOCUMENT_TITLE1")} <a href="javascript:void(0)" data-toggle="modal" data-target="#idProofNote"><small><i class="fas fa-info-circle"></i></small></a></h3>
                </div>
            </div>
            <div className="dashboard_box">
                <div className="kycIdentityDocument">
                    <GridContainer>
                        {
                            addressProof && ['new', 'rejected'].includes(addressProof.status) && <>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className="twoFAForm">
                                        <ProofForm />
                                    </div>
                                </GridItem>
                                
                            </>
                        }

                        {
                            addressProof && !['new', 'rejected','approved'].includes(addressProof.status) && <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="verifiedDocument">
                                    <h3>{t("ADDRESS_DOCUMENT_VERIFIED")}</h3>
                                    <p>{t("ADDRESS_DOCUMENT_DESCRIPTION5")} <a href="/support-ticket">{t("SUPPORT_TEAM")}</a></p>
                                </div>
                            </GridItem>
                        }
                        {
                            addressProof && ['approved'].includes(addressProof.status) && <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="verifiedDocument">
                                    <h3>{t("ADDRESS_DOCUMENT_VERIFIED1")}</h3>
                                    <p>{t("ADDRESS_DOCUMENT_DESCRIPTION5")} <a href="/support-ticket">{t("SUPPORT_TEAM")}</a></p>
                                </div>
                            </GridItem>
                        }
                    </GridContainer>
                </div>
                <div class="modal fade" id="idProofNote" tabindex="-1" aria-labelledby="idProofNoteLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Note</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    <div className="settingsNote">
                    <ul>
                        <li>{t("ADDRESS_DOCUMENT_DESCRIPTION1")}</li>
                        <li>{t("ADDRESS_DOCUMENT_DESCRIPTION2")}</li>
                        <li>{t("ADDRESS_DOCUMENT_DESCRIPTION3")}</li>
                        {/* <li>{t("ADDRESS_DOCUMENT_DESCRIPTION4")}</li> */}
                    </ul>
                </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default AddressProof;