// import package
import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "classnames";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";

import RecentTrade from "./RecentTrade";

// import context
import SocketContext from "../Context/SocketContext";

// import action
import { getOrderBook, orderBookDetail } from "../../actions/spotTradeAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toFixed } from "../../lib/roundOf";

const OrderBook = forwardRef((props, ref) => {
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();

  // props
  const { setExpandScreen, expandScreen, layout } = props;

  // state
  const [orderBook, setOrderBook] = useState({
    buyOrder: [],
    sellOrder: [],
  });
  const [orderBookType, setOrderBookType] = useState("all");
  const [showTotal, setShowTotal] = useState(true);
  const [showType, setShowType] = useState("single"); // single double

  const { buyOrder, sellOrder } = orderBook;

  // redux-state
  const tradePair = useSelector((state) => state.tradePair);
  const tickerData = useSelector((state) => state.marketPrice);

  // function
  const fetchOrderBook = async (pairId) => {
    try {
      const { status, loading, result } = await getOrderBook(pairId);
      if (status == "success") {
        setOrderBook(result);
      }
    } catch (err) {}
  };

  const handleOrderBook = (type) => {
    setOrderBookType(type);
  };

  useImperativeHandle(ref, () => ({
    orderBookResize(layout) {
      if (showType == "double") {
        if (layout && layout.w >= 4) {
          setShowTotal(true);
        } else {
          setShowTotal(false);
        }
      }
    },
    orderCount() {
      return {
        buyOrder: buyOrder.length,
        sellOrder: sellOrder.length,
      };
    },
  }));

  useEffect(() => {
    if (
      !isEmpty(tradePair) &&
      (tradePair.botstatus == "off" || tradePair.botstatus == "Off")
    ) {
      fetchOrderBook(tradePair.pairId);
    }
    setOrderBook({
      buyOrder: [],
      sellOrder: [],
    });

    // socket
    socketContext.socket.on("orderBook", (result) => {
      if (result.pairId == tradePair.pairId) {
        setOrderBook({
          buyOrder: result.buyOrder,
          sellOrder: result.sellOrder,
        });
      }
    });
    return () => {
      socketContext.socket.off("orderBook");
    };
  }, [tradePair]);

  // useEffect(() => {
  //     if (showType == 'double') {
  //         if (layout && layout.w >= 4) {
  //             setShowTotal(true)
  //         } else {
  //             setShowTotal(false)
  //         }
  //     }
  // }, [])

  let sellCount = 0,
    buyCount = 20;
  if (orderBookType == "sell") {
    buyCount = 0;
  } else if (orderBookType == "buy") {
    sellCount = 20;
  } else if (orderBookType == "all") {
    buyCount = 10;
    sellCount = 10;
  }

  return (
    <div className="tradeTableLeftSide darkBox orderBook">
      <div className="tableHead tradeFulltabbedTable">
        <nav>
          <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              {" "}
              Order Book
            </a>
            <a
              class="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Recent Trade
            </a>
          </div>
        </nav>
        <div className="inputGroup">
          <a
            href="#settingsOrderBook"
            className="ml-0 zoomIcon"
            data-toggle="collapse"
          >
            <i class="bi bi-gear-fill"></i>
          </a>
          <div class="collapse" id="settingsOrderBook">
            <div class="card card-body">
              <div className="inputGroup">
                <button
                  className="btn ml-0"
                  onClick={() => {
                    handleOrderBook("buy");
                  }}
                >
                  <img
                    src={require("../../assets/images/btn-green-dot.png")}
                    alt=""
                    className="img-fluid"
                  />
                </button>
                <button
                  className="btn ml-0"
                  onClick={() => {
                    handleOrderBook("sell");
                  }}
                >
                  <img
                    src={require("../../assets/images/btn-pink-dot.png")}
                    alt=""
                    className="img-fluid"
                  />
                </button>
                <button
                  className="btn ml-0"
                  onClick={() => {
                    handleOrderBook("all");
                  }}
                >
                  <img
                    src={require("../../assets/images/btn-green-pink-dot.png")}
                    alt=""
                    className="img-fluid"
                  />
                </button>
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="buySell"
                  name="buySell1"
                  value={showType}
                  onChange={(e) => {
                    setShowType(e.target.value);
                    if (e.target.value == "single") {
                      setShowTotal(true);
                    } else if (layout && layout.w >= 4) {
                      setShowTotal(true);
                    } else {
                      setShowTotal(false);
                    }
                  }}
                >
                  <FormControlLabel
                    value="single"
                    control={<Radio />}
                    label="Single"
                  />
                  <FormControlLabel
                    value="double"
                    control={<Radio />}
                    label="Double"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          {expandScreen == "" && (
            <a
              href="#"
              className="zoomIcon"
              onClick={() => {
                setExpandScreen("orderBook");
              }}
            >
              <i class="bi bi-arrows-angle-expand"></i>
            </a>
          )}
          {expandScreen == "orderBook" && (
            <a
              href="#"
              className="zoomIcon"
              onClick={() => {
                setExpandScreen("");
              }}
            >
              <i class="bi bi-arrows-angle-contract"></i>
            </a>
          )}
        </div>
      </div>
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div className="tradeTableBody">
            <div
              className={clsx("orderBookTwoColumn", {
                singleColumnOrderBook: showType == "single",
              })}
            >
              <div className="greenColumn">
                <div className="tradeTableTitle row w-100 mx-auto">
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal }
                    )}
                  >
                    Price
                  </span>
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal },
                      "text-right"
                    )}
                  >
                    Size
                  </span>
                  {showTotal && <span className="col-4 text-right">Total</span>}
                </div>
                {sellOrder &&
                  sellOrder.length > 0 &&
                  sellOrder.map((item, key) => {
                    let le = sellOrder.length;
                    let buyordeindex = sellOrder[0].total;
                    var precentagesell = (item.total / buyordeindex) * 100;
                    return (
                      <div
                        className={clsx(
                          "tradeTableBodyRow w-100",
                          { odd: key % 2 == 1 },
                          { even: key % 2 == 0 },
                          "row mx-auto"
                        )}
                      >
                        <div
                          className="redRangeBg"
                          style={{ width: `${precentagesell}%` }}
                        ></div>

                        <span
                          className={clsx(
                            { "col-4": showTotal },
                            { "col-6": !showTotal },
                            "pinkText"
                          )}
                          onClick={() => {
                            orderBookDetail(dispatch, {
                              price: toFixed(
                                item._id,
                                tradePair.secondFloatDigit
                              ),
                            });
                          }}
                        >
                          {toFixed(item._id, tradePair.secondFloatDigit)}
                        </span>
                        <span
                          className={clsx(
                            { "col-4": showTotal },
                            { "col-6": !showTotal },
                            "text-right"
                          )}
                          onClick={() => {
                            orderBookDetail(dispatch, {
                              price: toFixed(
                                item._id,
                                tradePair.secondFloatDigit
                              ),
                            });
                          }}
                        >
                          {toFixed(item.quantity, tradePair.firstFloatDigit)}
                        </span>
                        {showTotal && (
                          <span className={"col-4 text-right"}>
                            {toFixed(item.total, tradePair.firstFloatDigit)}
                          </span>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="tradeTableBodyRow even highLight row mx-auto w-100">
                <span className="col-12 pinkText pl-3 font-size-16">
                  <i className="fas fa-caret-down"></i>{" "}
                  {toFixed(tickerData.markPrice, tradePair.secondFloatDigit)}
                </span>
              </div>
              <div className="redColumn">
                <div className="tradeTableTitle row w-100 mx-auto">
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal }
                    )}
                  >
                    Price
                  </span>
                  <span
                    className={clsx(
                      { "col-4": showTotal },
                      { "col-6": !showTotal },
                      "text-right"
                    )}
                  >
                    Size
                  </span>
                  {showTotal && <span className="col-4 text-right">Total</span>}
                </div>
                {buyOrder &&
                  buyOrder.length > 0 &&
                  buyOrder.map((item, key) => {
                    let le = buyOrder.length;
                    let buyordeindex = buyOrder[le - 1].total;
                    var precentage = (item.total / buyordeindex) * 100;
                    return (
                      <div
                        className={clsx(
                          "tradeTableBodyRow w-100",
                          { odd: key % 2 == 1 },
                          { even: key % 2 == 0 },
                          "row mx-auto"
                        )}
                        // data-toggle="tooltip"
                        // data-placement="right"
                        // data-html="true"
                        // title="<b>Avg.Price:</b> = 7,138.75<br /><b>Sum BTC:</b> 1.302019<br /><b>Sum USDT:</b> 14,279.6600000"
                      >
                        <div
                          className="greenRangeBg"
                          style={{ width: `${precentage}%` }}
                        ></div>
                        <span
                          className={clsx(
                            { "col-4": showTotal },
                            { "col-6": !showTotal },
                            "greenText"
                          )}
                          onClick={() => {
                            orderBookDetail(dispatch, {
                              price: toFixed(
                                item._id,
                                tradePair.secondFloatDigit
                              ),
                            });
                          }}
                        >
                          {toFixed(item._id, tradePair.secondFloatDigit)}
                        </span>
                        <span
                          className={clsx(
                            { "col-4": showTotal },
                            { "col-6": !showTotal },
                            "text-right"
                          )}
                          onClick={() => {
                            orderBookDetail(dispatch, {
                              price: toFixed(
                                item._id,
                                tradePair.secondFloatDigit
                              ),
                            });
                          }}
                        >
                          {toFixed(item.quantity, tradePair.firstFloatDigit)}
                        </span>
                        {showTotal && (
                          <span className={"col-4 text-right"}>
                            {toFixed(item.total, tradePair.firstFloatDigit)}
                          </span>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <RecentTrade />
        </div>
      </div>
    </div>
  );
});

export default OrderBook;
