import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import {Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Footer from "components/Footer/Footer.js";

import SupportTicket from "components/SupportTicket"
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}

export default function SupportPage(props) {
  const { ...rest } = props;
   // redux
  const theme = useSelector(state => state.theme)
  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
        <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages">
      
      <SupportTicket/>
      
            {/* <div className="container">
              <GridContainer>
                  <GridItem xs={12} sm={12} md={5} lg={5}>
                    <h3 className="dash_title">Create Support Ticket</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7} lg={7}>
                    <ul className="profile_dash">
                     <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                     <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                   </ul>
                  </GridItem>
                </GridContainer>
              <div className="dashboard_box">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7} lg={8}>
                    <div className="contact_form settingsSelect mb-0">
                      <div className="form-group">
                        <label>Ticket For</label>
                        <Select value="0">
                        <MenuItem value={0}>Withdraw - Fiat -  Can not withdraw my fiat amount</MenuItem>
                        <MenuItem value={20}>Login Issue</MenuItem>
                        <MenuItem value={30}>Spot Trade</MenuItem>
                        <MenuItem value={40}>Two Factor & Password Update</MenuItem>
                        <MenuItem value={50}>General</MenuItem>
                      </Select>
                      </div>
                      <div className="form-group">
                        <label>Message to Support Team</label>
                        <textarea className="form-control" rows="4"></textarea>
                      </div>
                      <div className="form-group">
                        <button type="button" class="btn btn-primary text-uppercase py-2">Create Ticket</button>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5} lg={4}>
                    <div className="supportTicketList">
                      <h5 class="dash_subtitle">More About Support</h5>
                      <ul>
                        <li><a href="#">1. Login Issue</a></li>
                        <li><a href="#">2. Deposit Issue</a></li>
                        <li><a href="#">3. Withdraw Issue</a></li>
                        <li><a href="#">4. Spot Trade</a></li>
                        <li><a href="#">5. Derivative Trade</a></li>
                        <li><a href="#">6. Two Factor & Password Update</a></li>
                        <li><a href="#">7. General</a></li>
                      </ul>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>

              <div class="row align-items-center">
                <div class="col-lg-12">
                  <h3 class="dash_title mb-3">Support Details</h3>
                </div>
              </div>
                <div className="supporTicketAccordian wow fadeIn">
                             <div id="accordion">
                               <div className="card">
                                 <div className="card-header wow flipInX" id="headingOne">
                                   <h5 className="mb-0">
                                     <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       <span className="stHeadText subjectWidth"><small>Subject</small>Widthaw Issue - 2FA not working</span>
                                       <span className="stHeadText ticketIdWidth"><small>Ticket ID</small>#0004</span>
                                       <span className="stHeadText statusWidth"><small>Status</small><span className="yellowText">Open</span></span>
                                       <i className="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                   </h5>
                                 </div>

                                 <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                   <div className="card-body">
                                    <p className="metaChatDetails">Create on: 12 -05-2021 15:15</p>
                                     <div className="row">
                                       <div className="col-md-12">
                                         <ul className="ticketComments">
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportUserImg.jpg")} alt="" className="img-fluid" /></div>
                                               <p>User</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p>Hello Admin,</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                             </div>
                                           </li>
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportUserImg.jpg")} alt="" className="img-fluid" /></div>
                                               <p>Support Team</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p className="metaChatDetails">Admin reply on 01-06-2020  15:35</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                               <p>Thanks,<br />Admin</p>
                                             </div>
                                           </li>
                                         </ul>
                                         <div className="messageTypeBox contact_form">
                                           <div className="row">
                                             <div className="col-md-12">
                                                <div className="form-group">
                                                  <label className="d-block">Reply to admin</label>
                                                  <textarea rows="2" className="form-control"></textarea>
                                                </div>
                                             </div>
                                             <div className="col-md-12">
                                               <p className="submitChat">
                                                 <button type="button" class="btn btn-outline text-uppercase py-2">Reply</button>
                                                 <a href="#" className="ml-0 ml-sm-3">Satisfied, Close this ticket</a>
                                               </p>
                                             </div>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div className="card">
                                 <div className="card-header wow flipInX" id="headingTwo">
                                   <h5 className="mb-0">
                                     <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       <span className="stHeadText subjectWidth"><small>Subject</small>Widthaw Issue - not deposited my crypto on my wallet</span>
                                       <span className="stHeadText ticketIdWidth"><small>Ticket ID</small>#0004</span>
                                       <span className="stHeadText statusWidth"><small>Status</small><span className="yellowText">Open</span></span>
                                       <i className="fa fa-plus" aria-hidden="true"></i>
                                     </button>
                                   </h5>
                                 </div>
                                 <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                   <div className="card-body">
                                    <p className="metaChatDetails">Create on: 12 -05-2021 15:15</p>
                                     <div className="row">
                                       <div className="col-md-12">
                                         <ul className="ticketComments">
                                           <li>
                                            <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportUserImg.jpg")} alt="" className="img-fluid" /></div>
                                               <p>User</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p>Hello Admin,</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                             </div>
                                           </li>
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportAdminLogo.png")} alt="" className="img-fluid" /></div>
                                               <p>Support Team</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p className="metaChatDetails">Admin reply on 01-06-2020  15:35</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                               <p>Thanks,<br />Admin</p>
                                             </div>
                                           </li>
                                         </ul>
                                         <div className="messageTypeBox contact_form">
                                           <div className="row">
                                             <div className="col-md-12">
                                                <div className="form-group">
                                                  <label className="d-block">Reply to admin</label>
                                                  <textarea rows="2" className="form-control"></textarea>
                                                </div>
                                             </div>
                                             <div className="col-md-12">
                                               <p className="submitChat">
                                                 <button type="button" class="btn btn-outline text-uppercase py-2">Reply</button>
                                                 <a href="#" className="ml-0 ml-sm-3">Satisfied, Close this ticket</a>
                                               </p>
                                             </div>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div className="card">
                                 <div className="card-header wow flipInX" id="headingThree">
                                   <h5 className="mb-0">
                                     <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                      <span className="stHeadText subjectWidth"><small>Subject</small>Login Issue - password wrong</span>
                                       <span className="stHeadText ticketIdWidth"><small>Ticket ID</small>#0004</span>
                                       <span className="stHeadText statusWidth"><small>Status</small><span className="greenText">Closed</span></span>
                                       <i className="fa fa-plus" aria-hidden="true"></i>
                                     </button>
                                   </h5>
                                 </div>
                                 <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                   <div className="card-body">
                                    <p className="metaChatDetails">Create on: 12 -05-2021 15:15</p>
                                     <div className="row">
                                       <div className="col-md-12">
                                         <ul className="ticketComments">
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportUserImg.jpg")} alt="" className="img-fluid" /></div>
                                               <p>User</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p>Hello Admin,</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                             </div>
                                           </li>
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportAdminLogo.png")} alt="" className="img-fluid" /></div>
                                               <p>Support Team</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p className="metaChatDetails">Admin reply on 01-06-2020  15:35</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                               <p>Thanks,<br />Admin</p>
                                             </div>
                                           </li>
                                         </ul>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>

                               <div className="card">
                                 <div className="card-header wow flipInX" id="headingFour">
                                   <h5 className="mb-0">
                                     <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                       <span className="stHeadText subjectWidth"><small>Subject</small>Exchange Issue - fee not shown</span>
                                       <span className="stHeadText ticketIdWidth"><small>Ticket ID</small>#0004</span>
                                       <span className="stHeadText statusWidth"><small>Status</small><span className="greenText">Closed</span></span>
                                       <i className="fa fa-plus" aria-hidden="true"></i>
                                     </button>
                                   </h5>
                                 </div>
                                 <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                   <div className="card-body">
                                    <p className="metaChatDetails">Create on: 12 -05-2021 15:15</p>
                                     <div className="row">
                                       <div className="col-md-12">
                                         <ul className="ticketComments">
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportUserImg.jpg")} alt="" className="img-fluid" /></div>
                                               <p>User</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p>Hello Admin,</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                             </div>
                                           </li>
                                           <li>
                                             <div className="ticketUserDetails">
                                               <div className="userImg"><img src={require("../assets/images/supportAdminLogo.png")} alt="" className="img-fluid" /></div>
                                               <p>Support Team</p>
                                             </div>
                                             <div className="ticketDetails">
                                               <p className="metaChatDetails">Admin reply on 01-06-2020  15:35</p>
                                               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                                               <p>Thanks,<br />Admin</p>
                                             </div>
                                           </li>
                                         </ul>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
            </div> */}


          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
