import React, { useEffect } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from 'rc-checkbox';
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from '@material-ui/core/Switch';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from 'react-data-table-component';
import MenuItem from "@material-ui/core/MenuItem";
import {Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { useSelector } from "react-redux";
import TransactionHistory from '../components/TransactionHistory';

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return null;
}

// Fiat History Table
const fiatHistory = [
{ date: "11-05-2021 15:15", type: <span className="textDepositGreen">Deposit</span>, currency: "USD", amount: "1,500.50", transRef : "1235484556", bankAccount : "ICICI Bank - 1234 xxxx xxxx 1234", status : <div className="textStatusGreen">Completed</div>, },
{ date: "11-05-2021 15:15", type: <span className="textWithdrawRed">Withdraw</span>, currency: "Euro", amount: "1,500.50", transRef : "1235484556", bankAccount : "ICICI Bank - 1234 xxxx xxxx 1234", status : <div className="textStatusGreen">Completed</div>, },
{ date: "11-05-2021 15:15", type: <span className="textWithdrawRed">Withdraw</span>, currency: "USD", amount: "1,500.50", transRef : "1235484556", bankAccount : "ICICI Bank - 1234 xxxx xxxx 1234", status : <div className="textStatusOrange">Pending</div>, },
{ date: "11-05-2021 15:15", type: <span className="textDepositGreen">Deposit</span>, currency: "Rupees", amount: "1,500.50", transRef : "1235484556", bankAccount : "ICICI Bank - 1234 xxxx xxxx 1234", status : <div className="textStatusGreen">Completed</div>, },
{ date: "11-05-2021 15:15", type: <span className="textDepositGreen">Deposit</span>, currency: "USD", amount: "1,500.50", transRef : "1235484556", bankAccount : "ICICI Bank - 1234 xxxx xxxx 1234", status : <div className="textStatusGreen">Completed</div>, },
];
const fiatHistoryColumns = [
  {
    name: 'Date',
    selector: 'date',
    sortable: false,
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: false,
  },
  {
    name: 'Currency',
    selector: 'currency',
    sortable: false,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: false,
  },
  {
    name: 'Transaction Ref.',
    selector: 'transRef',
    sortable: false,
  },
  {
    name: 'Bank & Account',
    selector: 'bankAccount',
    sortable: false,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
];


// Crypto History Table
const cryptoHistory = [
{ date: "11-05-2021 15:15", type: <span className="textDepositGreen">Deposit</span>, crypto: "Bitcoin", amount: "0.00215487 BTC", address : <a href="#">12E7iTgXziTgXiTgX8KGp8KGp8KGpW8KGpMdqv</a>, status : <div className="textStatusGreen">Completed</div>, },
{ date: "11-05-2021 15:15", type: <span className="textWithdrawRed">Withdraw</span>, crypto: "Ethereum", amount: "0.00215487 ETH", address : <a href="#">12E7iTgXziTgXiTgX8KGp8KGp8KGpW8KGpMdqv</a>, status : <div className="textStatusOrange">Pending</div>, },
{ date: "11-05-2021 15:15", type: <span className="textWithdrawRed">Withdraw</span>, crypto: "Ripple", amount: "0.00215487 XRP", address : <a href="#">12E7iTgXziTgXiTgX8KGp8KGp8KGpW8KGpMdqv</a>, status : <div className="textStatusOrange">Pending</div>, },
{ date: "11-05-2021 15:15", type: <span className="textDepositGreen">Deposit</span>, crypto: "Litecoin", amount: "0.00215487 LTC", address : <a href="#">12E7iTgXziTgXiTgX8KGp8KGp8KGpW8KGpMdqv</a>, status : <div className="textStatusGreen">Completed</div>, },
{ date: "11-05-2021 15:15", type: <span className="textDepositGreen">Deposit</span>, crypto: "Tether", amount: "0.00215487 USDT", address : <a href="#">12E7iTgXziTgXiTgX8KGp8KGp8KGpW8KGpMdqv</a>, status : <div className="textStatusGreen">Completed</div>, },
];
const cryptoHistoryColumns = [
  {
    name: 'Date',
    selector: 'date',
    sortable: false,
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: false,
  },
  {
    name: 'Crypto',
    selector: 'crypto',
    sortable: false,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: false,
  },
  {
    name: 'Address',
    selector: 'address',
    sortable: false,
    width: "350px",
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: false,
  },
];


export default function History(props) {
const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
 const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;
    // redux
    const theme = useSelector(state => state.theme)

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
        <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />



          <div className="dashboardContent userPages">
         
         <TransactionHistory/>
            {/* <div className="container">
              <GridContainer>
                  <GridItem xs={12} sm={12} md={5} lg={5}>
                    <h3 className="dash_title">Fiat History</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7} lg={7}>
                    <ul className="profile_dash">
                     <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                     <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                   </ul>
                  </GridItem>
                </GridContainer>
              <div className="dashboard_box stakingHistoryTable">
                <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                  <div className="newsSelectGroup">
                    <label>Filter by</label>
                    <Select value="0">
                        <MenuItem value={0}>Withdraw</MenuItem>
                        <MenuItem value={20}>Deposit</MenuItem>
                        <MenuItem value={30}>Transfer</MenuItem>
                      </Select>
                      <Select value="0" className="marginSpace">
                        <MenuItem value={0}>USD</MenuItem>
                        <MenuItem value={20}>INR</MenuItem>
                        <MenuItem value={30}>AUD</MenuItem>
                        <MenuItem value={40}>EURO</MenuItem>
                        <MenuItem value={50}>SR</MenuItem>
                      </Select>
                      <div className="tableSearchBox">
                        <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search by Date / Trans.Ref / Bank" />
                          <div class="input-group-append">
                            <span class="btnType1"><i class="fas fa-search"></i></span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="newsSelectGroup">
                    <button className="btn btn-outline text-uppercase py-1 m-0">Download PDF</button>
                  </div>
                </div>
                <DataTable columns={fiatHistoryColumns} data={fiatHistory} noHeader />
              </div>

              <div class="row align-items-center">
                <div class="col-lg-12">
                  <h3 class="dash_title mb-3">Cryptocurrency History</h3>
                </div>
              </div>
              <div className="dashboard_box stakingHistoryTable">
                <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                  <div className="newsSelectGroup">
                    <label>Filter by</label>
                    <Select value="0">
                        <MenuItem value={0}>Withdraw</MenuItem>
                        <MenuItem value={20}>Deposit</MenuItem>
                        <MenuItem value={30}>Transfer</MenuItem>
                      </Select>
                      <Select value="0" className="marginSpace">
                        <MenuItem value={0}>USD</MenuItem>
                        <MenuItem value={20}>INR</MenuItem>
                        <MenuItem value={30}>AUD</MenuItem>
                        <MenuItem value={40}>EURO</MenuItem>
                        <MenuItem value={50}>SR</MenuItem>
                      </Select>
                      <div className="tableSearchBox">
                        <div class="input-group">
                          <input type="text" class="form-control" placeholder="Search by Date / Trans.Ref / Bank" />
                          <div class="input-group-append">
                            <span class="btnType1"><i class="fas fa-search"></i></span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="newsSelectGroup">
                    <button className="btn btn-outline text-uppercase py-1 m-0">Download PDF</button>
                  </div>
                </div>
                <DataTable className="historyCryptoTable" columns={cryptoHistoryColumns} data={cryptoHistory} noHeader />
              </div>
            </div> */}



          </div>


        </div>
      </div>
      <Footer />
    </div>
  );
}
