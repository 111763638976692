import React, { useEffect, useState } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTooltip from 'react-tooltip';
import Switch from '@material-ui/core/Switch';


import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import PostmyAd from "components/PostAd"
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Dashboard(props) {



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />

<PostmyAd/>

      {/* <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages peer2peer_section">
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Peer-to-Peer <span><i class="fas fa-chevron-right arrow_key_titlw"></i> </span> <span className="color_gol-sd">Post My Ad</span></h3>
                </GridItem>

              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>

                  <div className="dashboard_box t2patid">
                    <div className="end_content mar---butm"> <p className="color_whit_7 with_22_2  inputabowlabel"><span className="spna_ifd">Wallet:</span>0.25698789 BTC<span className="unde_lin_qwe">All</span></p></div>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>

                        <form className="contact_form mb-0 w100_form_p2p pa125sd">
                          <div className="settingsSelect clas_Newselect clas_Newselect124">
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6} lg={4} className="paddin_gri_po mt-151_15">
                                <div className="form-group d-flex_p2p d-flex_p2p_23">
                                  <label className="label_color_78">I want to </label>
                                  <Select value={10}>
                                    <MenuItem value={10}>Sell Cryptocurrency</MenuItem>
                                    <MenuItem value={20}>Sell Cryptocurrency</MenuItem>
                                    <MenuItem value={30}>Sell Cryptocurrency</MenuItem>
                                  </Select>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={4} className="paddin_gri_po mt-151_15">
                                <div className="form-group d-flex_p2p d-flex_p2p_23">
                                  <label className="label_color_78">Cryptocurrency</label>
                                  <Select value={10}>
                                    <MenuItem value={10}>Bitcoin</MenuItem>
                                    <MenuItem value={20}>Bitcoin</MenuItem>
                                    <MenuItem value={30}>Bitcoin</MenuItem>
                                  </Select>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={4} className="mt-151_15">

                                <div className="form-group d-flex_p2p d-flex_p2p_23">
                                  <label className="label_color_78">Quantity</label>
                                  <div class="input-group ">

                                    <input type="text" class="form-control" value="25,500.00" />
                                    <div class="input-group-append">
                                      <button type="button" className="inpor_inside_buttons">INR</button>
                                    </div>
                                  </div>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={4} className="paddin_gri_po mt-151_15">
                                <div className="form-group d-flex_p2p d-flex_p2p_23">
                                  <label className="label_color_78 label_widthsdf">Price</label>
                                  <div className="flot_fixe_ins width_input">
                                    <input type="text" className="form-control" value="0.00" />
                                    <Select value={10}>
                                      <MenuItem value={10}>US </MenuItem>
                                      <MenuItem value={20}>INR </MenuItem>
                                      <MenuItem value={30}>AUD </MenuItem>
                                    </Select>
                                  </div>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={8} className="paddin_gri_po mt-151_15">
                                <div className="form-group d-flex_p2p d-flex_p2p_23 whithAutft">
                                  <label className="label_color_78 ">per Bitcoin</label>
                                  <div className="bitcoin_value_box">
                                    <h3><span>Current Market Price </span>1 Bitcoin = 2,25,500.00 INR</h3>
                                  </div>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={7} className="mt-151_15">

                                <div className="form-group d-flex_p2p d-flex_p2p_23 flort_lesttd ">
                                  <label className="label_color_78">Price Limit From </label>
                                  <div class="input-group ">

                                    <input type="text" class="form-control" value="25,500.00" />
                                    <div class="input-group-append">
                                      <button type="button" className="inpor_inside_buttons">INR</button>
                                    </div>
                                  </div>

                                </div>
                                <div className="form-group d-flex_p2p d-flex_p2p_23 flort_lesttd ">
                                  <label className="label_color_78">To</label>
                                  <div class="input-group ">

                                    <input type="text" class="form-control" value="25,500.00" />
                                    <div class="input-group-append">
                                      <button type="button" className="inpor_inside_buttons">INR</button>
                                    </div>
                                  </div>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={5} className="mt-151_15">
                                <div className="form-group d-flex_p2p d-flex_p2p_23 flort_lesttd  w100_form_p2p date-width">
                                  <label className="primary_label" htmlFor="minimumbid">Show My Post Till</label>
                                  <div className="wdth--1001">
                                    <DatePicker className="form-control primary_inp" selected={startDate} onChange={(date) => setStartDate(date)} selectsStart dateFormat="dd/MM/yyyy h:mm aa" showTimeInput>

                                    </DatePicker>
                                    <i class="far fa-calendar-alt"></i>
                                  </div>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15">
                                <div className="form-group d-flex_p2p d-flex_p2p_23 whithAutft select_width">
                                  <label className="label_color_78">Preferred Payments</label>
                                  <Select value={10}>
                                    <MenuItem value={10}>Bank, UPI, Gpay</MenuItem>
                                    <MenuItem value={20}>Bank, UPI, Gpay</MenuItem>
                                    <MenuItem value={30}>Bank, UPI, Gpay</MenuItem>
                                  </Select>

                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={12} className="paddin_gri_po mt-151_15">
                                <p className="ckksdyt_box"><input type="checkbox" />I agree to Escrow my crypto funds and accept the <span><a href="">terms</a> </span> and <span> <a href="">policy</a></span></p>
                                <button type="button" className="btn btn-primary text-uppercase py-2 display_block_mars" data-toggle="modal" data-target="#confirm_post" >Submit Post</button>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </form>

                      </GridItem>
                    </GridContainer>

                  </div>
                </GridItem>

              </GridContainer>


            </div>
          </div>
        </div>
        <div class="modal fade buy_sellaction confirm_sellaction modalzindex" id="confirm_post" tabindex="-1" role="dialog" aria-labelledby="confirm_post" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

              <div class="modal-body">
                <div className="confirm_body ">
                  <h2>Confirm My Post</h2>
                  <img src={require("../assets/images/postconfirm.png")} alt="" className="img-fluid" />
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="righttext">Type:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">Sell</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Cryptocurrency:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">Bitcoin</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Price:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">2,50,500.00 INR</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Quantity: </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">0.00123456 BTC</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Limit:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">5,000.00 INR - 10,00,000 INR</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Payment Type:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">Bank, UPI</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Post Show Till:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">30-07-2021  15:15</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="righttext">Post ID:</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_fff">#123456</span>
                    </div>
                  </div>
                  <div className="new_confirm_pay_button mat_205">
                    <button className="button1 button2" data-dismiss="modal">Cancel</button>
                    <button className="button1 ">Confirm</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


        <Footer />
      </div> */}
    </div>
  );
}
