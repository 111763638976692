import React, { useEffect, useState } from "react";
import { Accordion, Card, Button } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getAllFaq } from '../actions/commonAction'
const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const FaqPage = (props) => {
  const { ...rest } = props;
  // state
  const [data, setData] = useState([])

  // function
  const fetchFaq = async () => {
    try {
      const { status, loading, result } = await getAllFaq()
      console.log('result',result)
      if (status == 'success') {
        setData(result)
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchFaq()
  }, [])

     // redux
     const theme = useSelector(state => state.theme)

  return (
    
    <div className="page_wrap">
         <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Helmet application" />
      </Helmet>
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />

<div className="dashboardContent userPages">
        <div className="container">
        <div class="col-lg-12 clas_heasdin aos-init aos-animate" data-aos="fade-up">
          <h1>FAQ</h1>
        </div>
          <section className="secFAQ cmsPages">
            <div className="container">
              <div className="cmsBoxWhiteBox pb-0">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-sm-12 ml-auto mr-auto">
                    <div className="faqCont">
                      <div className="homeAccordian wow fadeIn">

                        {
                          data && data.length > 0 && data.map((item, key) => {
                            return (
                              <div className="faqGridCard">
                                <h3>{item.categoryName}</h3>
                                <Accordion>

                                  {
                                    item && item.categoryDetails && item.categoryDetails.length > 0 && item.categoryDetails.map((el, index) => {
                                      return (
                                        <Card>
                                          <Card.Header>
                                            <h5 className="mb-0">
                                              <Accordion.Toggle as={Button} variant="link" eventKey={`${key}${index}`}>
                                                <span className="question">{index + 1}{'. '}{el.question}</span> <i className="fas fa-chevron-down"></i>
                                              </Accordion.Toggle>
                                            </h5>

                                          </Card.Header>
                                          <Accordion.Collapse eventKey={`${key}${index}`}>
                                            <Card.Body>
                                              <p>{el.answer}</p>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      )
                                    })
                                  }
                                </Accordion>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FaqPage;