import React, { useEffect } from "react";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTooltip from 'react-tooltip';
import Switch from '@material-ui/core/Switch';


import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import P2pBuySell from "components/P2pBuySell"

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Dashboard(props) {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <P2pBuySell/>
{/* 
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="dashboardContent userPages peer2peer_section">
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Peer-to-Peer</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                  <ul className="profile_dash">
                    <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                    <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                  </ul>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>

                  <div className="dashboard_box">

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6} lg={6}>

                        <div className="balance_details_panel pb-0">
                          <div className="balance_details_left">
                            <h5 className="dash_subtitle paddin_title_p">Estimated P2P Balance</h5>
                            <div className="flexDashBalance">
                              <div className="mr-5">
                                <h3>Total Account Balance</h3>
                                <h2 className="balance_color_dash">0.00455349 <span>BTC</span></h2>
                              </div>
                              <div>
                                <h3>Estimated Value:</h3>
                                <h4>$232.77</h4>
                              </div>
                            </div>


                          </div>
                          <div className="balance_details_right aligncontans_cen">
                            <div className="chartDash">
                              <img src={require("../assets/images/p2pimg.png")} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <div class="peer_clas_parant">
                          <div className="peer2peer_contant_first">
                            <h1>JNC Exchange P2P BUY/SELL YOUR CRYPTO LOCALLY</h1>
                            <p>Peer-to-peer exchange (or P2P exchange) is a marketplace where people can trade crypto
                              directly with each other on their own terms, in virtually any country.</p>
                            <button className="post_ter">Post new ad</button>
                          </div>
                        </div>

                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="recentad_section">
                          <div className="recent_title">
                            <h3>Recent Post Ad</h3>
                          </div>
                          <div className="Post_id">
                            <p><span>Post ID:</span> #123456</p>
                          </div>
                          <div className="Posted_date">
                            <p><span>Posted on:</span> 20 Jun 2021 15:15</p>
                          </div>
                          <div className="Sell_section">
                            <p><span>Sell:</span>$3,500.00 | 0.023659878 BTC</p>
                          </div>
                          <div className="Sell_section">
                            <p>Bank UPI</p>
                          </div>
                          <div className="link_section">
                            <p>[<a href="">Release</a>]</p>
                            <p>[<a href="">Edit My Post </a>]</p>

                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>

                  </div>
                </GridItem>

              </GridContainer>
              <div className="dashboard_box dashHistoryTable onlytab_bordernone border_po0">

                <nav>
                  <div class="nav nav-tabs primaryNav" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-recentTransaction-tab" data-toggle="tab" href="#nav-recentTransaction" role="tab" aria-controls="nav-recentTransaction" aria-selected="true">BTC </a>
                    <a class="nav-item nav-link" id="nav-loginHistory-tab" data-toggle="tab" href="#nav-loginHistory" role="tab" aria-controls="nav-loginHistory" aria-selected="false">ETH</a>
                    <a class="nav-item nav-link" id="nav-notificationHistory-tab" data-toggle="tab" href="#nav-notificationHistory" role="tab" aria-controls="nav-notificationHistory" aria-selected="false">USDT</a>
                  </div>
                </nav>
                <div className="boxSpace pt-1">
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-recentTransaction" role="tabpanel" aria-labelledby="nav-recentTransaction-tab">
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <div className="recentad_section">
                            <form className="contact_form mb-0 w100_form_p2p">
                              <div className="settingsSelect clas_Newselect clas_Newselect124">
                                <GridContainer>
                                  <GridItem xs={12} sm={7} md={6} lg={4}>
                                    <div className="form-group d-flex_p2p flex-wrwp-mobile_1">
                                      <label className="label_color_78">Enter Amount</label>
                                      <div className="flot_fixe_ins">
                                        <input type="text" className="form-control" />
                                        <Select value={10}>
                                          <MenuItem value={10}>US </MenuItem>
                                          <MenuItem value={20}>INR </MenuItem>
                                          <MenuItem value={30}>AUD </MenuItem>
                                        </Select>
                                      </div>

                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6} lg={5}>
                                    <div className="form-group d-flex_p2p flex-wrwp-mobile_1">
                                      <label className="label_color_78">Payments</label>
                                      <Select value={10}>
                                        <MenuItem value={10}>Bank, UPI</MenuItem>
                                        <MenuItem value={20}>Bank, UPI</MenuItem>
                                        <MenuItem value={30}>Bank, UPI</MenuItem>
                                      </Select>
                                      <div className="peer2peer_contant_first ">
                                        <button className="buttont_poidf mx-2 my-0">Search</button>
                                      </div>
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={12} lg={3}>
                                    <p className="refresh_color_78"><a href="" className="refresh_color_78">Refresh</a></p>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            </form>

                          </div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>

                                  <th></th>
                                  <th><p className="blew">Buy</p></th>
                                  <th></th>
                                  <th></th>
                                </tr>

                              </thead>

                              <tbody>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button data-toggle="modal" data-target="#BuyModel">Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6} className="border_lefrt">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>

                                  <th></th>
                                  <th><p className="blew sell_text_colo">Sell</p></th>
                                  <th></th>
                                  <th></th>
                                </tr>

                              </thead>

                              <tbody>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </GridItem>
                      </GridContainer>

                    </div>
                    <div class="tab-pane fade" id="nav-loginHistory" role="tabpanel" aria-labelledby="nav-loginHistory-tab">
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <div className="recentad_section">
                            <form className="contact_form mb-0 w100_form_p2p">
                              <div className="settingsSelect clas_Newselect clas_Newselect124">
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={6} lg={4}>
                                    <div className="form-group d-flex_p2p">
                                      <label className="label_color_78">Enter Amount</label>
                                      <div className="flot_fixe_ins">
                                        <input type="text" className="form-control" />
                                        <Select value={10}>
                                          <MenuItem value={10}>US </MenuItem>
                                          <MenuItem value={20}>INR </MenuItem>
                                          <MenuItem value={30}>AUD </MenuItem>
                                        </Select>
                                      </div>

                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6} lg={5}>
                                    <div className="form-group d-flex_p2p">
                                      <label className="label_color_78">Payments</label>
                                      <Select value={10}>
                                        <MenuItem value={10}>Bank, UPI</MenuItem>
                                        <MenuItem value={20}>Bank, UPI</MenuItem>
                                        <MenuItem value={30}>Bank, UPI</MenuItem>
                                      </Select>
                                      <div className="peer2peer_contant_first ">
                                        <button className="buttont_poidf mx-2 my-0">Search</button>
                                      </div>
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6} lg={3}>
                                    <p className="refresh_color_78"><a href="" className="refresh_color_78">Refresh</a></p>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            </form>

                          </div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={6}>
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>

                                  <th></th>
                                  <th><p className="blew">Buy</p></th>
                                  <th></th>
                                  <th></th>
                                </tr>

                              </thead>

                              <tbody>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={6} className="border_lefrt">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>

                                  <th></th>
                                  <th><p className="blew sell_text_colo">Sell</p></th>
                                  <th></th>
                                  <th></th>
                                </tr>

                              </thead>

                              <tbody>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div class="tab-pane fade" id="nav-notificationHistory" role="tabpanel" aria-labelledby="nav-notificationHistory-tab">
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <div className="recentad_section">
                            <form className="contact_form mb-0 w100_form_p2p">
                              <div className="settingsSelect clas_Newselect clas_Newselect124">
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={6} lg={4}>
                                    <div className="form-group d-flex_p2p">
                                      <label className="label_color_78">Enter Amount</label>
                                      <div className="flot_fixe_ins">
                                        <input type="text" className="form-control" />
                                        <Select value={10}>
                                          <MenuItem value={10}>US </MenuItem>
                                          <MenuItem value={20}>INR </MenuItem>
                                          <MenuItem value={30}>AUD </MenuItem>
                                        </Select>
                                      </div>

                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6} lg={5}>
                                    <div className="form-group d-flex_p2p">
                                      <label className="label_color_78">Payments</label>
                                      <Select value={10}>
                                        <MenuItem value={10}>Bank, UPI</MenuItem>
                                        <MenuItem value={20}>Bank, UPI</MenuItem>
                                        <MenuItem value={30}>Bank, UPI</MenuItem>
                                      </Select>
                                      <div className="peer2peer_contant_first ">
                                        <button className="buttont_poidf mx-2 my-0">Search</button>
                                      </div>
                                    </div>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6} lg={3}>
                                    <p className="refresh_color_78"><a href="" className="refresh_color_78">Refresh</a></p>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            </form>

                          </div>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={6}>
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>

                                  <th></th>
                                  <th><p className="blew">Buy</p></th>
                                  <th></th>
                                  <th></th>
                                </tr>

                              </thead>

                              <tbody>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button>Buy</button></div></td>


                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={6} className="border_lefrt">
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <thead>
                                <tr>

                                  <th></th>
                                  <th><p className="blew sell_text_colo">Sell</p></th>
                                  <th></th>
                                  <th></th>
                                </tr>

                              </thead>

                              <tbody>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                                <tr>
                                  <td><div className="buy_table_contant">
                                    <h4>User Id</h4>
                                    <p>20 orders,<br></br>
                                      97.5% completion</p></div></td>
                                  <td className="balance_amt_detail"><h4>25,50,500<span>INR</span></h4>
                                    <p><span className="mar-lrft0_rigt ">Available</span>0.12365987 BTC<br></br><span className="mar-lrft0_rigt ">Limit</span>₹1,500.00 - ₹10,500.85</p></td>
                                  <td><p className="clas_po_78">Bank,<br></br>UPI</p></td>
                                  <td className="buy_button_op"><div >
                                    <button className="sellcolor_o">Sell</button></div></td>


                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                  </div>
                </div>
              </div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="peer2peer_contant_first ">
                    <button data-toggle="modal" data-target="#confirm_payment">Show more</button>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <div class="modal fade buy_sellaction confirm_sellaction modalzindex" id="confirm_payment" tabindex="-1" role="dialog" aria-labelledby="confirm_payment" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">

              <div class="modal-body">
                <div className="confirm_body">
                  <h2>Confirm payment</h2>
                  <img src={require("../assets/images/pulp.png")} alt="" className="img-fluid" />
                  <p>Please confirm that payment has been made to the seller. </p>
                  <p>Malicious clicks will lead to account frozen</p>
                  <div className="new_confirm_pay_button">
                    <button className="button1 button2" data-dismiss="modal">Cancel</button>
                    <button className="button1 ">Confirm</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="modal fade buy_sellaction modalzindex" id="BuyModel" tabindex="-1" role="dialog" aria-labelledby="BuyModel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle blew">Buy</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={7} className="grid_wh_padding">
                    <div className="model_detail_text">
                      <div className="detail_text_model">
                        <span className="f-20 colo_er_opo">User Id</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="color_whit_7">20 orders, 97.5% completion</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="colo_er_opo">Price</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="color_green_o">25,50,500 INR</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="colo_er_opo">Payment Time Limit</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="color_green_o">30 Minutes</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="colo_er_opo">Available </span>
                      </div>
                      <div className="detail_text_model">
                        <span className="color_green_o">0.12365478 BTC</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="colo_er_opo">Seller’s Payment</span>
                      </div>
                      <div className="detail_text_model">
                        <span className="color_whit_7">Bank, UPI</span>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={5} className="bor_lef_ds solo_padding ">
                    <form className="contact_form mb-0">
                      <div className="form-group">

                        <label className="color_whit_7 w100_form_p2p">I want to pay <span className="unde_lin_qwe">All</span></label>
                        <div class="input-group ">
                          <input type="text" class="form-control" value="25,500.00" />
                          <div class="input-group-append">
                            <button className="inpor_inside_buttons">INR</button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="color_whit_7 ">I will receive</label>
                        <div class="input-group ">
                          <input type="text" class="form-control" value="0.00023659878 " />
                          <div class="input-group-append">
                            <button className="inpor_inside_buttons">BTC</button>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-0">
                        <button className="btn btn-primary text-uppercase py-2 w-100 button_gb_coloe">Enbale</button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </div>
             
            </div>
          </div>
        </div>
        <Footer />
      </div> */}


    </div>
  );
}
