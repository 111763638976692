// import package
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// import component
import GridItem from "components/Grid/GridItem.js";

// import action
import { editEmail, addemailnew } from "../../actions/users";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import validation from "./validation";

const initialFormValue = {
  newEmailUser: "",
  Email: "",
};

const EmailChange = () => {
  const { t, i18n } = useTranslation();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();

  const { newEmailUser, Email } = formValue;

  // redux-state
  const accountData = useSelector((state) => state.account);
  const { email, newEmail } = accountData;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      let reqData = {
        newEmail: newEmailUser,
      };
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setLoader(false);
        return;
      }

      let { status, loading, error, message } = await editEmail(reqData);
      setLoader(loading);
      if (status == "success") {
        setFormValue({
          newEmail: email,
        });
        toastAlert("success", message, "editEmail");
      } else {
        if (error) {
          setValidateError(error);
        } else if (message) {
          toastAlert("error", t(message), "editEmail");
        }
      }
    } catch (err) {}
  };

  const handleFormSubmitadd = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      let reqData = {
        newEmail,
      };
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setLoader(false);
        return;
      }

      let { status, loading, error, message } = await addemailnew(reqData);
      setLoader(loading);
      if (status == "success") {
        setFormValue({
          newEmail: email,
        });
        toastAlert("success", message, "editEmail");
      } else {
        if (error) {
          setValidateError(error);
        } else if (message) {
          toastAlert("error", t(message), "editEmail");
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (email) {
      setFormValue({
        Email: email,
        newEmailUser: newEmail,
      });
    }
  }, [email]);

  return (
    <GridItem xs={12} sm={12} md={4} lg={4}>
      {Email && (
        <div className="form-group">
          <label>
            Current Email Address<span class="textRed">*</span>
          </label>
          <input
            type="text"
            name="Email"
            value={Email}
            disabled={true}
            className="form-control"
          />
        </div>
      )}
      <div className="form-group">
        <label>
          New Email Address<span class="textRed">*</span>
        </label>
        <input
          type="text"
          name="newEmailUser"
          value={newEmailUser}
          onChange={handleChange}
          className="form-control"
        />
        {validateError.newEmail && (
          <p className="error-message">{validateError.newEmail}</p>
        )}
      </div>
      {email ? (
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary text-uppercase py-2 my-0"
            onClick={handleFormSubmit}
            disabled={loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            Change Email
          </button>
        </div>
      ) : (
        <div className="form-group">
          <button
            type="button"
            className="btn btn-primary text-uppercase py-2 my-0"
            onClick={handleFormSubmitadd}
            disabled={loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            Add New Email
          </button>
        </div>
      )}
    </GridItem>
  );
};
export default EmailChange;
