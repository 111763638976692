// import package
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "classnames";
// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { changePassword } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const { t, i18n } = useTranslation();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [oldPasswordShow, setoldPasswordShow] = useState(false);
  const [PasswordShow, setPasswordShow] = useState(false);
  const [ConfirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const { oldPassword, password, confirmPassword } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      oldPassword,
      password,
      confirmPassword,
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      let { status, loading, error, message } = await changePassword(reqData);
      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", t(message), "changePassword", "TOP_CENTER");
      } else {
        if (error) {
          setValidateError(error);
        } else if (message) {
          toastAlert("error", t(message), "changePassword", "TOP_CENTER");
        }
      }
    } catch (err) {}
  };

  return (
    <div className="profileDetailView">
      <h4 className="dash_title">
        {t("UPDATE_PASSWORD")}{" "}
        <a
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#idProofNote"
        >
          <small>
            <i class="fas fa-info-circle"></i>
          </small>
        </a>
      </h4>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div className="twoFAForm">
            <form className="contact_form mb-0">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={612}>
                  <div className="form-group">
                    <label>{t("CURRENT_PASSWORD")}</label>
                    <div className="input-group">
                      <input
                        type={`${oldPasswordShow ? "text" : "password"}`}
                        className="form-control"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={handleChange}
                      />
                      <div class="input-group-append">
                        <span
                          className="input-group-text"
                          onClick={() => setoldPasswordShow(!oldPasswordShow)}
                        >
                          <i
                            className={clsx(
                              "fa",
                              { "fa-eye": oldPasswordShow },
                              { "fa-eye-slash": !oldPasswordShow }
                            )}
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                    {validateError.oldPassword && (
                      <p className="error-message">
                        {t(validateError.oldPassword)}
                      </p>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={13} lg={12}>
                  <div className="form-group">
                    <label>{t("NEW_PASSWORD")}</label>
                    <div className="input-group">
                      <input
                        type={`${PasswordShow ? "text" : "password"}`}
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                      <div class="input-group-append">
                        <span
                          className="input-group-text"
                          onClick={() => setPasswordShow(!PasswordShow)}
                        >
                          <i
                            className={clsx(
                              "fa",
                              { "fa-eye": PasswordShow },
                              { "fa-eye-slash": !PasswordShow }
                            )}
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                    {validateError.password && (
                      <p className="error-message">
                        {t(validateError.password)}
                      </p>
                    )}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="form-group">
                    <label>{t("CONFIRM_PASSWORD")}</label>
                    <div className="input-group">
                      <input
                        type={`${ConfirmPasswordShow ? "text" : "password"}`}
                        className="form-control"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                      />
                      <div class="input-group-append">
                        <span
                          className="input-group-text"
                          onClick={() =>
                            setConfirmPasswordShow(!ConfirmPasswordShow)
                          }
                        >
                          <i
                            className={clsx(
                              "fa",
                              { "fa-eye": ConfirmPasswordShow },
                              { "fa-eye-slash": !ConfirmPasswordShow }
                            )}
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                    {validateError.confirmPassword && (
                      <p className="error-message">
                        {t(validateError.confirmPassword)}
                      </p>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
              <div className="form-group mb-0">
                <button
                  type="button"
                  onClick={handleFormSubmit}
                  className="btn btn-primary text-uppercase py-2"
                >
                  {loader && <i class="fas fa-spinner fa-spin"></i>}
                  {t("UPDATE")}
                </button>
              </div>
            </form>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div class="modal-body">
            <div className="settingsNote">
              <p>{t("PASSWORD_TITLE")}</p>
              <ul>
                <li>- {t("PASSWORD_DESCRIPTION1")}</li>
                <li>- {t("PASSWORD_DESCRIPTION6")}</li>
                <li>- {t("PASSWORD_DESCRIPTION2")}</li>
                <li>- {t("PASSWORD_DESCRIPTION5")}</li>
                <li>- {t("PASSWORD_DESCRIPTION3")}</li>
                <li>- {t("PASSWORD_DESCRIPTION4")}</li>
              </ul>
            </div>
          </div>
        </GridItem>
        <div
          class="modal fade"
          id="idProofNote"
          tabindex="-1"
          aria-labelledby="idProofNoteLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Notes</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default ChangePassword;
