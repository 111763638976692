import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function toastAlert(
  errorType,
  message,
  id,
  position = "TOP_CENTER" //TOP_RIGHT, TOP_CENTER
) {
  if (errorType === "error") {
    toast.error(message, {
      autoClose: 2000,
      toastId: id,
      position: toast.POSITION[position],
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else if (errorType === "success") {
    toast.success(message, {
      autoClose: 2000,
      toastId: id,
      position: toast.POSITION[position],
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else if (errorType === "warning") {
    toast.warning(message, {
      autoClose: 2000,
      toastId: id,
      position: toast.POSITION[position],
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else if (errorType === "info") {
    toast.info(message, {
      autoClose: 2000,
      toastId: id,
      position: toast.POSITION[position],
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
}
