// import package
import React, { createRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from "rc-checkbox";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
// import config
import config from "../../config";
import { useHistory } from "react-router-dom";
// import action
import { createUser, resendOtp } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getLang } from "../../lib/localStorage";
import queryString from "query-string";

const initialFormValue = {
  email: "",
  password: "",
  confirmPassword: "",
  reCaptcha: "",
  isTerms: false,
  showPassword: false,
  showConfirmPassword: false,
  referalcode: "",
  roleType: 1,
  phoneNo: "",
  mobileotp: "",
  phoneCode: +91,
};

const RegisterForm = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const recaptchaRef = createRef();
  const grecaptchaObject = window.grecaptcha;

  const location = useLocation();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [showOTP, setShowmobileOTP] = useState(false);

  const {
    email,
    password,
    confirmPassword,
    reCaptcha,
    isTerms,
    showPassword,
    showConfirmPassword,
    referalcode,
    roleType,
    phoneNo,
    phoneCode,
    mobileotp,
  } = formValue;

  // function

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const roleTypeChange = (type) => {
    const formData = { ...formValue, ...{ ["roleType"]: type } };
    setFormValue(formData);
  };

  const handleRecaptcha = (value) => {
    let formData = { ...formValue, ...{ reCaptcha: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!(isTerms == true)) {
      setValidateError({ isTerms: "ACCEPT_TERMS_MESSAGE" });
      return;
    }

    if (showOTP) {
      if (isEmpty(mobileotp)) {
        setValidateError({ mobileotp: "Required" });
        return;
      }
    }

    setLoader(true);
    let reqData = {
      email,
      phoneNo: "+" + phoneCode + phoneNo,
      password,
      confirmPassword,
      reCaptcha,
      isTerms,
      referalcode,
      langCode: getLang(),
      roleType,
      mobileotp,
    };
    let { status, loading, message, error } = await createUser(reqData);
    setLoader(loading);
    grecaptchaObject.reset();
    if (status == "success") {
      setFormValue(initialFormValue);
      toastAlert("info", message, "signup", "TOP_CENTER");
      setTimeout(() => history.push("/login"), 2000);
    } else {
      if (status == "OTP_NOTIFY") {
        setShowmobileOTP(true);
        toastAlert("info", message, "login");
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("info", message, "login");
      }
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      email,
      phoneNo: "+" + phoneCode + phoneNo,
      password,
      isTerms,
      mobileotp,
      roleType,
      resendOTP: true,
      langCode: getLang(),
    };
    let { status, loading, message, error, userSetting } = await resendOtp(
      reqData
    );

    setLoader(loading);
    grecaptchaObject.reset();
    if (status == "success") {
      setFormValue(initialFormValue);
      toastAlert("info", message, "signup", "TOP_CENTER");
    } else {
      if (status == "OTP_NOTIFY") {
        setShowmobileOTP(true);
        setValidateError({ mobileotp: "" });
        toastAlert("info", message, "login");
      } else {
        if (status == "EMAIL_OTP_NOTIFY") {
          setShowmobileOTP(true);
          setValidateError({ mobileotp: "" });
          toastAlert("info", message, "login");
        } else {
          if (error) {
            setValidateError(error);
          }
          toastAlert("info", message, "login");
        }
      }
    }
  };

  useEffect(() => {
    // setValidateError(validation(formValue));
    const parsed = queryString.parse(location.search);
    var referralcode = "";
    if (!isEmpty(parsed.ref)) {
      var referralcode = parsed.ref;
    }

    let formData = { ...formValue, ...{ ["referalcode"]: referralcode } };

    setFormValue(formData);
  }, []);

  return (
    <form className="login_form p-4 mb-4" data-aos="fade-up">
      <h2 className="text-left">{t("REGISTER_TITLE_1")}</h2>

      <div className="form_fonr_ad">
        <ul className="nav nav-tabs">
          <li className="active">
            <a
              className="active"
              onClick={() => roleTypeChange(1)}
              data-toggle="tab"
              href="#home"
            >
              Email
            </a>
          </li>
          <li>
            <a
              onClick={() => roleTypeChange(2)}
              data-toggle="tab"
              href="#menu1"
            >
              Mobile
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div id="home" className="tab-pane fade in active show">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {toched.email && validateError.email && (
                <p className="error-message">{validateError.email}</p>
              )}
            </div>
          </div>
          <div id="menu1" className="tab-pane fade">
            <div className="form-group">
              {/* <input
                type="text"
                className="form-control"
                placeholder="Mobile Number"
                name="phoneNo"
                value={phoneNo}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Eg +911234567890"}
              /> */}
              <PhoneInput
                country={"us"}
                name="phoneNo"
                placeholder="Enter phone number"
                value={phoneCode + phoneNo}
                onChange={handlePhoneNumber}
                onBlur={handleBlur}
              />
              {validateError.phoneNo && (
                <p className="error-message">{validateError.phoneNo}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {!showOTP && (
        <>
          <div className="form-group">
            <div className="input-group regGroupInput">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                placeholder="Password"
                name="password"
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="input-group-append">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setFormValue((el) => {
                      return { ...el, ...{ showPassword: !el.showPassword } };
                    });
                  }}
                >
                  <i
                    className={clsx(
                      "fa",
                      { "fa-eye": showPassword },
                      { "fa-eye-slash": !showPassword }
                    )}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
            {toched.password && validateError.password && (
              <p className="error-message">{validateError.password}</p>
            )}
          </div>

          <div className="form-group">
            <div className="input-group regGroupInput">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="form-control"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="input-group-append">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setFormValue((el) => {
                      return {
                        ...el,
                        ...{ showConfirmPassword: !el.showConfirmPassword },
                      };
                    });
                  }}
                >
                  <i
                    className={clsx(
                      "fa",
                      { "fa-eye": showConfirmPassword },
                      { "fa-eye-slash": !showConfirmPassword }
                    )}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
            {toched.confirmPassword && validateError.confirmPassword && (
              <p className="error-message">{validateError.confirmPassword}</p>
            )}
          </div>
          <div className="form-group pt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Referral Code (Optional)"
              name="referalcode"
              value={referalcode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {toched.referalcode && validateError.referalcode && (
              <p className="error-message">{validateError.referalcode}</p>
            )}
          </div>
        </>
      )}

      {showOTP && (
        <div className="form-group pt-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter Mobile OTP"
            name="mobileotp"
            value={mobileotp}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {validateError.mobileotp && (
            <p className="error-message">{validateError.mobileotp}</p>
          )}
        </div>
      )}

      <div className="form-group text-center">
        <ReCAPTCHA
          ref={recaptchaRef}
          grecaptcha={grecaptchaObject}
          sitekey={config.RECAPTCHA_SITE_KEY}
          onChange={handleRecaptcha}
        />

        {validateError.reCaptcha != "" && (
          <p className="error-message">{validateError.reCaptcha}</p>
        )}
      </div>

      <div className="form-group">
        <div className="form-check">
          <Checkbox
            name="isTerms"
            onChange={handleCheckBox}
            checked={isTerms}
          />

          {/* <input
        type="checkbox"
        id="flexCheckDefault"
        name="isTerms"
        checked={true}
        // value=""
        onChange={handleChange}
    /> */}
          <label className="form-check-label" for="flexCheckDefault">
            I accept and agree to the{" "}
            <Link to="/terms">Terms & Conditions</Link>
          </label>
          {validateError.isTerms && (
            <p className="error-message">{t(validateError.isTerms)}</p>
          )}
        </div>
      </div>
      <div className="form-group">
        {validateError.mobileotp && (
          <Button
            onClick={resendOTP}
            disabled={isEmpty(validateError.mobileotp) || loader}
          >
            {" "}
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            Resend OTP
          </Button>
        )}
      </div>
      <div className="form-group">
        <Button
          onClick={handleFormSubmit}
          disabled={!isEmpty(validateError) || loader}
        >
          {loader && <i class="fas fa-spinner fa-spin"></i>}
          Submit
        </Button>
      </div>

      <div className="d-flex">
        {/* <Link to="/recover-password" className="mr-auto">Forgot password?</Link> */}
        <Link to="/login" className="ml-auto">
          Already have an account?
        </Link>
      </div>
    </form>
  );
};

export default RegisterForm;
