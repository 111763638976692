// import package
import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Hidden, Button, Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import SocketContext from "../Context/SocketContext";

// import action
import { logout } from "../../actions/users";

import { encryptString } from "../../lib/cryptoJS";
import config from "../../config/index";
import {
  getNotificationHistory,
  getNotificationHistory_read,
} from "../../actions/dashboardAction";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { setTradeTheme, setTheme } from "../../actions/commonAction";

export default function HeaderLinks(props) {
  const socketContext = useContext(SocketContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);

  // state
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [unreadcount, setunreadcount] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick3 = (event) => {
    setAnchorE4(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE4(null);
  };

  // redux state
  const tradeThemeData = useSelector((state) => state.tradeTheme);
  const { isAuth } = useSelector((state) => state.auth);
  const { email } = useSelector((state) => state.account);
  const themeData = useSelector((state) => state.theme);

  // function
  const fetchNotificationHistory = async () => {
    try {
      const {
        status,
        loading,
        result,
        unread_count,
      } = await getNotificationHistory();
      setLoader(loading);
      if (status == "success") {
        setData(result);
        setunreadcount(unread_count);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchNotificationHistory();
    callsocket();
  }, []);

  function callsocket() {
    socketContext.socket.on("unreadnotification", (result) => {
      //console.log(result, "Helo12313");
      fetchNotificationHistory();
      // setunreadcount(result);
    });
  }

  return (
    <div className="inner_page_menu">
      <div className="dashboard_login">
        <Hidden smDown>
          <ul className="list-iline">
            {/* {
              ['/spot/:tikerRoot?', '/derivative/:tikerRoot?'].includes(routeMatch.path) && <li>
                <div className="toggleMode" title="toggle dark mode">
                  <label>
                    <input type="checkbox" checked={tradeThemeData == 'light' ? true : false} name="" onClick={() => setTradeTheme(dispatch, tradeThemeData == 'light' ? 'dark' : 'light')} />
                    <span></span>
                  </label>
                </div>
              </li>
            } */}

            <li>
              <div className="toggleMode" title="toggle dark mode">
                <label>
                  <input
                    type="checkbox"
                    checked={themeData == "light" ? true : false}
                    name=""
                    onClick={() =>
                      setTheme(
                        dispatch,
                        themeData == "light" ? "dark" : "light"
                      )
                    }
                  />
                  <span></span>
                </label>
              </div>
            </li>

            {isAuth && (
              <li>
                <Link to="/spot">Spot</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/p2p">P2P</Link>
              </li>
            )}
            {isAuth && (
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )}

            {/* {isAuth && (
              <li>
                <Link to="#" onClick={NavigateExchange}>
                  ICO
                </Link>
              </li>
            )} */}

            {isAuth && (
              <li>
                <Button
                  aria-controls="wallet_menu"
                  aria-haspopup="true"
                  onClick={handleClick3}
                >
                  Wallet
                </Button>
                <Menu
                  id="wallet_menu"
                  anchorEl={anchorE4}
                  keepMounted
                  open={Boolean(anchorE4)}
                  onClose={handleClose3}
                >
                  <MenuItem>
                    <Link to="/wallet">Spot Wallet</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/P2pwallet">P2p Wallet</Link>
                  </MenuItem>
                </Menu>
              </li>
            )}
            {/* 
            {
              isAuth && <li>
                <Link to="/staking">Staking</Link>
              </li>
            } */}

            {isAuth && (
              <li>
                {unreadcount > 0 && (
                  <span className="notify_count">{unreadcount}</span>
                )}
                <Button
                  class="btn btnNotification"
                  type="button"
                  data-toggle="collapse"
                  data-target="#notificationDropdown"
                  aria-expanded="false"
                  aria-controls="notificationDropdown"
                  onClick={getNotificationHistory_read}
                >
                  <i className="fas fa-bell"></i>
                </Button>
                <div class="collapse" id="notificationDropdown">
                  <div className="notificationDropdown">
                    <ul>
                      {data.length > 0 &&
                        data.map((item, key) => {
                          return (
                            <li>
                              {/* <p>{dateTimeFormat(item.createdAt)}</p> */}
                              <h5>
                                {item.description} @
                                {dateTimeFormat(item.createdAt)}
                              </h5>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </li>
            )}

            {isAuth && (
              <li>
                <Button
                  aria-controls="profile_menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <i className="fas fa-user"></i>
                </Button>
                <Menu
                  id="profile_menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Link to="/profile">Profile</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/kyc">KYC</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/settings">Settings</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/history">History</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/support-ticket">Support</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="#" onClick={() => logout(history, dispatch)}>
                      Logout
                    </Link>
                  </MenuItem>
                </Menu>
              </li>
            )}
          </ul>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <ul className="list-iline">
            <li className="pb-3">
              <div className="toggleMode" title="toggle dark mode">
                <label>
                  <input
                    type="checkbox"
                    checked={themeData == "light" ? true : false}
                    name=""
                    onClick={() =>
                      setTheme(
                        dispatch,
                        themeData == "light" ? "dark" : "light"
                      )
                    }
                  />
                  <span></span>
                </label>
              </div>
            </li>
            {isAuth && (
              <li>
                <Link to="/spot">Spot</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/p2p">P2P</Link>
              </li>
            )}
            {isAuth && (
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )}

            {/* {isAuth && (
              <li>
                <Link to="#" onClick={NavigateExchange}>
                  ICO                  
                </Link>
              </li>
            )} */}
            {isAuth && (
              <li>
                <Button
                  aria-controls="wallet_menu"
                  aria-haspopup="true"
                  onClick={handleClick3}
                >
                  Wallet
                </Button>
                <Menu
                  id="wallet_menu"
                  anchorEl={anchorE4}
                  keepMounted
                  open={Boolean(anchorE4)}
                  onClose={handleClose3}
                >
                  <MenuItem>
                    <Link to="/wallet">Spot Wallet</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/P2pwallet">P2p Wallet</Link>
                  </MenuItem>
                </Menu>
              </li>
            )}

            {/* {isAuth && (
              <li>
                <Link to="/settings">Staking</Link>
              </li>
            )} */}

            {/* {
              isAuth && <li>
                <Link to="">Notifications</Link>
              </li>
            } */}

            {isAuth && (
              <li>
                <Link to="/profile">Profile</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/kyc">KYC</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/settings">Settings</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/history">History</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="/support-ticket">Support</Link>
              </li>
            )}

            {isAuth && (
              <li>
                <Link to="#" onClick={() => logout(history, dispatch)}>
                  Logout
                </Link>
              </li>
            )}
          </ul>
        </Hidden>
      </div>
    </div>
  );
}
