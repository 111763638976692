import React, { useEffect } from "react";
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ProfileDetail from "../components/ProfileDetail";
import BankAccount from "../components/BankAccount";
import Announcement from "../components/Announcement";
import EmailChange from "../components/EmailChange";
import PhoneNoChange from "../components/PhoneNoChange";
import UPIAccount from "../components/UPIAccount/UPIAccount";
import ReffralDetail from "../components/ReffralDetail/ReffralDetail"
import QRAccount from "../components/QRCodeAccount/QRCodeAccount";
import { useDispatch, useSelector } from "react-redux";
import { toastAlert } from "../lib/toastAlert";
import {getrefferDetail} from "../actions/users"

import { CopyToClipboard } from "react-copy-to-clipboard";

import config from "../config";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return null;
}
export default function Profile(props) {
  const { ...rest } = props;

  const refferDetail = async () => {
    try {
      const { status, loading, result } = await getrefferDetail();
      if (status == 'success') {

        console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",result)
      }
    } catch (err) { }
  }

  useEffect(() => {
    refferDetail()
  }, []);
  // redux
  const theme = useSelector(state => state.theme)
 
  const { referencecode } = useSelector((state) => state.account);
  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header
            className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest}
          />
          <div className="profileContent userPages">
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Profile Details</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                  <Announcement />
                </GridItem>
              </GridContainer>

              <ProfileDetail />
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h3 className="dash_title mb-3">Referral Program</h3>
                </div>
              </div>
              <div className="dashboard_box">
                <form className="contact_form">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <div className="form-group">
                        <label>Referral Code</label>
                        <input
                          type="text"
                          name="newEmail"
                          value={referencecode}
                          // onChange={}
                          className="form-control"
                        />
                      </div>

                      <div className="form-group">
                        <label>Referral Link</label>
                        <span>
                          {" "}
                          <CopyToClipboard
                            text={
                              referencecode &&
                              config.FRONT_URL + "register/?ref=" + referencecode
                            }
                            onCopy={() => {
                              toastAlert("success", "Copied!", "twoFa");
                            }}
                          >
                            <a
                              href="javscript:void(0)"
                              className="py-0 my-0 px-2"
                            >
                              <i class="fas fa-copy"></i>
                            </a>
                          </CopyToClipboard>
                        </span>
                        <input
                          type="text"
                          value={config.FRONT_URL + "register/?ref=" + referencecode}
                          // onChange={}
                          className="form-control"
                        />
                      </div>
                    </GridItem>
                    <ReffralDetail />
                    {/* <PhoneNoChange /> */}

                    {/* <GridItem xs={12} sm={12} md={4} lg={4}>
                       <div className="form-group">
                            <label>Email Address<span class="textRed">*</span></label>
                            <input type="text" name=""  value="ajith@britisheducationonline.org" className="form-control" />
                        </div>
                        <div className="form-group">
                            <button type="button" className="btn btn-primary text-uppercase py-2 my-0">Update Email</button>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                       <div className="form-group">
                            <label>Phone Number<span class="textRed">*</span></label>
                            <div class="input-group mb-3">
                              <input type="text" value="9191091919" class="form-control" />
                              <div class="input-group-append">
                                <button type="button" className="btn btn-primary text-uppercase py-2 my-0">Send OTP</button>
                              </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Enter OTP</label>
                            <input type="text" name="" value="" className="form-control" />
                        </div>
                         <div className="form-group">
                            <button type="button" className="btn btn-primary text-uppercase py-2 my-0">Update Phone Number</button>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                       <div className="form-group">
                              <label className="invisible w-100">hidden</label>
                             <button type="button" className="btn btn-primary text-uppercase py-2 my-0">Edit Details</button>
                        </div>
                    </GridItem> */}
                  </GridContainer>
                </form>
              </div>

              <div className="dashboard_box">
                <form className="contact_form">
                  <GridContainer>
                    <EmailChange />
                  </GridContainer>
                </form>
              </div>

              <div className="dashboard_box">
                <form className="contact_form">
                  <GridContainer>
                  <PhoneNoChange />
                  </GridContainer>
                </form>
              </div>

              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h3 className="dash_title mb-3">Bank Account Details</h3>
                </div>
              </div>
              <BankAccount />
              <div className="row align-items-center boxSpace">
                <div className="col-lg-12">
                  <h3 className="dash_title mb-3">UPI Details</h3>
                </div>
              </div>
              <UPIAccount />
              <div className="row align-items-center boxSpace">
                <div className="col-lg-12">
                  <h3 className="dash_title mb-3">GPay QR code Details</h3>
                </div>
              </div>
              <QRAccount />
              {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#confirm_delete">
                Open modal
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
  
    </div>
  );
}
