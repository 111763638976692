// import package
import React from "react";

// import component
import HomeBanner from "./HomeBanner";

const Home = () => {
  return (
    <>
      <HomeBanner />
    </>
  );
};

export default Home;
