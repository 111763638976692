import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "classnames";

import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";

import "./styles.css";
import "./example-styles.css";
import { getLaunchpadCms } from "../../actions/launchpadActions";
// import component
import SelectPair from "./SelectPair";
import OrderPlace from "./OrderPlace";
import MarketPrice from "./MarketPrice";
import OrderBook from "./OrderBook";
import UserOrderList from "./UserOrderList";
import Chart from "./Chart";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ShowcaseLayout = (props) => {
  const orderBookRef = useRef();

  // state
  const [expandScreen, setExpandScreen] = useState("");

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [getLaunchpadCmsdata, setLaunchpadCms] = useState([]);
  const [layouts, setLayouts] = useState({
    lg: props.initialLayout,
  });

  // function
  const getLaunchpadCmsList = async () => {
    let { status, result, launchpadCMS } = await getLaunchpadCms();

    if (status) {
      // Active Token
      launchpadCMS &&
        launchpadCMS.length >= 0 &&
        launchpadCMS.map((item, key) => {
          if (item.identifier == "spot_page_image") {
            setLaunchpadCms([item]);
          }
        });
    }
  };

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };
  const onLayoutChange = (layout, layouts) => {
    props.onLayoutChange(layout, layouts);
  };
  useEffect(() => {
    setMounted(true);
    // getLaunchpadCmsList();
  }, []);

  const handleLayoutResize = (layoutList, previousLayout, currentLayout) => {
    if (currentLayout && currentLayout.i == "1") {
      orderBookRef.current.orderBookResize(currentLayout);
    }
  };
  const createMarkup = (a) => {
    return { __html: a };
  };

  return (
    <div>
      <ResponsiveReactGridLayout
        {...props}
        layouts={layouts}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        onResize={handleLayoutResize}
        // isDraggable={false}
        draggableHandle=".MyDragHandleClassName"
        // WidthProvider option
        measureBeforeMount={false}
        // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
        // and set `measureBeforeMount={true}`.
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
      >
        <div key={1} className="static topOverview">
          <MarketPrice />
        </div>
        <div
          key={2}
          className={clsx("orderPlaceMain", {
            fullScreen: expandScreen == "orderPlace",
          })}
        >
          <SelectPair />
        </div>
        <div
          key={3}
          className={clsx("orderBookMain", {
            fullScreen: expandScreen == "orderBook",
          })}
        >
          <OrderBook
            ref={orderBookRef}
            layout={
              props.initialLayout &&
              props.initialLayout.find((el) => el.i == "1")
            }
            setExpandScreen={setExpandScreen}
            expandScreen={expandScreen}
          />
        </div>
        <div key={4} className="tradeChartMain">
          <div className="tradeChart">
            <Chart />
          </div>
        </div>

        <div
          key={7}
          className={clsx("orderPlaceMain", {
            fullScreen: expandScreen == "orderPlace",
          })}
        >
          <OrderPlace
            orderBookRef={orderBookRef}
            setExpandScreen={setExpandScreen}
            expandScreen={expandScreen}
          />
        </div>
        <div key={6} className="userOrderListMain">
          <UserOrderList />
        </div>
      </ResponsiveReactGridLayout>
    </div>
  );
};

ShowcaseLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired,
};

ShowcaseLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  initialLayout: [
    {
      x: 0,
      y: 0,
      w: 9,
      h: 2,
      i: "1",
      name: "marketPrice",
      static: true,
    },
    {
      x: 10,
      y: 0,
      w: 3,
      h: 22,
      i: "2",
      name: "marketList",
      static: false,
    },
    {
      x: 0,
      y: 10,
      w: 3,
      h: 20,
      i: "3",
      name: "orderBook",
      static: false,
    },
    {
      x: 3,
      y: 1,
      w: 6,
      h: 9,
      i: "4",
      name: "chart",
      static: false,
    },
    {
      x: 3,
      y: 11,
      w: 6,
      h: 11,
      i: "7",
      name: "orderPlace",
      static: false,
    },
    {
      x: 0,
      y: 22,
      w: 12,
      h: 8,
      i: "6",
      name: "tradeHistory",
      static: false,
    },
  ],
};

export default ShowcaseLayout;
