// import package
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import DataTable from 'react-data-table-component';
import { Select, MenuItem } from '@material-ui/core';

// import action
import { getTrnxHistory ,getLaunchpadhistory} from '../../actions/walletAction'
import isEmpty from '../../lib/isEmpty';

const columns = [
    {
        name: 'Date',
        selector: 'date',
        sortable: false,
    },
    {
        name: 'Token',
        selector: 'launchtoken',
        sortable: false,
    },
    {
        name: 'Currency',
        selector: 'currency',
        sortable: false,
    },
    {
        name: 'Price',
        selector: 'price',
        sortable: false,
    },
    {
        name: 'Quantity',
        selector: 'quantity',
        sortable: false,
    },
    {
        name: 'Total',
        selector: 'total',
        sortable: false,
    }
    // {
    //     name: 'Status',
    //     selector: 'status',
    //     sortable: false,
    // },
];

const LaunchHistory = (props) => {
    // props
    const { currencyOption } = props;

    // state
    const [loader, setLoader] = useState(true)
    const [record, setRecord] = useState({
        'data': [],
        'count': 0
    })
    const [option, setOption] = useState([])
    const [filter, setFilter] = useState({
        'currencyId': '',
        'paymentType': 'all',
        'search': '',
        'page': 1,
        'limit': 5
    })

    const { currencyId, paymentType } = filter

    const fetchLaunchistory =async()=>{
        try{
            const { status, loading, result } = await getLaunchpadhistory()
                setLoader(loading)
                if (status == 'success') {
                    if (result && result.launchpadData && result.launchpadData.length > 0) {
                        let resultArr = []
                        result.launchpadData.map((item) => {
                            resultArr.push({
                                'date': item.created_date,
                                'launchtoken': item.launchpadId&&item.launchpadId.symbol,
                                'currency': item.currencySymbol,
                                'quantity': item.quantity,
                                'price': item.price,
                                'total':item.total
                            })
    
                            //textStatusOrange,textStatusGreen
                        })
                        setRecord({
                            'data': resultArr,
                            count: result.count
                        })
                    }
                }
        }
        catch(err){
            console.log("errerere",err)
        }
    }
    // function
    const fetchHistory = async (reqQuery) => {
        try {
            const { status, loading, result } = await getTrnxHistory('fiat', reqQuery)
            setLoader(loading)
            if (status == 'success') {
                if (result && result.data && result.data.length > 0) {
                    let resultArr = []
                    result.data.map((item) => {
                        resultArr.push({
                            'date': item.createdAt,
                            'type': item.paymentType,
                            'currency': item.currencySymbol,
                            'amount': item.amount,
                            'transRef': item._id,
                            'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                            'status': <div className="textStatusGreen">{item.status}</div>
                        })

                        //textStatusOrange,textStatusGreen
                    })
                    setRecord({
                        'data': resultArr,
                        count: result.count
                    })
                }
            }
        } catch (err) { }
    }

    const handlePageChange = page => {
        let filterData = { ...filter, ...{ 'page': page } }
        setFilter(filterData)
        fetchHistory(filterData)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
        setFilter(filterData)
        fetchHistory(filterData)
    };


    useEffect(() => {
        // if (currencyOption && currencyOption.length > 0) {
        //     let optionData = currencyOption.filter((el => el.type == 'fiat'))
        //     if (optionData && optionData.length > 0) {
        //         setOption(optionData)
        //         let filterData = { ...filter, ...{ 'currencyId': optionData[0]._id } }
        //         setFilter(filterData)
        //         fetchHistory(filterData)
        //     }
        // }
        fetchLaunchistory()
    }, [])

   

    return (
        <div className="dashboard_box stakingHistoryTable">
            <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
              
            </div>
            <DataTable
            className="historyCryptoTable"
                columns={columns}
                data={record.data}
                noHeader
                progressPending={loader}
                pagination
                paginationPerPage={5}
                paginationTotalRows={record.count}
                paginationRowsPerPageOptions={[5,10,15,20,500]}
                paginationServer
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </div>
    )
}

LaunchHistory.propTypes = {
    currencyOption: PropTypes.array.isRequired
};

LaunchHistory.defaultProps = {
    currencyOption: [],
};

export default LaunchHistory;