// import package
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import browser from "browser-detect";
import Checkbox from "rc-checkbox";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import clsx from "classnames";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
// import action
import {
  getGeoInfoData,
  login,
  resendOtp,
  ICOLOGIN,
} from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getLang } from "../../lib/localStorage";
import { useParams, useLocation } from "react-router-dom";
import { encryptObject, decryptObject } from "../../lib/cryptoJS";

const initialFormValue = {
  email: "",
  password: "",
  twoFACode: "",
  emailotp: "",
  phoneCode: "",
  isTerms: false,
  roleType: 1,
  showPassword: "",
  phoneNo: "",
};

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { authToken } = useParams();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [loginHistory, setLoginHistory] = useState({});
  const [showTwoFA, setShowTowFA] = useState(false);
  const [emailotpshow, set_emailotpshow] = useState(false);
  const [Timer, startTimmer] = useState();

  const {
    email,
    password,
    isTerms,
    twoFACode,
    emailotp,
    showPassword,
    phoneNo,
    roleType,
    phoneCode,
  } = formValue;
  let location = useLocation();

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name == "twoFACode") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
    }
    if (name == "emailotp") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const roleTypeChange = (type) => {
    const formData = { ...formValue, ...{ ["roleType"]: type } };
    setFormValue(formData);
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    if (!checked) {
      localStorage.removeItem("details");
      setFormValue(initialFormValue);
    } else {
      setFormValue(formData);
      setValidateError(validation(formData));
    }
  };

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipaddress: result.ip,
        region: result.region,
        broswername: browserResult.name,
        ismobile: browserResult.mobile,
        os: browserResult.os,
      });
    } catch (err) {}
  };

  const saveKeepme = async (reqData) => {
    if (reqData.isTerms) {
      let Userdetail = reqData;

      if (Userdetail) {
        let details = await encryptObject(Userdetail && Userdetail);
        localStorage.setItem("details", details);
      }
    }
  };

  const getKeepMeData = async (x) => {
    let userDetails = await decryptObject(x);

    setFormValue(userDetails);
    setToched({ email: true, password: true });
    setLoader(false);
    setValidateError({});
  };

  useEffect(() => {
    let Details = localStorage.getItem("details");
    // if (Details) getKeepMeData(Details);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      email,
      phoneNo: "+" + phoneCode + phoneNo,
      password,
      isTerms,
      twoFACode,
      emailotp,
      loginHistory,
      roleType,
      resendOTP: false,
      langCode: getLang(),
    };
    let { status, loading, message, error, userSetting } = await login(
      reqData,
      dispatch
    );
    setLoader(loading);
    if (status == "success") {
      if (isTerms) {
        let userDetail = {
          email,
          phoneNo,
          password,
          isTerms,
          loginHistory,
          roleType,
          resendOTP: false,
          langCode: getLang(),
        };
        await saveKeepme(userDetail);
      }

      toastAlert("info", message, "login");

      // setFormValue(initialFormValue);

      if (userSetting && !isEmpty(userSetting.afterLogin)) {
        history.push(userSetting.afterLogin.url);
      } else {
        history.push("/profile");
      }
    } else if (status == "EMAIL_OTP_NOTIFY") {
      set_emailotpshow(true);
      toastAlert("info", message, "login");
    } else if (status == "TWO_FA") {
      setShowTowFA(true);
      toastAlert("info", message, "login");
    } else {
      if (error) {
        if (error && error.startcounter) {
          alert(error && error.remaingTime);
          // settimer(true)
          startTimmer(error && error.remaingTime);
        }
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };
  const resendOTP = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      email,
      phoneNo: "+" + phoneCode + phoneNo,
      password,
      isTerms,
      twoFACode,
      emailotp,
      loginHistory,
      roleType,
      resendOTP: true,
      langCode: getLang(),
    };
    let { status, loading, message, error, userSetting } = await resendOtp(
      reqData,
      dispatch
    );
    setValidateError({});
    setLoader(loading);
    if (status == "success") {
      toastAlert("info", message, "login");
      if (isTerms) {
        let userDetail = {
          email,
          phoneNo,
          password,
          isTerms,
          loginHistory,
          roleType,
          resendOTP: false,
          langCode: getLang(),
        };
        await saveKeepme(userDetail);
      }

      if (userSetting && !isEmpty(userSetting.afterLogin)) {
        history.push(userSetting.afterLogin.url);
      } else {
        history.push("/profile");
      }
    } else if (status == "EMAIL_OTP_NOTIFY") {
      set_emailotpshow(true);
      toastAlert("info", message, "login");
    } else if (status == "TWO_FA") {
      setShowTowFA(true);
      toastAlert("info", message, "login");
    } else {
      if (error) {
        if (error && error.startcounter) {
          alert(error && error.remaingTime);
          // settimer(true)
          startTimmer(error && error.remaingTime);
        }

        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };
  useEffect(() => {
    getGeoInfo();
    setValidateError(validation(formValue));
  }, []);

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  return (
    <form className="login_form p-4 mb-4" data-aos="fade-up">
      <h2 class="text-left">Hello! Welcome back.</h2>
      <h6>Log in with your data that you entered during your registration</h6>
      <div className="form_fonr_ad">
        <ul className="nav nav-tabs">
          <li className="active">
            <a
              className="active"
              onClick={() => roleTypeChange(1)}
              data-toggle="tab"
              href="#home"
            >
              Email
            </a>
          </li>
          <li>
            <a
              data-toggle="tab"
              onClick={() => roleTypeChange(2)}
              href="#menu1"
            >
              Mobile
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div id="home" className="tab-pane fade in active show">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder={t("EMAIL_PLACEHOLDER")}
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {toched.email && validateError.email && (
                <p className="error-message">{t(validateError.email)}</p>
              )}
            </div>
          </div>
          <div id="menu1" className="tab-pane fade">
            <div className="form-group">
              {/* <input
                type="text"
                className="form-control"
                placeholder="Mobile Number"
                name="phoneNo"
                value={phoneNo}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Eg +911234567890"}
              /> */}

              <PhoneInput
                country={"us"}
                placeholder="Enter phone number"
                name="phoneNo"
                value={phoneCode + phoneNo}
                onChange={handlePhoneNumber}
                onBlur={handleBlur}
              />
              {validateError.phoneNo && (
                <p className="error-message">{t(validateError.phoneNo)}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="input-group regGroupInput mt-2">
          <input
            className="form-control mt-2"
            placeholder={t("PASSWORD_PLACEHOLDER")}
            name="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="input-group-append">
            <Link
              onClick={(e) => {
                e.preventDefault();
                setFormValue((el) => {
                  return { ...el, ...{ showPassword: !el.showPassword } };
                });
              }}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-eye": showPassword },
                  { "fa-eye-slash": !showPassword }
                )}
                aria-hidden="true"
              ></i>
            </Link>
          </div>
        </div>
        {toched.password && validateError.password && (
          <p className="error-message">{t(validateError.password)}</p>
        )}
      </div>

      {/* <div className="form-group">
        <input
          type="password"
          className="form-control"
          placeholder={t("PASSWORD_PLACEHOLDER")}
          name="password"
          value={password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {toched.password && validateError.password && (
          <p className="error-message">{t(validateError.password)}</p>
        )}
      </div> */}

      {emailotpshow && roleType == 1 && (
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder={t("ENTER_EMAIL_OTP")}
            name="emailotp"
            value={emailotp}
            onChange={handleChange}
          />
          {validateError.emailotp && (
            <p className="error-message">{t(validateError.emailotp)}</p>
          )}
        </div>
      )}
      {emailotpshow && roleType == 2 && (
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder={t("ENTER_PHONE_OTP")}
            name="emailotp"
            value={emailotp}
            onChange={handleChange}
          />
          {validateError.emailotp && (
            <p className="error-message">{t(validateError.emailotp)}</p>
          )}
        </div>
      )}

      {showTwoFA && (
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder={t("ENTER_TWO_FA_CODE")}
            name="twoFACode"
            value={twoFACode}
            onChange={handleChange}
          />
          {validateError.twoFACode && (
            <p className="error-message">{t(validateError.twoFACode)}</p>
          )}
        </div>
      )}

      <div className="form-group">
        <div className="form-check">
          <Checkbox
            name="isTerms"
            onChange={handleCheckBox}
            checked={isTerms}
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("KEEP_SIGN_COMPUTER")}
          </label>
        </div>
      </div>
      <div className="form-group">
        {/* {loader && <i class="fas fa-spinner fa-spin"></i>} */}
        {validateError.emailotp && (
          <Button
            onClick={resendOTP}
            disabled={isEmpty(validateError.emailotp) || loader}
          >
            Resend OTP
          </Button>
        )}
      </div>
      <div className="form-group">
        {/* <progress value="0" max="10" id="progressBar"></progress> */}

        {!validateError.emailotp && (
          <Button
            onClick={handleFormSubmit}
            disabled={!isEmpty(validateError) || loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            {t("SIGN_IN_BUTTON")}
          </Button>
        )}
      </div>

      <div className="d-flex">
        <Link to="/recover-password" className="mr-auto">
          {t("FORGOT_PASSWORD")}?
        </Link>
        <Link to="/register" className="ml-auto">
          {t("DON'T_HAVE_ACCOUNT")}?
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
