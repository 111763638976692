import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getCMSPage } from '../actions/commonAction';

const PressPage = (props) => {
  // state
  const [content, setContent] = useState('');

  // function
  const fetchCmsPage = async () => {
    try {
      const { status, loading, result } = await getCMSPage('press');
      if (status == 'success') {
        setContent(result.content)
        document.title = result.title;
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchCmsPage()
  }, [])

   // redux
   const theme = useSelector(state => state.theme)


  return (
    <div className="page_wrap">
      <Header className="header"
        color="transparent"
        // routes={dashboardRoutes}
         brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
      />

      <div className="static_container py-4">
        <div className="container pt-5">
          <div dangerouslySetInnerHTML={{ '__html': content }} />
        </div></div>
      <Footer />
    </div>
  );
}

export default PressPage;