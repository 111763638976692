// import package
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";

// import component
import GridItem from "components/Grid/GridItem.js";

// import action
import { changeNewPhone, verifyNewPhone } from "../../actions/users";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import validation from "./validation";

const initialFormValue = {
  newPhoneCode: "91",
  newPhoneNo: "",
  otp: "",
};

const mobileInitialValue = {
  isLoading: false,
  type: "send", // send or resend,
  timer: 120, //sec,
  isDisable: false,
  timerStart: false,
};

const PhoneNoChange = () => {
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [mobileDetail, setMobileDetail] = useState(mobileInitialValue);

  const { newPhoneCode, newPhoneNo, otp } = formValue;

  // redux-state
  const accountData = useSelector((state) => state.account);
  const { phoneCode, phoneNo, ChangePhoneCode, ChangePhoneNo } = accountData;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
      return;
    }
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let newPhoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          newPhoneCode: dialCode,
          newPhoneNo: newPhoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ newPhoneNo } };
    }
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleBlurPhone = (e) => {
    setToched({ ...toched, ...{ newPhoneNo: true, newPhoneCode: true } });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleMobileSubmit = async (e) => {
    setMobileDetail({
      ...mobileDetail,
      ...{ isLoading: true, isDisable: true },
    });

    let reqData = {
      newPhoneCode,
      newPhoneNo,
    };
    try {
      let { status, loading, error, message } = await changeNewPhone(reqData);

      if (status == "success") {
        setMobileDetail({
          ...mobileDetail,
          ...{
            isLoading: false,
            isDisable: true,
            timer: mobileDetail.timer - 1,
            timerStart: true,
            type: "resend",
          },
        });
        toastAlert("success", message, "editPhoneNumber");
      } else {
        setMobileDetail({
          ...mobileDetail,
          ...{
            isLoading: false,
            isDisable: false,
          },
        });
        if (!isEmpty(error)) {
          setValidateError(error);
          return;
        }
        toastAlert("error", message, "editPhoneNumber");
      }
    } catch (err) {}
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      let reqData = {
        otp,
      };
      const { status, loading, error, message, result } = await verifyNewPhone(
        reqData
      );
      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);
        setMobileDetail(mobileInitialValue);
        toastAlert("success", message, "editPhoneNumber");
      } else {
        setValidateError(error);
      }
    } catch (err) {}
  };

  useEffect(() => {
    setValidateError(true);
  }, []);

  useEffect(() => {
    if (phoneNo) {
      let countcode = ChangePhoneCode.length;
      let dd = ChangePhoneNo.slice(countcode + 1);

      setFormValue({
        newPhoneCode: ChangePhoneCode,
        newPhoneNo: dd,
      });
    }
  }, [phoneNo]);

  useEffect(() => {
    if (mobileDetail.timer > 0 && mobileDetail.timerStart == true) {
      const intervalId = setInterval(() => {
        setMobileDetail({
          ...mobileDetail,
          ...{ timer: mobileDetail.timer - 1 },
        });
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (mobileDetail.timer == 0 && mobileDetail.timerStart == true) {
      setMobileDetail({
        ...mobileDetail,
        ...{
          timer: 120,
          timerStart: false,
          isDisable: false,
        },
      });
    }
  }, [mobileDetail.timer]);

  return (
    <GridItem xs={12} sm={12} md={4} lg={4}>
      {phoneNo && (
        <div className="form-group">
          <label>
            Current Phone Number<span class="textRed">*</span>
          </label>
          <div class="input-group mb-3">
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNo}
              disabled={true}
              specialLabel={false}
            />

            <div class="input-group-append"></div>
          </div>
        </div>
      )}

      <div className="form-group">
        <label>
          Phone Number<span class="textRed">*</span>
        </label>
        <div class="input-group mb-3">
          <PhoneInput
            placeholder="Enter phone number"
            value={newPhoneCode + newPhoneNo}
            onChange={handlePhoneNumber}
            onBlur={handleBlurPhone}
            specialLabel={false}
          />

          <div class="input-group-append">
            <button
              type="button"
              className="btn btn-primary text-uppercase py-2 my-0"
              disabled={
                mobileDetail.isDisable ||
                !isEmpty(validateError.newPhoneCode) ||
                !isEmpty(validateError.newPhoneNo)
              }
              onClick={handleMobileSubmit}
            >
              {mobileDetail.isLoading && <i class="fas fa-spinner fa-spin"></i>}
              {mobileDetail.type == "send" ? "Send OTP" : "Resend OTP"}
            </button>
          </div>

          {validateError.newPhoneNo && (
            <span className="error-message">{validateError.newPhoneNo}</span>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>Enter OTP</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            name="otp"
            value={otp}
            onChange={handleChange}
            onBlur={handleBlur}
            pattern="[0-9]*"
          />

          <div className="input-group-append">
            <span className="input-group-text">
              {mobileDetail.timer != 0 && mobileDetail.timer != 120 && (
                <small className="textBlue">{mobileDetail.timer} Sec</small>
              )}
            </span>
          </div>
        </div>
        {toched.otp && validateError.otp && (
          <span className="error-message">{validateError.otp}</span>
        )}
      </div>
      <div className="form-group ">
        <button
          type="button"
          className="btn btn-primary text-uppercase py-2 my-0"
          disabled={!isEmpty(validateError)}
          onClick={handleFormSubmit}
        >
          {loader && <i class="fas fa-spinner fa-spin"></i>}
          Add or verify Phone Number
        </button>
      </div>
    </GridItem>
  );
};

export default PhoneNoChange;
