import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import { Link } from "react-router-dom";

import ReactTooltip from 'react-tooltip';



// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ChangePassword from '../components/ChangePassword';
import TwoFA from '../components/TwoFA';
import GeneralSetting from '../components/GeneralSetting';


// import lib
import { dateTimeFormat } from '../lib/dateTimeHelper'
import isEmpty from '../lib/isEmpty';
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const SettingPage = (props) => {

      // redux-state
    const accountData = useSelector(state => state.account);
    const anncData = useSelector(state => state.announcement);

    const { loginHistory } = accountData;
  const { ...rest } = props;
// redux
const theme = useSelector(state => state.theme)

  return (
    <div className="dashboard_container page_wrap">
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest} />
          <div className="settingsContent userPages">
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Security Settings</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                  <ul className="profile_dash">
                   {
                        anncData && anncData.length > 0 && !isEmpty(anncData[0]) && <li>{anncData[0].content} - <span>Admin Announcement</span></li>
                    }

                    {
                        !isEmpty(loginHistory) && <li>{dateTimeFormat(loginHistory.createdDate, 'DD-MM-YYYY HH:mm')}, {loginHistory.broswername}, {loginHistory.ipaddress}  - <span>Last login</span></li>
                    }
                  </ul>
                </GridItem>
              </GridContainer>
              <div className="dashboard_box">
                <TwoFA />
                <ChangePassword />
              </div>
              <GeneralSetting />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SettingPage;