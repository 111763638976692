// import package
import React, { useEffect, useState } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import actions
import { getSptCat, createNewTicket } from "../../actions/supportAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import validation from "./validation";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  categoryId: "",
  message: "",
  file: {},
};

const CreateTicket = () => {
  const { t, i18n } = useTranslation();
  // state
  const [categoryList, setCategoryList] = useState([]);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();
  const [validateError, setValidateError] = useState({});

  const { categoryId, message, file } = formValue;

  // function

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let reqData = {
      categoryId,
      message,
      file,
    };
    let validationError = validation(reqData, "createTicket");
    let formData = new FormData();
    formData.append("categoryId", categoryId);
    formData.append("message", message);
    formData.append("file", file);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      return;
    }

    setLoader(true);
    try {
      const { status, loading, message, error } = await createNewTicket(
        formData
      );
      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);
        toastAlert("success", message, "createTicket");
        setTimeout(() => window.location.reload(false), 2000);

        // handleTicketList({
        //     'callFrom': 'createTicket'
        // })
      } else {
        setValidateError(error);
        toastAlert("error", message, "createTicket");
      }
    } catch (err) {}
  };

  const fetchCategory = async () => {
    try {
      const { status, loading, error, result } = await getSptCat();
      if (status == "success") {
        setCategoryList(result);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={7} lg={8}>
        <div className="contact_form settingsSelect mb-0">
          <div className="form-group">
            <label>Ticket For</label>
            <Select
              value={categoryId}
              name={"categoryId"}
              onChange={handleChange}
            >
              <MenuItem value={" "}>{"Select Issue"}</MenuItem>

              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item._id}>
                      {item.categoryName}
                    </MenuItem>
                  );
                })}
            </Select>
            {validateError.categoryId && (
              <p className="error-message">{validateError.categoryId}</p>
            )}
          </div>
          <div className="form-group">
            <label>Message to Support Team</label>
            <textarea
              rows="4"
              className="form-control"
              name={"message"}
              onChange={handleChange}
              value={message}
            />
            {validateError.message && (
              <p className="error-message">{validateError.message}</p>
            )}
          </div>
          <div className="">
            <label>Support Image</label>
            <input
              type="file"
              className="form-control"
              name={"file"}
              accept=".jpg,.jpeg,.png"
              onChange={handleFileChange}
            />

            <label>{t("MAX_1MB_IMG")}</label>

            {validateError.file && (
              <p className="error-message">{validateError.file}</p>
            )}
          </div>
          <div class="form-group mt-3">
            {loader && <i class="fas fa-spinner fa-spin"></i>}
            <input
              type="submit"
              className="btn btn-primary text-uppercase py-2"
              value="Create Ticket"
              onClick={handleFormSubmit}
              disabled={loader}
            />
          </div>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={5} lg={4}>
        <div className="supportTicketList">
          <h5 class="dash_subtitle">More About Support</h5>
          {/* <ul>
            <li>1. Login Issue</li>
            <li>2. Deposit Issue</li>
            <li>3. Withdraw Issue</li>
            <li>4. Spot Trade</li>
         
            <li>5. Two Factor & Password Update</li>
            <li>6. General</li>
          </ul> */}
          <p>
            Tell us what went wrong, or what you need help with, and we'll do
            our best to resolve the issue.Curious about the next steps, or the
            best way to fill out a support ticket?
            <a href="/faq"> Find out here.</a>
          </p>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default CreateTicket;
