// import package
import React, { useEffect, useState, useContext } from "react";

import { useHistory, Link } from "react-router-dom";

import { Scrollbars } from "react-custom-scrollbars-2";

import { getdailychart } from "../../actions/spotTradeAction";

import ReactApexChart from "react-apexcharts";

const HomeBanner = () => {
  const history = useHistory();

  const [duppairlists, setduppairlists] = useState([]);
  const [dailychage, setdailychage] = useState([]);

  useEffect(() => {
    if (dailychage && dailychage.length > 0) {
      // setPairLists(dailychage);
      // setduppairlists(pairListData)

      var newarray = [];
      for (var i = 0; i < dailychage.length; i++) {
        if (dailychage[i].secondCurrencySymbol == "INR") {
          newarray.push(dailychage[i]);
        }
      }
      setduppairlists(newarray);
    }
  }, [dailychage]);

  const getdailyupdatewithtrade = async () => {
    try {
      const { status, loading, result } = await getdailychart();
      if (status == "success") {
        setdailychage(result);
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    getdailyupdatewithtrade();
  }, []);

  const Navigate = (pairData) => {
    let pair = `${pairData.firstCurrencySymbol}_${pairData.secondCurrencySymbol}`;
    history.push("/spot/" + pair);
    window.location.reload(false);
  };
  const ordertype_changes = (type) => {
    var newarray = [];
    for (var i = 0; i < dailychage.length; i++) {
      if (dailychage[i].secondCurrencySymbol == type) {
        newarray.push(dailychage[i]);
      }
    }
    setduppairlists(newarray);
  };

  return (
    <div className="col-lg-12 tabel_tab_new" data-aos="fade-up">
      <div className="overflow_hid_we">
        <ul class="nav nav-tabs nav_icin_tab">
          <li class="active">
            <a
              className="active"
              onClick={() => {
                ordertype_changes("INR");
              }}
              data-toggle="tab"
              href="#"
            >
              <img
                src={require("../../assets/images/INR.png")}
                alt=""
                className=""
              />
              INR
            </a>
          </li>

          <li>
            <a
              data-toggle="tab"
              href="#"
              onClick={() => {
                ordertype_changes("USDT");
              }}
            >
              {" "}
              <img
                src={require("../../assets/images/usdtIcon.png")}
                alt=""
                className=""
              />
              USDT
            </a>
          </li>

          {/* 
                    <li>
                        <a data-toggle="tab" href="#" onClick={() => { ordertype_changes('ETH') }} ><img src={require("../../assets/images/ethIcon.png")} alt="" className="" />ETH</a>
                    </li> */}

          <li>
            <a
              onClick={() => {
                ordertype_changes("BTC");
              }}
              data-toggle="tab"
              href="#"
            >
              <img
                src={require("../../assets/images/coin2.png")}
                alt=""
                className=""
              />
              BTC
            </a>
          </li>

          {/* <li>
                        <a  onClick={() => { ordertype_changes('TRX') }} data-toggle="tab" href="#"><img src={require("../../assets/images/trx.png")} alt="" className="" />TRX</a>
                    </li> */}
        </ul>

        <div class="tab-content">
          <div id="home" class="tab-pane fade in active show">
            <Scrollbars style={{ width: "100%", height: 240 }}>
              <div className="table-responsive tabel_inside_content">
                <table class="table table-striped table-dark">
                  <thead>
                    <tr>
                      <th scope="col">Pair</th>
                      <th scope="col">Last Price</th>
                      <th scope="col">24H Change</th>
                      <th scope="col">Volume</th>
                      <th scope="col">Last 7 Days</th>
                      <th scope="col">Trade Now</th>
                    </tr>
                  </thead>
                  <tbody>
                    {duppairlists.length > 0 &&
                      duppairlists.map((item, i) => {
                        const options = {
                          chart: {
                            type: "area",
                            height: 50,
                            width: 150,
                            toolbar: {
                              show: false,
                            },
                            zoom: {
                              enabled: false,
                            },
                            sparkline: {
                              enabled: true,
                            },
                          },

                          dataLabels: {
                            enabled: false,
                          },
                          markers: {
                            shape: "circle",
                          },

                          legend: {
                            show: false,
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            curve: "straight",
                            colors: [item.change < 0 ? "#e70a0a" : "#0ad821"],
                          },

                          grid: {
                            show: false,
                          },

                          fill: {
                            type: "solid",
                            opacity: 0.4,
                            colors: item.change < 0 ? "#e70a0a" : "#0ad821",
                          },
                          tooltip: {
                            enabled: false,
                          },
                          responsive: [
                            {
                              breakpoint: 1300,
                              options: {
                                chart: {
                                  width: 120,
                                },
                              },
                            },
                          ],
                        };

                        return (
                          <tr>
                            <th>
                              {item.firstCurrencySymbol}/
                              {item.secondCurrencySymbol}
                            </th>
                            <td>
                              {item.markPrice[0].toFixed(item.secondFloatDigit)}
                            </td>
                            <td>{item.change}</td>

                            <td>
                              {item.secondVolume} {item.secondCurrencySymbol}
                            </td>
                            <td>
                              <ReactApexChart
                                options={options}
                                series={[{ data: item.markPrice }]}
                                type="line"
                                height={30}
                                width={80}
                              />
                            </td>
                            <td>
                              <div className="table_button_p">
                                <button
                                  onClick={() => {
                                    Navigate(item);
                                  }}
                                >
                                  Trade
                                </button>
                                {/* <button>SELL</button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
