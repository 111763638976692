// import package
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dateTimeFormat } from "../../lib/dateTimeHelper";
// import action
import { getrefferlist } from "../../actions/users";

// import lib
import isEmpty from "../../lib/isEmpty";
const ReffralDetail = (props) => {
  const { t, i18n } = useTranslation();

  const [result, setResult] = useState();

  // function
  const ListReffer = async () => {
    try {
      const { status, loading, result } = await getrefferlist();
      if (status == "success") {
        console.log("Result123", result);
        setResult(result);
      }
    } catch (err) {}
  };
  useEffect(() => {
    ListReffer();
  }, []);

  return (
    <>
      <div className="table-responsive">
        <h5>Referral List</h5>
        <table className="table dash_table">
          <thead>
            <th>{t("EMAIL_PHONE")}</th>
            <th>Date</th>
          </thead>
          <tbody>
            {result &&
              result.length > 0 &&
              result.map((item, key) => {
                return (
                  <tr>
                    <td>{isEmpty(item.email) ? item.phoneNo : item.email}</td>
                    <td>
                      {dateTimeFormat(item.createdAt, "YYYY-MM-DD HH:mm")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReffralDetail;
