// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  let nameRegex = /^[a-zA-Z]+$/;
  var checkSymbole = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  let numberAndLettersRegex = /^[0-9a-zA-Z\s]+$/;
  if (isEmpty(value.bankName)) {
    errors.bankName = "REQUIRED";
  }

  // if (!isNaN(value.bankName)) {
  //     errors.bankName = "Invalid Bank Name"
  // }
  else if (!nameRegex.test(value.bankName)) {
    errors.bankName = "Only Alphabets Allowed";
  } else if (checkSymbole.test(value.bankName)) {
    errors.bankName = "Only Alphabets Allowed";
  }

  if (isEmpty(value.accountNo)) {
    errors.accountNo = "REQUIRED";
  } else if (nameRegex.test(value.accountNo)) {
    errors.accountNo = "Only Alphabets Allowed";
  } else if (checkSymbole.test(value.accountNo)) {
    errors.accountNo = "Only Alphabets Allowed";
  }

  if (isEmpty(value.bankAddress)) {
    errors.bankAddress = "REQUIRED";
  } else if (!numberAndLettersRegex.test(value.bankAddress)) {
    errors.bankAddress = "Only Letter and Numbers Allowed";
  }

  if (value.accountNo.toString().length >= 20) {
    errors.accountNo = "Invalid Account Number";
  }
  if (isNaN(value.accountNo)) {
    errors.accountNo = "Invalid Account Number";
  }

  if (isEmpty(value.holderName)) {
    errors.holderName = "REQUIRED";
  }

  //   if (isEmpty(value.state)) {
  //     errors.state = "REQUIRED";
  //   }

  if (!isNaN(value.holderName)) {
    errors.holderName = "REQUIRED";
  } else if (!nameRegex.test(value.holderName)) {
    errors.holderName = "Only Alphabets Allowed";
  } else if (checkSymbole.test(value.holderName)) {
    errors.holderName = "Only Alphabets Allowed";
  }

  if (isEmpty(value.bankcode)) {
    errors.bankcode = "REQUIRED";
  } else if (nameRegex.test(value.bankcode)) {
    errors.bankcode = "Only Numeric Allowed";
  } else if (checkSymbole.test(value.bankcode)) {
    errors.bankcode = "Only Numeric Allowed";
  }

  if (isEmpty(value.country)) {
    errors.country = "Country Required";
  }

  if (isEmpty(value.city)) {
    errors.city = "City Required";
  }

  return errors;
};

export default validation;
