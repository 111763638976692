/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-kit-react/components/footerStyle.js";

// import action
import { getLanguage,getSiteSetting } from '../../actions/commonAction';

// import lib
import { capitalize } from '../../lib/stringCase';
import { setLang, getLang } from '../../lib/localStorage';
import isEmpty from "../../lib/isEmpty";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  // state
  const [langOption, setLangOption] = useState([])
  const [language, setLanguage] = useState('')
  const [setting, setSetting] = useState('')

  // function
  const handleLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguage(value)
    setLang(value)
    i18n.changeLanguage(value);
  }
  const settingData = async() =>{
    try {
      const { status, loading, result } = await getSiteSetting(dispatch);
      
      if (status == 'success') {
        setSetting(result);
      }
    } catch (err) { }
  }
  const fetchLanguage = async () => {
    try {
      const { status, loading, result } = await getLanguage(dispatch);
      if (status == 'success') {
        setLangOption(result);
        let lang = getLang();
        if (isEmpty(lang)) {
          let primaryData = result && result.length > 0 && result.find((el => el.isPrimary == true))
          if (primaryData) {
            setLanguage(primaryData.code)
            setLang(primaryData.code)
            i18n.changeLanguage(primaryData.code);
          }
        } else {
          setLanguage(lang)
        }
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchLanguage();
    settingData();
  }, [])

  return (
    <footer className="pt-4">
      <div className="container">
        <div className="row pb-lg-3">
        
         <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Company</h5>
            <ul className="list-inline">
              <li><Link to="/about">About us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              
            </ul>
          </div> 
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Documents</h5>
            <ul className="list-inline">
            <li><Link to="/terms">Terms & Conditions</Link></li>
              {/* <li><Link to="/disclaimer">Risk Disclaimer</Link></li> */}
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Support</h5>
            <ul className="list-inline">
              <li><Link to="/faq">FAQ's </Link></li>
              {/* <li><Link to="/refund">Refund Policy</Link></li> */}
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
          <h5>Social Media Links:</h5>
          <ul className="social_media">
            <li className="pr-4"><a href={setting && setting.youtubelink && setting.facebookLink} target="_blank"><i className="fab fa-facebook"></i></a></li>
              {/* <li className="pr-4"><a href={setting && setting.telegramlink && setting.telegramlink} target="_blank"><i className="fab fa-telegram-plane"></i></a></li> */}
              <li className="pr-4"><a href={setting && setting.twitterUrl && setting.twitterUrl} target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li className="pr-4"><a href={setting && setting.linkedinLink && setting.linkedinLink} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              {/* <li className="pr-4"><a href={setting && setting.redditlink && setting.redditlink} target="_blank"><i className="fab fa-reddit-alien"></i></a></li> */}
              <li className="pr-4"><a href={setting && setting.youtubelink && setting.youtubelink} target="_blank"><i className="fab fa-youtube"></i></a></li>
            </ul>
        </div>
        </div>
        <div className="footer_bottom text-center">
          <div className="row py-3">
            <div className="col-md-12 mb-3 mb-md-0">
              <p className="text-center">Copyright © 2022<span>JNC Exchange,</span> All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
