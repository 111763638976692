// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value, callFrom) => {
  console.log("ggggggggggggg", value);
  if (callFrom == "createTicket") {
    return createTicket(value);
  } else if (callFrom == "replyMsg") {
    return replyMsg(value);
  }
};

export const createTicket = (value) => {
  let errors = {};
  let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png)$/;
  if (isEmpty(value.categoryId)) {
    errors.categoryId = "Required";
  }

  if (isEmpty(value.message)) {
    errors.message = "Required";
  }
  if (value.file && value.file.size) {
    if (value.file.size > 1000000) {
      errors.file = "Image Size is too Large";
    } else if (!imageFormat.test(value.file.name)) {
      errors.file = "Invalid Image Format";
    }
  } else {
    errors.file = "Image is Required";
  }

  return errors;
};

export const replyMsg = (value) => {
  let errors = {};

  if (isEmpty(value.message)) {
    errors.message = "Required";
  }

  return errors;
};

export default validation;
