import React, { useEffect, useState, useContext } from "react";
import { Button } from "@material-ui/core";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import components
import HeaderLinks from "../components/Header/HeaderLinks.js";
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import Home from "../components/Home";
import { Helmet } from "react-helmet";
import MarketTable from "../components/Home/MarketTable";
import { getCMSPageHome } from "../actions/commonAction";

// import context
import SocketContext from "../components/Context/SocketContext";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getPairList } from "../actions/spotTradeAction";

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const HomePage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [pairLists, setPairLists] = useState([]);
  const [homefunction1, sethomefunction1] = useState({});
  const [homesignup1, sethomesignup1] = useState({});
  const [homesignup2, sethomesignup2] = useState({});
  const [homesignup3, sethomesignup3] = useState({});
  const [homefunction2, sethomefunction2] = useState({});
  const [homefunction3, sethomefunction3] = useState({});
  const [homehead, sethomehead] = useState({});
  const [homecontent, sethomecontent] = useState({});

  const [homefunctionhead, sethomefunctionhead] = useState({});

  const socketContext = useContext(SocketContext);

  const { ...rest } = props;

  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    fetchPairList();
    socketContext.socket.on("marketPrice", (result) => {
      // console.log(result,'resultresultt')
      setPairLists((el) => {
        let pairList = [];
        el.map((item) => {
          if (item._id == result.pairId) {
            pairList.push({
              ...item,
              ...{
                markPrice: result.data.markPrice,
                change: result.data.change,
                // "volume":  result.volume
              },
            });
          } else {
            pairList.push(item);
          }
        });
        // let pairIndex = pairList.findIndex((item => item._id == result.pairId))
        // if (pairIndex >= 0) {
        //     pairList[pairIndex].markPrice = result.data.markPrice
        //     pairList[pairIndex].change = result.data.change
        // }
        return pairList;
      });
    });

    cmsAllcontentCall();
    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, []);

  const cmsAllcontentCall = async () => {
    try {
      const { status, result } = await getCMSPageHome();
      if (status == "success") {
        console.log("ddddddddddd", result);
        result.map((item) => {
          if (item.identifier == "home_function_head") {
            sethomefunctionhead(item);
          }
          if (item.identifier == "home_function2") {
            console.log("274", item);
            sethomefunction2(item);
          }
          if (item.identifier == "home_function3") {
            console.log("279", item);
            sethomefunction3(item);
          }
          if (item.identifier == "home_trust_head") {
            sethomehead(item);
          }
          if (item.identifier == "home_function_head") {
            console.log("261", item);
            sethomefunctionhead(item);
          }

          if (item.identifier == "home_signup3") {
            sethomesignup3(item);
          }
          if (item.identifier == "home_signup2") {
            sethomesignup2(item);
          }
          if (item.identifier == "home_signup1") {
            sethomesignup1(item);
          }

          if (item.identifier == "home_trust_content") {
            sethomecontent(item);
          }
          if (item.identifier == "home_function1") {
            sethomefunction1(item);
          }
        });
      }
    } catch (err) {}
  };

  const fetchPairList = async () => {
    try {
      const { status, loading, result } = await getPairList();

      if (status == "success") {
        setPairLists(result, dispatch);
      } else {
      }
    } catch (err) {}
  };

  // redux
  const theme = useSelector((state) => state.theme);

  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header
        className="header"
        color="transparent"
        routes={dashboardRoutes}
        // brand={<img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />}
        brand={
          theme == "dark" ? (
            <img
              src={require("../assets/images/logo_white.svg")}
              alt="logo"
              className="img-fluid"
            />
          ) : (
            <img
              src={require("../assets/images/logo.svg")}
              alt="logo"
              className="img-fluid"
            />
          )
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>JNC Exchange</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Helmet application" />
        <meta name="keywords" content="HTML, CSS, JavaScript" />
      </Helmet>
      <Home />
      <div className="token_new_section black_ae bg_light-white">
        <div className="container content-container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-12">
              <Slider {...settings}>
                {pairLists && pairLists.length > 0
                  ? pairLists.map((item, i) => {
                      return (
                        <div className="slider_contet">
                          <p>
                            {item.firstCurrencySymbol}/
                            {item.secondCurrencySymbol}
                          </p>
                          <h3>
                            {item.markPrice.toFixed(item.secondFloatDigit)}{" "}
                            {item.change < 0 ? (
                              <img
                                src={require("../assets/images/downred.png")}
                                alt=""
                                className=""
                              />
                            ) : (
                              <img
                                src={require("../assets/images/upgreen.png")}
                                alt=""
                                className=""
                              />
                            )}{" "}
                          </h3>
                        </div>
                      );
                    })
                  : ""}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="token_new_section gp_gray bg_light-gray">
        <div className="container content-container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6" data-aos="fade-up">
              <div className="token_new_card">
                <div>
                  <p>Trade Now</p>
                  <h2>
                    Start Trading & Earn <span>NOW!</span>
                  </h2>
                  <Button
                    onClick={() => history.push("/spot")}
                    className="primary_btn mt-md-5 pt-3 mb-md-0 mt-3 mb-4"
                  >
                    Trade Now
                  </Button>
                </div>
                <img
                  src={require("../assets/images/section_2_i_1.png")}
                  alt=""
                  className="width_12"
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6" data-aos="fade-up">
              <div className="token_new_card token_new_card_11_22">
                <div className="gd_ted">
                  <p>P2P</p>
                  <h2>
                    <span className="orince_colo">Buy/Sell</span> Your Crypto
                    Locally
                  </h2>
                  <Button
                    onClick={() => history.push("/p2p")}
                    className="primary_btn mt-md-5 pt-3 mb-md-0 mt-3 mb-4"
                  >
                    Buy Now
                  </Button>
                </div>
                <img
                  src={require("../assets/images/section_2_i_2.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_calculator gp_gray bg_light-white">
        <div className="container content-container">
          <div className="row">
            <div className="col-lg-12 clas_heasdin" data-aos="fade-up">
              <h1>Market Trend</h1>
            </div>

            <MarketTable />
          </div>
        </div>
      </div>

      <div className="home_calculator black_ae bg_light-gray">
        <div className="container content-container">
          <div className="row">
            <div className="col-lg-12 clas_heasdin" data-aos="fade-up">
              <div
                dangerouslySetInnerHTML={{
                  __html: homefunctionhead && homefunctionhead.content,
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
              <div className="card_digital_currnsy">
                <img
                  src={require("../assets/images/digital_curr_1.png")}
                  alt=""
                  className=""
                />

                <div
                  dangerouslySetInnerHTML={{
                    __html: homefunction1 && homefunction1.content,
                  }}
                />
              </div>
            </div>
            <div className=" col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
              <div className="card_digital_currnsy">
                <img
                  src={require("../assets/images/didital_curr_2.png")}
                  alt=""
                  className=""
                />

                <div
                  dangerouslySetInnerHTML={{
                    __html: homefunction2 && homefunction2.content,
                  }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4" data-aos="fade-up">
              <div className="card_digital_currnsy">
                <img
                  src={require("../assets/images/digital_curr_3.png")}
                  alt=""
                  className=""
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: homefunction3 && homefunction3.content,
                  }}
                />

                {/* <h3>The most secured <br />
                  currency ever</h3>
                <p>JNC Exchange is the most secure Crypto Exchange platform that is made with a secured algorithm, SSL and with other security concerns.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_calculator bg_light-gray">
        <div className="container content-container">
          <div className="row">
            <div className="col-lg-12 clas_heasdin" data-aos="fade-up">
              <h1>How can you easily Register here?</h1>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-6 col-lg-4 "
              data-aos="fade-up"
            >
              <div className="getstarted_section">
                <div className="icon_ing">
                  <img
                    src={require("../assets/images/gt_start_i_1.png")}
                    alt=""
                  />
                </div>
                <div className="content_get_start">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homesignup1 && homesignup1.content,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-6 col-lg-4 "
              data-aos="fade-up"
            >
              <div className="getstarted_section">
                <div className="icon_ing">
                  <img
                    src={require("../assets/images/gt_start_i_2.png")}
                    alt=""
                  />
                </div>
                <div className="content_get_start">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homesignup2 && homesignup2.content,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-6 col-md-6 col-lg-4 "
              data-aos="fade-up"
            >
              <div className="getstarted_section">
                <div className="icon_ing">
                  <img
                    src={require("../assets/images/gt_start_i_3.png")}
                    alt=""
                  />
                </div>
                <div className="content_get_start">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homesignup3 && homesignup3.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_calculator black_ae bg_light-white">
        <div className="container content-container">
          <div className="row">
            <div className="col-lg-12 clas_heasdin" data-aos="fade-up">
              <div className="why_content_right">
                {/* <h1>Why should you trust JNC Exchange</h1>
                <p>JNC Exchange debuted its own cryptocurrency, dubbed 'JNC.' In the future, the JNC Token will be a utility token that can be used to pay for specific services. Token is built on community, which corresponds to the platform's core goal of financial inclusion in the Indian bitcoin market and boosting Indian users in the cryptocurrency world.</p> */}

                <div
                  className="jncxfeaturesTop"
                  dangerouslySetInnerHTML={{
                    __html: homehead && homehead.content,
                  }}
                />

                <div className="inner_why_content">
                  <div
                    className="jncxfeatures"
                    dangerouslySetInnerHTML={{
                      __html: homecontent && homecontent.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_calculator gb_app_sectio bg_light-gray">
        <div className="container content-container">
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-12 clas_heasdin"
              data-aos="fade-up"
            >
              <div className="padin_for_timer text-center">
                <h1>Trade Anytime, Anywhere</h1>
                <p className="text_over_heing mb-1">
                  Download and Install Our App to Start Trading
                </p>
                <div className="social_icon">
                  <a
                    href="https://play.google.com/store/apps/details?id=jncx.io"
                    target="_blank"
                  >
                    {" "}
                    <img
                      src={require("../assets/images/playstore.png")}
                      alt="Store"
                      className="img-fluid"
                    />
                  </a>

                  {/* <a href="#" target="_blank"> */}
                  <div className="defaultrraw">
                    <img
                      src={require("../assets/images/appstore.png")}
                      alt="Store"
                      className="img-fluid"
                    />
                  </div>

                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
