// import package
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import action
import { replyMessage, closeTicket } from "../../actions/supportAction";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  message: "",
  supportimage: "",
};

const ReplyConversation = (props) => {
  // props
  const { ticketId, receiverId, replyChatFun, closeTicketFun } = props;

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const { message, supportimage } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidateError(validation(formData, "replyMsg"));
  };
  const handleFile = async (e) => {
    const { name, files } = e.target;
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;
    let formData = { ...formValue, ...{ [name]: files[0] } };
    console.log("files[0]files[0]files[0]", files[0]);
    if (files[0].size > 1000000) {
      setFormValue(formData);
      setValidateError({ supportimageerr: "Too Large" });
    } else if (!imageFormat.test(files[0].name)) {
      setFormValue(formData);
      setValidateError({ supportimageerr: "Invalid File format" });
    } else {
      setFormValue(formData);
      setValidateError({});
    }
  };

  const handleSubmit = async () => {
    let reqData = {
      message,
      receiverId: receiverId,
      ticketId: ticketId,
    };
    setValidateError({});
    if (isEmpty(message) && isEmpty(supportimage.name)) {
      toastAlert("error", "Please enter  message or image");

      return;
    }
    if (supportimage) {
      if (supportimage.size > 1000000) {
        setValidateError({ supportimageerr: "Too Large" });
        return;
      }
    }

    const formData = new FormData();
    formData.append("receiverId", receiverId);
    formData.append("ticketId", ticketId);
    if (message) formData.append("message", message);
    if (supportimage) formData.append("supportimage", supportimage);

    try {
      const { status, loading, error, result } = await replyMessage(formData);
      if (status == "success") {
        setFormValue(initialFormValue);
        replyChatFun(result);
        setValidateError(validation(initialFormValue, "replyMsg"));
      }
    } catch (err) {}
  };

  const handleCloseTicket = async () => {
    let reqData = {
      ticketId: ticketId,
    };
    try {
      const { status, loading, error, message, result } = await closeTicket(
        reqData
      );
      if (status == "success") {
        closeTicketFun(result.status);
        toastAlert("success", message, "supportTicket");
        setTimeout(() => window.location.reload(false), 2000);
      }
    } catch (err) {}
  };

  useEffect(() => {
    setValidateError(validation(formValue, "replyMsg"));
  }, []);

  return (
    <div className="messageTypeBox contact_form">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="d-block">Reply to Support Team</label>
            {/* <textarea
                            rows="2"
                            className="form-control"
                            onChange={handleChange}
                            name="message"
                            value={message}
                        /> */}
            <div className="footer_chat">
              <div className="form-group">
                <div className="input_position">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="write message..."
                    name="message"
                    value={message}
                    onChange={handleChange}
                  />
                  <div className="flx_amount_just">
                    <div className="button_section_chat">
                      <div>
                        <i class="fas fa-paperclip"></i>
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          name="supportimage"
                          onChange={handleFile}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <label className="d-block">
                      {supportimage && supportimage.name}
                    </label>
                  </div>

                  <label className="d-block"> Max 1MB in jpg/png/JPEG</label>
                </div>
                {/* { validateError.message && <p className="error-message">{validateError.message}</p>} */}
                <label className="d-block">
                  {" "}
                  {validateError.supportimageerr && (
                    <p className="error-message">
                      {validateError.supportimageerr}
                    </p>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <p className="submitChat">
            <button
              type="button"
              class="btn btn-outline text-uppercase py-2"
              disabled={!isEmpty(validateError)}
              onClick={handleSubmit}
            >
              Reply
            </button>
            <Link onClick={handleCloseTicket} className="ml-0 ml-sm-3">
              Satisfied, Close this ticket
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReplyConversation;
