let key = {};

if (process.env.REACT_APP_MODE === "production") {
  console.log("Set Production Config");
  const API_URL = "https://api.jncx.io";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn", //ant
    API_URL: "https://api.jncx.io",
    FRONT_URL: "https://jncx.io/",
    // API_URL: "http://52.4.57.222:2053",
    // FRONT_URL: "http://3.217.48.132",
    ADMIN_URL: "https://controls.jncx.io",
    SOCKET_URL: "https://api.jncx.io",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },

    socialMedia: {
      telegramUrl: "https://telegram.org/jncx",
      twitterUrl: "https://www.twitter.com/jncx_india",
      linkedinUrl: "https://www.linkedin.com/jncx",
      redditUrl: "https://www.reddit.com/company/jncx",
      youtubeUrl: "https://www.youtube.com/channel/UCkgIx6cYNapkeJwH_uEaiwg",
    },
    exchangeurl: "https://3.217.48.132/",
    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",

    MerchantID: "f793fce60179451188d6137b03310103",
    returnUrl: "https://api.jncx.io/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "945e82a7c27b4d4ea5f925de9a281256",

    exchangeurl: "https://jncx.io/",
  };
} else if (process.env.REACT_APP_MODE === "development") {
  console.log("Set dev env Config");
  const API_URL = "http://jncapi.wealwin.com";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn",
    // RECAPTCHA_SITE_KEY: "6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi",
    API_URL: "https://jncapi.wealwin.com",
    FRONT_URL: "https://jnc.wealwin.com/",
    ADMIN_URL: "https://jncapi.wealwin.com",
    SOCKET_URL: "https://jncapi.wealwin.com",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/j",
      twitterUrl: "https://www.twitter.com/j",
      linkedinUrl: "https://www.linkedin.com/j",
      redditUrl: "https://www.reddit.com/j",
      youtubeUrl: "https://www.youtube.com/j",
    },

    // zaakpayurl:"https://zaakstaging.zaakpay.com/api/paymentTransact/V8",

    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",

    MerchantID: "f793fce60179451188d6137b03310103",
    returnUrl: "https://jncapi.wealwin.com/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "945e82a7c27b4d4ea5f925de9a281256",

    exchangeurl: "https://jnc.wealwin.com/",
  };
} else if (process.env.REACT_APP_MODE === "stage") {
  console.log("Set stage env Config");
  const API_URL = "https://stagingapi.jncx.io/";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LdfEEIcAAAAANidC5VzEl3SRkCRChH_Nk3f22wn",
    // RECAPTCHA_SITE_KEY: "6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi",
    API_URL: "https://stagingapi.jncx.io",
    FRONT_URL: "https://staging.jncx.io/",
    ADMIN_URL: "https://stagingcontrols.jncx.io",
    SOCKET_URL: "https://stagingapi.jncx.io",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/j",
      twitterUrl: "https://www.twitter.com/j",
      linkedinUrl: "https://www.linkedin.com/j",
      redditUrl: "https://www.reddit.com/j",
      youtubeUrl: "https://www.youtube.com/j",
    },

    // zaakpayurl:"https://zaakstaging.zaakpay.com/api/paymentTransact/V8",

    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",

    MerchantID: "f793fce60179451188d6137b03310103",
    returnUrl: "https://jncapi.wealwin.com/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "945e82a7c27b4d4ea5f925de9a281256",

    exchangeurl: "https://jnc.wealwin.com/",
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost"; //http://192.168.29.63:3000/  http://localhost
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6Lf-oQAaAAAAAHsxE5WyOHwmKav2lOfeL5KeA7AV",
    API_URL: `${API_URL}:2053`,
    SOCKET_URL: `${API_URL}:2053`,
    FRONT_URL: "http://localhost:3000/",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/mudra",
      twitterUrl: "https://www.twitter.com/mudra",
      linkedinUrl: "https://www.linkedin.com/mudra",
      redditUrl: "https://www.reddit.com/mudra",
      youtubeUrl: "https://www.youtube.com/mudra",
    },
    exchangeurl: "http://localhost:3002/home/",
    MerchantID: "f793fce60179451188d6137b03310103",
    returnUrl: "https://jncapi.wealwin.com/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "945e82a7c27b4d4ea5f925de9a281256",
  };
}

export default key;

// 6Lcz48IbAAAAAJffeGirGcjkr3PIKO4sZxBaf66G - secret key

// 6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi - site key
