// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (value.userId == "") {
    errors.userId = "REQUIRED";
  }

  if (value.currencyselected == "") {
    errors.currencyselected =
      "Please Select a Cyprocurreny would like to trade";
  }
  if (value.paymode == "") {
    errors.currencyselected = "Please Select Payment Type";
  }
  if (value.posttocurrency == "") {
    errors.posttocurrency = "Please Select a local currency";
  }
  if (value.minlimit == "" || value.minlimit == 0 || value.minlimit < 0) {
    errors.postminlimit = "Please Enter the Valid Min Limit";
  } else if (isNaN(value.minlimit) == true) {
    errors.postminlimit = "Please Enter the Min Limit number only";
  }
  console.log(typeof value.minlimit, "value.price");
  if (value.price == "" || value.price == 0 || value.price < 0) {
    errors.price = "Please Enter the valid Price ";
  } else if (isNaN(value.price) == true) {
    errors.price = "Please Enter the Price as number only";
  }
  if (value.quantity == "" || value.quantity == 0 || value.quantity < 0) {
    errors.quantity = "Please Enter the valid Quantity ";
  } else if (isNaN(value.quantity) == true) {
    errors.quantity = "Please Enter the Quantity as number only";
  }
  if (value.minlimit != "" && value.maxlimit != "") {
    if (parseFloat(value.minlimit) > parseFloat(value.maxlimit)) {
      errors.postminlimit = "Please Enter the correct Minimum Value";
    }
  }

  if (value.startDate == "") {
    errors.startDate = "Please Select date";
  } else {
    var date = new Date(value.startDate);
    if (date < new Date())
      errors.startDate = "Please Select date Greater than Current date";
  }

  if (value.postcheckboxaccept != true) {
    errors.postcheckboxaccept = "Please accept the terms and policy";
  }

  if (value.postprefcurrency == "") {
    errors.postprefcurrency = "Please Select Preferred Payments";
  } else if (isEmpty(value.paymode)) {
    errors.postprefcurrency = "Please select Preferred Payment";
  } else {
    if (value.noBank) {
      errors.postprefcurrency = value.paymode;
    }
  }

  return errors;
};

export default validation;
