// import config
import axios, { handleResp } from "../config/axios";

// import constant
import { SET_P2P_SPOT_PAIR_TRADE } from "../constant";

export const getP2PSpotPair = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/p2pSpotPair`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
    //  console.log(result);
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
    };
  }
};
export const getP2PPairList = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/p2pTradePair`,
    });
    console.log("respData.data.resultp2p---", respData.data.result);

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const postTrade = async (data) => {
  try {
    console.log("Post ad---", data);
    let respData = await axios({
      method: "post",
      url: `/api/postTrade`,
      data,
    });

    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

// export const getMyaddData = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/p2pMyadddetails`,
//         });
//         return {
//             status: 'success',
//             loading: false,
//             result: respData.data.result,
//         }
//     }
//     catch (err) {
  // handleResp(err, 'error')
//         return {
//             status: 'failed',
//             loading: false,
//         }
//     }
// }

export const getMyaddData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/p2pMyadddetails`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getMyTransactions = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getMyTransactions`,
      data,
    });
    console.log("Fiat History---", respData.data.result);
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getMyP2PHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getMyTransactions`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getMyRecentaddData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/p2pMyrecentadddetails`,
      data,
    });
    console.log("My ad data---", respData.data.result);

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      data: respData.data.data,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const updateTrade = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/updateTrade`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const cancelTrade = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/cancelTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const disputeTrade = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/disputeTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getBuyAdData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getBuyAdDetails`,
      data,
    });
    console.log("respData----", respData.data.result);
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSellAdData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getSellAdDetails`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSarchBuyData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getSarchBuyData`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSarchSellData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getSarchSellData`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const buyP2PTrade = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/buyP2PTrade`,
      data,
    });
    console.log("Resp---", respData.data);
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Resp---", err.dat);

    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const buyConfirmP2PTrade = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/buyConfirmP2PTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSingleBuyAdDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getSingleBuyAdDetails`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getSingleOrderDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getSingleOrderDetails`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getOrderStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getOrderStatus`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getChatDetails = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getChatDetails`,
      data,
    });
    console.log("getChatDetails----", respData.data.result);
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const saveChatData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/saveChatDetails`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const confirmPay = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/confirmPay`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const releaseCryptocurrency = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/releaseCryptocurrency`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---", err);
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const check2FA = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/check2FA`,
    });
    return {
      status: respData.data.success,
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    handleResp(err, 'error')
    console.log("Error---------", err);
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const cancelMyadAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/cancelMyad`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const closeTradeAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/closeOrder`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const closeTradeDateCrossed = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/closeTrade`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getTradeData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getTradeDetails`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getChatlisthistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/getChatlisthistory`,
      data,
    });
    return {
      status: respData.data.status,
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const updatevacation = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/updatevacation`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};


export const getbankdetail = async (id) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/getuserId/${id}`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, 'error')
    return {
      status: "failed",
     
    };
  }
};