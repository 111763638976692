import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getCMSPage } from '../actions/commonAction';

const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const TermsPage = (props) => {
    const { ...rest } = props;
    // state
   
    const [content, setContent] = useState('');

    // function
    const fetchCmsPage = async () => {
        try {
            const { status, loading, result } = await getCMSPage('terms');
            if (status == 'success') {
                setContent(result.content)
                document.title = result.title;
                const el = document.querySelector("meta[name='metadescription']");
                el.setAttribute("content", result.metadescription);
        
            
                const els = document.querySelector("meta[name='keywords']");
                els.setAttribute("content",result.metakeywords);
            }
        } catch (err) { }
    }

    useEffect(() => {
        fetchCmsPage()
    }, [])
// redux
const theme = useSelector(state => state.theme)
    return (
        <div className="page_wrap">
     <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />

             <div className="dashboardContent userPages">
                <div className="container">
                <div class="col-lg-12 clas_heasdin aos-init aos-animate" data-aos="fade-up">
          <h1>Terms &amp; Conditions</h1>
        </div>
                    <div dangerouslySetInnerHTML={{ '__html': content }} />
                </div></div> 
                {/* <div className="container">
        <div className="dash_top_bar content_page">
          <div className=" inner_pageheader">
                <h2>Terms Of Use</h2>
                <h3>Introduction</h3>
                <p>This <b>User Agreement</b> (this "Agreement") sets forth the legally binding terms and conditions you access to and use of any websites, mobile sites, mobile applications, desktop applications, products or services (the “Services”) offered by the MUDEX IT LAB (‘Company’),  incorporated under the laws of India</p>
                <p>The "<b>User</b>", "<b>you</b>", "<b>your</b>" shall refer to any natural person or entity and its authorized users that subscribes or uses the Services. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into this Agreement.</p>

                <p>By using any services made available through the JNC Exchange Platform, the User agrees that the User has read, understood and accepted all the terms and conditions contained in this Terms of Use agreement as well as our Privacy Policy.</p>

                <p>We may amend this Agreement related to the Services from time to time. Amendments will be effective upon our posting of such updated Agreement at this location or the amended policies or supplemental terms on the applicable Service. Your continued access or use of the Services after such posting constitutes your consent to be bound by the Agreement, as amended.</p>
                <p>By making use of JNC Exchange services, you acknowledge and agree that:</p>

                <ul>
                  <li>you are aware of the risks associated with transactions of digital currencies and their derivatives</li>
                  <li>you shall assume all risks related to the use of JNC Exchange services and transactions of digital currencies and their derivatives</li>
                  <li>JNC Exchange shall not be liable for any such risks or adverse outcomes.</li>
                </ul>
                <p>By accessing, using or attempting to use JNC Exchange services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access JNC Exchange or utilize JNC Exchange services.</p>
                
                <h3>Definitions</h3>
                <p>1. <b>JNC EXCHANGE</b> refers to P2P (peer-to-peer) decentralized crypto currency exchange platform ('this platform').</p>
                <p>2. <b>"JNC EXCHANGE", "OURSELVES", "US", "OUR"</b> and <b>"WE"</b> refers to the owners, operators, employees, directors, officers, agents, insurers, suppliers, and attorneys of the Platform.</p>
                <p>3. <b>"CLIENT", "CUSTOMER", "USER", "YOU"</b> and <b>"YOUR"</b> refers to you, an individual above the age of 18 years of age.</p>

                <p>4. <b>"PARTY"</b> refers to either You or Us and <b>"PARTIES"</b> refers to You and Us collectively, as the context may require.</p>

                <p>5. <b>COMPETENT AUTHORITY</b> means the relevant government authority in India, as notified or may be notified, depending on the context.</p>

                <p>6. <b>CONTENT</b> means any information, text, graphics, or other materials uploaded by the Company or the users, which appears on the Online Platforms for other users to access.</p>
                <p>7. <b>DIGITAL ASSETS</b> refers to Digital Currencies, their derivatives or other types of digitalized assets with a certain value.</p>
                <p>8. <b>DIGITAL CURRENCIES</b> refer to encrypted or digital tokens or crypto currencies with a certain value that are based on block chain and cryptography technologies and are issued and managed in a decentralized form.</p>
                <p>9. <b>FIAT WALLET</b> means an online address accessible through the Online Platforms and operated by a User for the storage of the User’s fiat currency holdings.</p>
                <p>10. <b>FUNDS</b> refer to both Digital Assets and fiat currency, as the case maybe.</p>
                <p>11. <b>KYC</b> means Know Your Customer requirements as determined by JNC exchange or under the AML Regulations.</p>
                <p>12. <b>LINKED BANK ACCOUNT</b> refers to any bank account owned and operated by the User and held with a Scheduled Commercial Bank, whose details were provided by the User during the activation process.</p>


                <h3>USER REGISTRATION</h3>
                <p>In order to enjoy the Services on the JNC Exchange Platform, User is required to create an account and register itself on the Platform. User will be required to provide certain information as prompted by the account registration form which generally include but not limited to:</p>
                <ul>
                  <li>Full Name</li>
                  <li>Email Address</li>
                  <li>Nationality</li>
                  <li>Telephone Number</li>
                  <li>Government Issued ID/Passport</li>
                  <li>Date of Birth</li>
                  <li>Proof of address</li>
                  <li>Bank account details</li>
                  <li>Photographs and images</li>
                  <li>Government Issued Tax Identification Number</li>
                </ul>
                <p>After registration, you must ensure that the information is true, complete, and timely updated when changed. If there are any grounds for believing that any of the information you provided is incorrect, false, outdated or incomplete, JNC Exchange reserves the right to send you a notice to demand correction, directly delete the relevant information, and, as the case may be, terminate all or part of JNC Exchange services we provide for you.</p>
                <p>If we are unable to reach you with the contact information you provided, you shall be fully liable for any loss or expense caused to JNC Exchange during your use of JNC Exchange services. You hereby acknowledge and agree that you have the obligation to update all the information if there is any change.</p>
                <p>By registering an account, you hereby authorize JNC Exchange to conduct investigations that JNC Exchange considers necessary, either directly or through a third party, to verify your identity or protect you, other users and/or finance from fraud or other financial crimes, and to take necessary actions based on the results of such investigations. You also acknowledge and agree that your personal information may be disclosed to credit bureaus and agencies for fraud prevention or financial crime prevention, which may respond to our investigations in full.</p>
                <p>You hereby agree that You shall not use the Platform or our Service, in connection with any activity violating any law, statute, ordinance, or regulation of any jurisdiction.</p>
                <p>You will also not use the Service to perform any illegal activity of any sort, in any jurisdiction, including but not limited to, money laundering, illegal gambling operations, terrorist financing, or malicious hacking.</p>
                <p>You are required to notify JNC exchange immediately of any unauthorized use of the Platform or Service or Your JNC Exchange Account that You may become aware of, by way of an email addressed to JNCex@gmail.com.</p>
                <p>The User who violates these Terms will get notified to the concerned authorities or get restricted from accessing the Platform and/or any other platform / service provided by the JNC Exchange, at JNC Exchange’s discretion. Further, such Users violating these Terms shall also be held liable and responsible for losses incurred by JNC Exchange or any User of the Platform.</p>
                <p>You hereby acknowledge and agree that You currently do not have more than one JNC Exchange Account.</p>


                <h3>PERSONAL DATA</h3>
                <p>Your personal data will be properly protected and kept confidential, but JNC exchange has the right to collect, process, use or disclose your personal data in accordance with the Terms (including the Privacy Policy) or applicable laws. Depending on the products or services concerned, your personal data may be disclosed to the following third parties:</p>

                <p>1.  Your transaction counterparty;</p>
                <p>2.  JNC exchange operators, and the shareholders, partners, investors, directors, supervisors, senior managers and employees of such entities;</p>
                <p>3.  Our joint ventures, alliance partners and business partners;</p>
                <p>4.  Our agents, contractors, suppliers, third-party service providers and professional advisers, including the parties who have been contracted to provide us with administrative, financial, research, operations, it and other services, in such areas as telecommunications, information technology, payroll, information processing, training, market research, storage and archival;</p>
                <p>5.  Third-party business partners who provide goods and services or sponsor contests or other promotional activities, whether or not in cooperation with us;</p>
                <p>6.  Insurance companies or insurance investigators and credit providers;</p>
                <p>7.  Credit bureaus, or any debt collection agencies or dispute resolution centers in the event of violation or dispute;</p>
                <p>8.  Business partners, investors, trustees or assignees (actual or expected) that promote business asset transactions (which can be broadened to include any merger, acquisition or asset sale) of JNC exchange operators;</p>
                <p>9.  Professional consultants such as auditors and lawyers;</p>
                <p>10. Relevant government regulatory agencies or law enforcement agencies to comply with laws or regulations formulated by government authorities;</p>
                <p>11. Assignees of our rights and obligations;</p>
                <p>12. Banks, credit card companies and their respective service providers;</p>
                <p>13. Persons with your consent as determined by you or the applicable contract.</p>


                <h3>Activities prohibited</h3>
                <p>In connection with your use of services and/or platform, you hereby agree that you will not:</p>
                <p>1. Violate (or assist any other party in violating) any applicable law, statute, ordinance, or regulation;</p>
                <p>2. Intentionally try to defraud (or assist in the defrauding of) the JNC exchange platform or any other user;</p>
                <p>3. Provide false, inaccurate, or misleading information;</p>
                <p>4. Trade taking advantage of any technical glitch, malfunction, failure, delay, default, or security breach;</p>
                <p>5. Take any action that interferes with, intercepts, or expropriates any system, data, or information;</p>
                <p>6. Partake in any transaction involving the proceeds of illegal activity;</p>
                <p>7. Attempt to gain unauthorized access to other user accounts, the services, or any related networks or systems;</p>
                <p>8. Use the services on behalf of any third party or otherwise act as an intermediary between JNC exchange and any third parties or acting for benefit of any third party;</p>
                <p>9. Use the services/platform for any illegal transaction;</p>
                <p>10. Use the services to engage in conduct that is detrimental to the JNC exchange or to any other user or any other third party or the public at large;</p>
                <p>11. Collect any user information from other users, including, without limitation, email addresses;</p>
                <p>12. Participate in any unfair trade practice or market manipulation, including but not limited to all such acts like pump and dump schemes, trading on any inside or non-public information acquired through sources or acts not permitted by law, wash trading, trading for the purposes of market manipulation, spoofing or layering etc.</p>
                <p>13. Use the services being provided by the JNC exchange to violate public morale, any third party interest or for any act being not permitted by the law of the land;</p>
                <p>14. Use multiple devices to access or log into your JNC exchange account at a given point of time, i.e. You shall not access your JNC exchange account through multiple devices simultaneously nor shall you ever use or try to gain access to any other third party JNC exchange account not belonging to you;</p>
                <p>15. Duplicate, distribute, create derivative works of display or commercially exploit the platform or any of its content, features or facilities, directly or indirectly, without our prior written permission nor would you have access to the platform in source-code form;</p>
                <p>16. Without the prior written consent of JNC exchange, modify, replicate, duplicate, copy, download, store, further transmit, disseminate, transfer, disassemble, broadcast, publish, remove or alter any copyright statement or label, or license, sub-license, sell, mirror, design, rent, lease, private label, grant security interest in the properties or any part of the properties, or create their derivative works or otherwise take advantage of any part of the properties;</p>
                <p>17. Defame, harass, or violate the privacy or intellectual property rights of the JNC exchange or any other user; or</p>
                <p>18. Upload, display or transmit any messages, photos, videos or other media that contain illegal goods, pornographic, violent, obscene or copyrighted images or materials for use as an avatar, in connection with a payment or payment request, or otherwise.</p>
                <p>19. In addition, you agree not to: a) use circumvention or obfuscating technologies to mask their ip addresses or to hide transaction details; b) create or circulate any technologies which violate, or which would facilitate other users to violate the terms hereof including for masking ip addresses or to obfuscate transaction details; c) misrepresent, misinform or misguide JNC exchange in any manner; d) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the service or of any related website, other websites, or the internet.</p>
                <p>JNC Exchange reserve the right to investigate and/or take appropriate action against You in Our sole discretion if You engage in prohibited activities or violate any other provision of these Terms or otherwise create liability for Us or any other person. Such action may include, in Our sole and absolute discretion, terminating Your JNC Exchange Account, reporting you to law enforcement authorities without providing any notice to You about any such report and confiscating any balance remaining in the JNC Exchange Account which has been terminated.</p>
                <p>Users are cautioned against undertaking any suspicious activity on or through the Platform or Services. In the event of any suspicious or large transactions being undertaken by You, JNC Exchange shall have the right but not the obligation to verify such activity by sharing Your IP address and other KYC details with the relevant authority, and if required, suspend the transactions or freeze your access.</p>
                
                <h3>TAXES</h3>
                <p>It is Your responsibility to determine, collect, report, and submit any/all taxes applicable to the payments and/or your digital assets you make or receive in connection with the use of the Platform and the Service to the appropriate tax authority. JNC Exchange is not responsible for determining whether taxes apply to your transaction, or for collecting, reporting or remitting any taxes arising from any transaction undertaken via the Platform or Services.</p>

                <h3>FINANCIAL ADVICE</h3>
                <p>The Content on the Online Platforms is provided for general information only. It is not intended to amount to investment advice on which you should rely. You must obtain specialist advice before taking, or refraining from, any action on the basis of the content provided on the Online Platforms. Platforms are executed automatically, based on the parameters of your order instructions and in accordance with our trade execution procedures and you are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance. JNC Exchange will not be liable for any loss suffered by you in connection with any transaction involving digital assets.</p>

                <h3>COMPLIANCE</h3>
                <p>You are solely responsible for ensuring that your use of the Services is in compliance with all laws, rules and regulations applicable to you and the right to access the Services is automatically revoked where use of the Services is prohibited or to the extent that the offering, sale or provision of the Services conflicts with any law, rule or regulation applicable to you.</p>
                <p>All users of the JNC Exchange Platform and any of its services acknowledge and declare that the source of their funds come from a legitimate manner and are not derived from illegal activities. JNC Exchange maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, and terminate the user account and user fiat which are flagged or investigated by legal mandate.</p>

                <h3>COMPLAINTS</h3>
                <p>If the user has any complaints, feedback or questions, the user shall contact us by using the form provided in the URL http://JNC.exchange/ and JNC Exchange shall make our best efforts to resolve the issue with expediency. JNC Exchange shall not provide any support services to walk-in users.</p>

                <h3>DISPUTE, GOVERNING LAW AND JURISDICTION</h3>
                <p>This Agreement and the rights and obligations there under and the relations of the parties and all matters arising under or in connection with this Terms of Use, including the construction, validity, performance or termination there under shall be governed and construed in accordance with Indian laws. JNC Exchange may also consider Arbitration as the appropriate mechanism for dispute resolution and arbitration proceedings shall be governed by Arbitration and Conciliation Act 1996 and rules framed there under and any amendment, modification, statutory enactment thereto from time to time.</p>
               
               <h3>LIMITATION OF LIABILITY</h3>
               <p>Users of this website are responsible for their actions in connection with the use of the Website, Service, and their Accounts. Users are also responsible for protecting access information to the Website including, but not limited to, user names, passwords, and bank account details. JNC Exchange is not responsible for the outcome, whether positive or negative, of any action performed by any of its users within or related to the Website. Some deposits and withdrawal methods require JNC exchange to use personal details of the user including, but not limited to, name, address, email, phone number, and bank account number, IFSC code, etc. and you agree that JNC exchange can use these information to facilitate deposits and withdrawals.</p>
               <p>The parties agree that in no event shall JNC exchange or its affiliates, officers, directors, agents, employees, and suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with the use of our Website, Service, or these Terms of Use. In case the applicable laws do not permit the limitation or exclusion of damages as described above, our aggregate liability (including the liability of our affiliates, officers, directors, agents, employees, and suppliers)in respect of claims based on events arising out of or in connection with any single member’s use of the Website, whether in contract or tort or otherwise, shall in no circumstances exceed the amount of the transaction(s) that are the subject of the claim less any amount of commission that may be due and payable in respect of such transaction(s).</p>
               <p>Please note that all Services provided through the JNC exchange Platforms are only for domestic and private use. You agree not to use the JNC exchange Platforms for any commercial or business purposes without obtaining a legally valid license to do so in accordance with these Terms.</p>
              <p>Notwithstanding anything to the contrary, JNC exchange shall not be obligated to make any payment or take any other action under these Terms if it is believed to be in good faith that such action may constitute a violation, or contribute to any violation, of any applicable law and JNC exchange shall not be liable to you for any claims, losses, or damages arising from our exercise of its right provided herein.</p>
              <p>JNC exchange will neither be liable for any loss caused to the User due to discontinuation of any crypto/digital asset that is being offered through the JNC exchange platform nor for any discontinuation, alteration, suspension or termination of any part of the services offered at JNC exchange Platform caused or occasioned by any Force Majeure event or any change in applicable law with respect to crypto/digital assets.</p>
              <p>JNC Exchange will not be liable for any breach of these Terms of Use, where the breach is due to abnormal and unforeseeable circumstances beyond JNC Exchange’s control, the consequences of which would have been unavoidable despite all effects to the contrary, nor is JNC exchange liable where the breach is due to the application of mandatory legal requirements.</p>
             
          </div>
        </div>
      </div> */}
            <Footer />
        </div>
    );
}

export default TermsPage