import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getCMSPage } from '../actions/commonAction';

const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const TermsPage = (props) => {
    const { ...rest } = props;
    // state
   
    const [content, setContent] = useState('');
const [ourstory, setourstory] = useState({});
const [ourservice, setourservice] = useState({});

    // function
    const fetchCmsPage = async () => {
      try {
        const { status, loading, result } = await getCMSPage('about_us_ourstory');
        console.log("resultresultresultresultresult",result)
        if (status == 'success') {
          console.log('result',result)
          setContent(result.content)
          setourstory(result)
          document.title = result.metatitle;
          const el = document.querySelector("meta[name='description']");
          el.setAttribute("content", result.metadescription);
          document.keywords = el;
          console.log('el', el)
          const els = document.querySelector("meta[name='keywords']");
          els.setAttribute("content", result.metakeywords);
          document.description = els;
          console.log('els', els)
        }
      } catch (err) { }
    }
    const fetchAboutService=async()=>{
    
      try {
        const { status, loading, result } = await getCMSPage('about_us_services');
        if (status == 'success') {
          setourservice(result,document.title = "About Us")
        }
      } catch (err) { }
    }

    useEffect(() => {
      fetchCmsPage()
      fetchAboutService()
    }, [])
// redux
const theme = useSelector(state => state.theme)
    return (
        <div className="page_wrap">
     <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />

             <div className="dashboardContent userPages">
                <div className="container">
                <div class="col-lg-12 clas_heasdin aos-init aos-animate" data-aos="fade-up">
                  <h1>About Us</h1>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="aboutContent">
                    <div dangerouslySetInnerHTML={{ '__html': ourstory&&ourstory.content }} /></div>
                    </div>
                    <div className="col-md-6">
                    <div className="aboutImg">
                    <img src={require("../assets/images/aboutImg.jpg")} alt="logo" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="aboutContent">
                    <div dangerouslySetInnerHTML={{ '__html': ourservice&&ourservice.content }} /></div>
                    </div>
                  </div>
                  
                </div>
            </div> 
        
            <Footer />
        </div>
    );
}

export default TermsPage