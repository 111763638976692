// import package
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link, withRouter } from "react-router-dom";

import clsx from 'classnames';

// import action
import { getTradeData,getChatlisthistory } from '../../actions/p2paction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';
import { transactionStatus } from '../../lib/displayStatus';

const CompletedTrade = () => {
    // state
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [userId, setUserId ]=useState("")
    const [chatdata, setChatdata]=useState([]);
    
    const authDetails = useSelector(state => state.auth);

    // funtion
    const fetchTransaction = async (userId) => {
        try {
            var ids='4';
		    let data = {'type':ids,userid:userId}
            const { status, loading, result } = await getTradeData(data);
            setLoader(loading)
            if (status != false) {
                setData(result)
            }
        } catch (err) { }
    }
    const getmessages = async (userId) => {
        try {
            let data = {userid:userId}
            const { status, loading, result } = await getChatlisthistory(data);
            setLoader(loading)
            if (status == true) {
                setChatdata(result)
            }
        } catch (err) { }

	
	  }

    useEffect(() => {
        if(authDetails.userId){
            setUserId(authDetails.userId)
            fetchTransaction(authDetails.userId);
            getmessages(authDetails.userId);
        }
        
        
    }, [authDetails])
    return (
        <div className="table-responsive">
            <table className="table mb-0">
            <thead>
                    <tr>
                    <th>Email</th>
                        <th>Status</th>
                        <th>Ad Type</th>
                        <th>Trade Pair</th>
                        <th>Quantity</th>
                        <th>Price</th>                     
                        <th>Trade Date</th>
                        <th>Action</th>
                       
                    </tr>
                </thead>
                <tbody>
                    {
                        loader && <div>Loading...</div>
                    }
                    {
                        !loader && data && data.length > 0 && data.map((item, key) => {
                            if(item.dispute_status==0){
                            if(item.status==4){
                                var tradestatus= "Completed";
                            }
                          
                            var messages=0
                            if(chatdata && chatdata.length>0){
                                for(var j=0;j<chatdata.length; j++){
                                    if(chatdata[j].orderbookId=== item._id){
                                        messages=messages+1
                                    }
                                }
                            }
                            return (
                                <tr key={key}>
                                   {item.from_userId!=userId ?<td>{item&&item.senderdetails&&item.senderdetails.email}</td>: <td>{item&&item.receiverdetails&&item.receiverdetails.email}</td>}
                                    <td className="textStatusOrange">{tradestatus}</td>
                                    {  item.to_userId==userId ?  <td> Buy{" "}{item.firstCurrency}</td>:  <td> Sell{" "}{item.firstCurrency}</td>   }
                                    <td>{item.firstCurrency}-{item.secondCurrency}</td>                                 
                                    <td>{(item.crypto_amount).toFixed(4 )}{" "}{item.firstCurrency}</td>
                                    <td>{(item.fiat_amount).toFixed(0)}{" "}{item.secondCurrency}</td>
                                    <td>{dateTimeFormat(item.created_date, 'YYYY-MM-DD HH:mm')}</td>
                                    <td><Link to={"/Chat/" + item._id} ><button  onClick={""} className="button_take_control btn btn-primary">View/ Message</button ></Link></td>
                                    {/* <td className={clsx({ "textDepositGreen": ['fiat_deposit', 'coin_deposit'].includes(item.paymentType), "textWithdrawRed": ['fiat_withdraw', 'coin_withdraw'].includes(item.paymentType) })}>
                                        {transactionStatus(item.paymentType)}
                                    </td> */}
                                </tr>
                            )
                                }
                        })
                    }
                    {
                        !loader && data && data.length <= 0 && <td colspan="6" align="center">
                            No Record
                            </td>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CompletedTrade;