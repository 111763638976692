// import package
import React, { useState, useEffect } from "react";
import clsx from "classnames";
import { useHistory } from "react-router-dom";
// import action
import { getorderHistory } from "../../actions/dashboardAction";
import { toastAlert } from "lib/toastAlert";
import { capitalize } from "../../lib/stringCase";
// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { transactionStatus } from "../../lib/displayStatus";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
const RecentTransaction = () => {
  const history = useHistory();
  // state
  const [data, setData] = useState([]);
  const [DownloadData, setDownloadData] = useState([]);
  const [loader, setLoader] = useState(true);

  // funtion
  const fetchTransaction = async () => {
    try {
      const { status, loading, result } = await getorderHistory();
      setLoader(loading);
      if (status == "success") {
        setData(result);

        const headers = [
          [
            "Date",
            "Transaction Id",
            "Trade Type",
            "Contract",
            "Price",
            "Quantity",
            "Filled / Remaining	",
            "Order Value",
            "Side",
          ],
        ];

        const download_data =
          result &&
          result.length > 0 &&
          result.map((elt) => [
            dateTimeFormat(elt.orderDate, "YYYY-MM-DD HH:mm"),
            elt._id,
            elt.orderType == "limit" ? "Limit" : "Market",
            elt.pairName,
            elt.price,
            elt.quantity,
            elt.quantity - elt.filledQuantity,
            elt.orderValue && elt.orderValue.toFixed(4),
            elt.buyorsell,
          ]);
        let download_csv = headers.concat(download_data);

        setDownloadData(download_csv);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchTransaction();
  }, []);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "Order History";
    const headers = [
      [
        "Date",
        "Transaction Id",
        "Trade Type",
        "Contract",
        "Price",
        "Quantity",
        "Filled / Remaining	",
        "Order Value",
        "Side",
      ],
    ];

    const download_data =
      data &&
      data.length > 0 &&
      data.map((elt) => [
        dateTimeFormat(elt.orderDate, "YYYY-MM-DD HH:mm"),
        elt._id,
        elt.orderType == "limit" ? "Limit" : "Market",
        elt.pairName,
        elt.price,
        elt.quantity,
        elt.quantity - elt.filledQuantity,
        elt.orderValue && elt.orderValue.toFixed(4),
        elt.buyorsell,
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: download_data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("orderhistory.pdf");
  };

  const copyValue = (val) => {
    // Create a "hidden" input
    var aux = document.createElement("input");

    // Assign it the value of the specified element
    aux.setAttribute("value", val);

    // Append it to the body
    document.body.appendChild(aux);

    // Highlight its content
    aux.select();

    // Copy the highlighted text
    document.execCommand("copy");

    // Remove it from the body
    document.body.removeChild(aux);
    toastAlert("success", "Copied", "wallet");
  };

  return (
    <div className="table-responsive">
      <br />
      <span>
        {data.length > 0 ? (
          <CSVLink data={DownloadData} filename={"orderhistory.csv"}>
            <button className="btn btn-primary text-uppercase py-2 m-0">
              Download csv
            </button>
          </CSVLink>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {data.length > 0 ? (
          <CSVLink data={DownloadData} filename={"orderhistory.xls"}>
            <button className="btn btn-primary text-uppercase py-2 m-0">
              Download xls
            </button>
          </CSVLink>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {data.length > 0 ? (
          <button
            className="btn btn-primary text-uppercase py-2 m-0"
            onClick={exportPDF}
          >
            Download PDF
          </button>
        ) : (
          ""
        )}
      </span>
      <table className="table mb-0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Transaction Id</th>
            <th>Trade Type</th>
            <th>Contract</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Remaining</th>

            <th>Order Value</th>
            <th>Side</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {loader && <div>Loading...</div>}
          {!loader &&
            data &&
            data.length > 0 &&
            data.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{dateTimeFormat(item.createdAt, "YYYY-MM-DD HH:mm")}</td>
                  <td>
                    <div onClick={() => copyValue(item._id)}>
                      {item._id.split("", 10)}...
                    </div>
                  </td>
                  <td>{item.orderType == "limit" ? "Limit" : "Market"}</td>
                  <td>{item.pairName}</td>
                  <td>{item.price}</td>
                  <td>{item.quantity}</td>
                  <td>{item.quantity - item.filledQuantity}</td>
                  <td>{item.orderValue && item.orderValue.toFixed(4)}</td>
                  <td>{item.buyorsell}</td>
                  <td>
                    {(item && item.status.includes("open")) ||
                    item.status.includes("pending") ? (
                      <button
                        onClick={() =>
                          history.push(
                            "/spot/" +
                              item.firstCurrency +
                              "_" +
                              item.secondCurrency
                          )
                        }
                        type="button"
                        className="btn btn-primary text-uppercase py-1 px-2"
                      >
                        {capitalize(item.status)}
                      </button>
                    ) : (
                      <>
                        {item.status == "cancel"
                          ? "Cancelled"
                          : capitalize(item.status)}
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          {!loader && data && data.length <= 0 && <div>No Record</div>}
        </tbody>
      </table>
    </div>
  );
};

export default RecentTransaction;
