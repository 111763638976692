// import package
import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderPlace, getfees } from "../../actions/spotTradeAction";
import Checkbox from "rc-checkbox";
import validation from "./validation";
import { setTokenfee } from "../../actions/walletAction";
import isEmpty from "lib/isEmpty";
const initialFormValue = {
  tokenfee: false,
};

const MudraTokenFee = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fees, setfees] = useState(0);
  const [feesCurrecny, setfeesCurrecny] = useState(0);

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const tradePair = useSelector((state) => state.tradePair);
  const { tokenfee } = formValue;

  useEffect(() => {
    if (!isEmpty(tradePair)) {
      getownfess();
    }
  }, [tradePair]);

  const getownfess = async () => {
    const { result, status } = await getfees();
    if (result.data) {
      setfees(result.data.percentage);
      setfeesCurrecny(result.data.currencyId.currencySymbol);
    }
  };

  // function

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    setFormValue(formData);
    setValidateError(validation(formData));
    setTokenfee({ tokenfee: checked }, dispatch);
  };

  return (
    <>
      {fees && fees > 0 ? (
        <div className="form-group button_bott_trader">
          <div class="input-group">
            <div class="input-group-prepend ">
              <span class="btnType1 border_s">
                <Checkbox
                  name="tokenfee"
                  onChange={handleCheckBox}
                  checked={tokenfee}
                />
                &nbsp;{" "}
                <p className="m-0">
                  Use {feesCurrecny} for fee ( {fees} % Discount)
                </p>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MudraTokenFee;
