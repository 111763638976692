// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  let numberAndLettersRegex = /^[0-9a-zA-Z]+$/;
  if (isEmpty(value.upiId)) {
    errors.upiId = "UPI Id Required";
  } else if (!numberAndLettersRegex.test(value.upiId)) {
    errors.upiId = "Only Letter and Numbers Allowed";
  }

  // if(isEmpty(value.accountNo)){
  //     errors.accountNo = "REQUIRED"
  // }

  // if(isEmpty(value.holderName)){
  //     errors.holderName = "REQUIRED"
  // }

  // if(isEmpty(value.bankcode)){
  //     errors.bankcode = "REQUIRED"
  // }

  // if(isEmpty(value.country)){
  //     errors.country = "REQUIRED"
  // }

  // if(isEmpty(value.city)){
  //     errors.city = "REQUIRED"
  // }

  return errors;
};

export default validation;
