// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  let postalCodeRegex = /^[0-9]*$/;
  var letters = /^[A-Za-z]+$/;
  if (isEmpty(value.firstName)) {
    errors.firstName = "REQUIRED";
  } else if (!letters.test(value.firstName)) {
    errors.firstName = "Only Letters Allowed";
  }
  if (isEmpty(value.lastName)) {
    errors.lastName = "REQUIRED";
  } else if (!letters.test(value.lastName)) {
    errors.lastName = "Only Letters Allowed";
  }
  // if (isEmpty(value.blockNo)) {
  //     errors.blockNo = "REQUIRED"
  // }
  if (isEmpty(value.address)) {
    errors.address = "REQUIRED";
  }
  if (isEmpty(value.country)) {
    errors.country = "REQUIRED";
  }
  if (isEmpty(value.state)) {
    errors.state = "REQUIRED";
  } else if (!letters.test(value.state)) {
    errors.state = "Only Letters Allowed";
  }
  if (isEmpty(value.city)) {
    errors.city = "REQUIRED";
  } else if (!letters.test(value.city)) {
    errors.city = "Only Letters Allowed";
  }
  if (isEmpty(value.postalCode)) {
    errors.postalCode = "REQUIRED";
  } else if (!postalCodeRegex.test(value.postalCode)) {
    errors.postalCode = "Only Numbers Allowed";
  }

  return errors;
};

export default validation;
