import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getCMSPage } from '../actions/commonAction';

const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const TermsPage = (props) => {
    const { ...rest } = props;
    // state
   
    const [content, setContent] = useState('');

    // function
    const fetchCmsPage = async () => {
        try {
            const { status, loading, result } = await getCMSPage('risk');
            if (status == 'success') {
                setContent(result)
                document.title = result.title;
            }
        } catch (err) { }
    }

    useEffect(() => {
        fetchCmsPage()
    }, [])

    // redux
   const theme = useSelector(state => state.theme)

    return (
        <div className="page_wrap">
     <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />

            {/* <div className="dashboardContent userPages">
                <div className="container">
                <div class="col-lg-12 clas_heasdin aos-init aos-animate" data-aos="fade-up">
          <h1>Terms &amp; Conditions</h1>
        </div>
                    <div dangerouslySetInnerHTML={{ '__html': content }} />
                </div></div> */}
                <div className="container">
                <div className="dash_top_bar content_page padingtop_80 pdin_top_pos pb-5">
          <div className=" inner_pageheader">
          <div dangerouslySetInnerHTML={{ '__html': content&&content.content }} />      

                {/* <h2>Risk Disclaimer</h2>
                <p>Crypto currencies are subject to constant and frequent fluctuations, due to which there may be an increase or decrease in the their value you receive after conversion; or they may even become worthless due to high fluctuations in value. There is always an inherent risk of losses associated with buying, selling, or trading in crypto currency. It is also possible that the value of crypto currency changes after the transaction has been initiated or while the transaction is in the process.</p>
                <p>Under no circumstance shall JNC Exchange be liable for any such changes or market fluctuations whether before, during or after the transaction.</p>
                <p>By accepting the TERMS OF USE and PRIVACY POLICY of JNC Exchange, users agree that sale and/ or purchase transactions initiated by them (users) on the platform are final, irreversible, non-refundable and non-cancellable. Once a sale/ purchase and/ or any other fiat transaction are initiated by the user on the platform, the said transaction is absolute and unconditional. User cannot seek or claim or demand refund or reversal for neither any reason whatsoever nor can it is canceled, modified or reversed by JNC Exchange operators upon their (user’s) request.</p>
                <p>There may be additional risks that are not foreseen or identified in the terms of use. Users should carefully assess whether their financial situation and tolerance for risk is suitable for buying, selling, or trading crypto currencies.</p> */}
          </div>
        </div>
      </div>
            <Footer />
        </div>
    );
}

export default TermsPage