// import package
import React from "react";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import P2phistory from "./P2phistory";

const history = () => {
  return (
    <div className="dashboardContent userPages">
      <div className="container">
        <GridContainer>
          <GridItem xs={12} sm={12} md={7} lg={7}></GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={8} lg={8}></GridItem>
          <GridItem xs={12} sm={12} md={4} lg={4}></GridItem>
        </GridContainer>

        <P2phistory />
      </div>
    </div>
  );
};

export default history;
