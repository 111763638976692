
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem } from "@material-ui/core";

// import components
import HeaderLinks from "../components/Header/HeaderLinks.js";
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import Home from '../components/Home';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Contactus from "../components/Contactus/contactus"
import { useDispatch,useSelector } from 'react-redux';
const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const HomePage = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [anchorE2, setAnchorE2] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorE2(null);
  };
  const { ...rest } = props;
  const theme = useSelector(state => state.theme)
  return (
    <div className="page_wrap">
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
      }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />
        <Contactus/>
                    {/* <section className="bg_body">
                    <div className="container padig_top">
                <div className="">
                <h1 className="heading-title text-center mb-4">CONTACT US</h1>
                <GridContainer className="justify-content-center mt-5">
                <GridItem xs={12} sm={12} md={5} lg={10}>
                <div className="dashboard_box ">
                                <div className="contact_form ">
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={4}>
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input
                                                type="text"
                                                name="newEmail"
                                                className="form-control"
                                            />
                                        
                                        </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={4}>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                name="newEmail"
                                                className="form-control"
                                            />
                                        
                                        </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={4}>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="text"
                                                name="newEmail"
                                                className="form-control"
                                            />
                                        
                                        </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className="form-group">
                                            <label>Message</label>
                                            <textarea className="form-control"></textarea>
                                            
                                        
                                        </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className="form-group d-flex justify-content-center">
                                           <button className="btn btn-primary text-uppercase">Submit</button>
                                            
                                        
                                        </div>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                         </div> 
                </GridItem>
                </GridContainer>
                       
                </div>
            </div>
           
                    </section>
             */}


      


      

      

   
      <Footer />
    </div>
  );
}

export default HomePage;