// import package
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ProofForm from './ProofForm';

const IDProof = () => {
    const { t, i18n } = useTranslation();

    // redux-state
    const { idProof } = useSelector(state => state.userKyc);

    return (
        <>

            <div className="row align-items-center">
                <div className="col-lg-12">
                    <h3 className="dash_title mb-3">{t("IDENTITY_DOCUMENT")} <a href="javascript:void(0)" data-toggle="modal" data-target="#idProofNote"><small><i class="fas fa-info-circle"></i></small></a></h3>
                </div>
            </div>
            <div className="dashboard_box">
                <div className="kycIdentityDocument">
                    <GridContainer>
                        {
                            idProof && ['new', 'rejected'].includes(idProof.status) && <>
                                <p>{t("IDENTITY_DOCUMENT_TITLE1")}</p>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className="twoFAForm">
                                        <ProofForm />
                                    </div>
                                </GridItem>
                            </>
                        }

                        {
                            idProof && !['new', 'rejected','approved'].includes(idProof.status) && <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="verifiedDocument">
                                    <h3>{t("IDENTITY_DOCUMENT_VERIFIED")}</h3>
                                    <p>{t("IDENTITY_DOCUMENT_DESCRIPTION4")} <a href="/support-ticket">{t("SUPPORT_TEAM")}</a></p>
                                </div>
                            </GridItem>
                        }
                        {
                            idProof && ['approved'].includes(idProof.status) && <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="verifiedDocument">
                                    <h3>{t("IDENTITY_DOCUMENT_VERIFIED1")}</h3>
                                    <p>{t("IDENTITY_DOCUMENT_DESCRIPTION4")} <a href="/support-ticket">{t("SUPPORT_TEAM")}</a></p>
                                </div>
                            </GridItem>
                        }

                    </GridContainer>
                </div>
                <div class="modal fade" id="idProofNote" tabindex="-1" aria-labelledby="idProofNoteLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Note</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    <div className="settingsNote">
                    <ul>
                        <li> - {t("IDENTITY_DOCUMENT_DESCRIPTION1")}</li>
                        <li> - {t("IDENTITY_DOCUMENT_DESCRIPTION2")}</li>
                        <li> - {t("IDENTITY_DOCUMENT_DESCRIPTION3")}{/*  <a href="#">{t("HERE")}</a> */}</li>
                    </ul>
                </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>
                
            </div>
        </>
    )
}

export default IDProof;