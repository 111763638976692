// import package
import React, { useState, useEffect } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

// import component
import ReplyConversation from "./ReplyConversation";

import config from "../../config";

// import lib
import isEmpty from "../../lib/isEmpty";
import { capitalize } from "../../lib/stringCase";

import * as moment from "moment";

const ChatList = (props) => {
  // props
  const { ticketRecord, eventKey, sender } = props;

  // state
  const [ticketData, setTicketData] = useState({});

  // function
  const replyChatFun = (replyMsg) => {
    setTicketData({ ...ticketData, ...{ reply: replyMsg } });
  };

  const closeTicketFun = (status) => {
    setTicketData({ ...ticketData, ...{ status: status } });
  };

  useEffect(() => {
    if (ticketRecord) {
      setTicketData(ticketRecord);
    }
  }, []);

  return (
    <Card className=" support_new_desion">
      <Card.Header>
        <h5 className="mb-0">
          <Accordion.Toggle as={Button} variant="link" eventKey={`${eventKey}`}>
            <span className="stHeadText subjectWidth">
              <small>Subject</small>
              {ticketRecord.categoryName}
            </span>
            <span className="stHeadText ticketIdWidth">
              <small>Ticket ID</small>#{ticketRecord.tickerId}
            </span>
            <span className="stHeadText statusWidth">
              <small>Status</small>
              <span className="yellowText">
                {capitalize(ticketRecord.status)}
              </span>
            </span>
            <i className="fa fa-plus" aria-hidden="true"></i>
          </Accordion.Toggle>
        </h5>
      </Card.Header>
      <Accordion.Collapse eventKey={`${eventKey}`}>
        <Card.Body>
          <p className="metaChatDetails">
            Creates on:{" "}
            {moment(ticketRecord.createdAt).format("DD-MM-YYYY")}
          </p>
          <div className="row">
            <div className="col-md-12">
              <ul className="ticketComments">
                {!isEmpty(ticketData) &&
                  ticketData.reply &&
                  ticketData.reply.length > 0 &&
                  ticketData.reply.map((el, index) => {
                    if (el.senderId == sender._id) {
                      return (
                        <li>
                          <div className="ticketUserDetails">
                            <div className="userImg">
                              <img
                                src={require("../../assets/images/supportUserImg.jpg")}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <p>User</p>
                          </div>
                          <div className="ticketDetails">{el.message}</div>
                          {el.file && (
                            <div className="ticketDetails">
                              <a
                                target="_blank"
                                href={
                                  config.API_URL + "/images/support/" + el.file
                                }
                              >
                                View File
                              </a>
                            </div>
                          )}
                        </li>
                      );
                    } else {
                      return (
                        <li>
                          <div className="ticketUserDetails">
                            <div className="userImg">
                              <img
                                src={require("../../assets/images/supportAdminLogo.png")}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <p>Support Team</p>
                          </div>

                          <div className="ticketDetails">
                            <p className="metaChatDetails">
                            Support Team reply on:{" "}
                              
                              {moment(el.createdAt).format("DD-MM-YYYY")}
                              {/* 01-06-2020  15:35 */}
                            </p>
                            <p>{el.message}</p>
                          </div>
                        </li>
                      );
                    }
                  })}
              </ul>
              {ticketRecord.status == "open" && (
                <ReplyConversation
                  receiverId={ticketRecord.adminId}
                  ticketId={ticketRecord._id}
                  replyChatFun={replyChatFun}
                  closeTicketFun={closeTicketFun}
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ChatList;
