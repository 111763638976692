// import package
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { setUPIForm } from "../../actions/users";

const ListUPIDetail = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // redux-state
  const { result } = useSelector((state) => state.upiDetail);

  useEffect(() => {
    return () => {
      dispatch(
        setUPIForm({
          formDisable: true,
          formType: "add",
        })
      );
    };
  }, []);

  return (
    <>
      {result && result.length == 0 && (
        <>
          <div className="boxSpace">
            <p>{t("REGISTER_UPI_ACCOUNT")}</p>
            <div className="form-group">
              <button
                onClick={() => {
                  dispatch(
                    setUPIForm({
                      formDisable: false,
                      formType: "add",
                    })
                  );
                }}
                className="btn btn-primary text-uppercase py-2"
              >
                {t("ADD_UPI_ACCOUNT")}
              </button>
            </div>
          </div>
        </>
      )}

      {result && result.length > 0 && (
        <div className="profileDetailView">
          <GridContainer>
            {result.map((item, key) => {
              return (
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <h4>
                    {t("UPI_ACCOUNT")} {key + 1}
                  </h4>
                  <ul>
                    <li>
                      <label>{t("UPI _ID")}</label>
                      <span>{item.upiId}</span>
                    </li>
                    {/* <li>
                                                <label>{t("ACCOUNT_NUMBER")}</label>
                                                <span>{item.accountNo}</span>
                                            </li>
                                            <li>
                                                <label>{t("ACCOUNT_HOLDER_NAME")}</label>
                                                <span>{item.holderName}</span>
                                            </li>
                                            <li>
                                                <label>{t("IBAN_CODE")}</label>
                                                <span>{item.bankcode}</span>
                                            </li>
                                            <li>
                                                <label>{t("BANK")}{" "}{t("CITY")}</label>
                                                <span>{item.city}</span>
                                            </li>
                                            <li>
                                                <label>{t("BANK")}{" "}{t("COUNTRY")}</label>
                                                <span>{item.country}</span>
                                            </li> */}
                  </ul>
                </GridItem>
              );
            })}
          </GridContainer>
          <div className="form-group mb-0">
            <button
              className="btn btn-outline py-2 text-uppercase"
              onClick={() => {
                dispatch(
                  setUPIForm({
                    formDisable: false,
                    formType: "add",
                  })
                );
              }}
            >
              Add Another Account
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ListUPIDetail;
