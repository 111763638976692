import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import Slider from "@material-ui/core/Slider";
import { Scrollbars } from "react-custom-scrollbars-2";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import Checkbox from "@material-ui/core/Checkbox";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
// import GridLayout from "react-grid-layout";

// import components
// import OrderPlace from "./OrderPlace";
// import MarketPrice from "./MarketPrice";
// import OrderBook from "./OrderBook";
// import UserOrderList from "./UserOrderList";
// import PairList from "./PairList";
// import Chart from "./Chart";

import "./styles.css";
import "./example-styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ShowcaseDerivativeLayout = (props) => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({
    lg: props.initialLayout,
  });

  // function

  const generateDOM = () => {
    return _.map(layouts.lg, function (l, i) {
      return (
        <div key={i} className={l.static ? "static" : ""}>
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            <span className="text">{i}</span>
          )}
        </div>
      );
    });
  };

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };
  const onLayoutChange = (layout, layouts) => {
    props.onLayoutChange(layout, layouts);
  };
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="derivativeTrade">
      <ResponsiveReactGridLayout
        {...props}
        layouts={layouts}
        onBreakpointChange={onBreakpointChange}
        measureBeforeMount={false}
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
      >
        <div key={0} className="static">
          <div className="header-overview">
            {/* <PairList /> */}
            {/* <MarketPrice /> */}
          </div>
        </div>
        <div key={1}>{/* <OrderPlace /> */}</div>
        <div key={2}>
          <div className="tradeChart">{/* <Chart /> */}</div>
        </div>

        <div key={3}>{/* <OrderBook /> */}</div>
        <div key={6}>
          <div className="tradeTableLeftSide darkBox recentTrades">
            <div className="tableHead">
              <h4>Recent Trades</h4>
            </div>
            <div className="tradeTableTitle row mx-auto">
              <span className="col-4">Price(USDT)</span>
              <span className="col-4">Amount</span>
              <span className="col-4">Time</span>
            </div>
            <div className="tradeTableBody customScroll">
              <Scrollbars style={{ width: "100%", height: 130 }}>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 pinkText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 pinkText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">1.235698</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">1.235698</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 pinkText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">1.235698</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">1.235698</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">1.235698</span>
                  <span className="col-4">15:05:05</span>
                </div>
                <div className="tradeTableBodyRow odd row mx-auto">
                  <span className="col-4 greenText">7455.50</span>
                  <span className="col-4">0.326598</span>
                  <span className="col-4">15:05:05</span>
                </div>
              </Scrollbars>
            </div>
          </div>
        </div>
        <div key={4}>{/* <UserOrderList /> */}</div>
      </ResponsiveReactGridLayout>
    </div>
  );
};

ShowcaseDerivativeLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired,
};

ShowcaseDerivativeLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  initialLayout: [
    {
      x: 0,
      y: 0,
      w: 9,
      h: 2,
      i: "0",
      name: "marketPrice",
      static: true,
    },
    {
      x: 9,
      y: 0,
      w: 3,
      h: 21,
      i: "1",
      name: "placeOrder",
      static: false,
    },
    {
      x: 0,
      y: 2,
      w: 6,
      h: 13,
      i: "2",
      name: "chart",
      static: false,
    },
    {
      x: 6,
      y: 2,
      w: 3,
      h: 7,
      i: "3",
      name: "orderBook",
      static: false,
    },
    {
      x: 6,
      y: 2,
      w: 3,
      h: 6,
      i: "6",
      name: "Recent Trade",
      static: false,
    },
    {
      x: 0,
      y: 10,
      w: 9,
      h: 6,
      i: "4",
      name: "tradeHistory",
      static: false,
    },
  ],
};

export default ShowcaseDerivativeLayout;
