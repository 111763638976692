// import package
import React, { useCallback, createRef, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneInput from "react-phone-input-2";
// import config
import config from "../../config";
import clsx from "classnames";
// import action
import { forgotPassword, sentOTP } from "../../actions/users";

// import lib
// import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  email: "",
  phoneCode: "+91",
  phoneNo: "",
  otp: "",
  recaptcha: "",

  roleType: 1,
};

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const recaptchaRef = createRef();
  const grecaptchaObject = window.grecaptcha;
  const history = useHistory();
  const [otpBtn, setOtpBtn] = useState(false);

  // states
  const [formValue, setFormValue] = useState(initialFormValue);
  // const [reCaptcha, setReCaptcha] = useState('');
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();

  const { email, reCaptcha, otp, phoneNo, phoneCode, roleType } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    // setValidateError(validation(formData));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } });
  };

  const handleRecaptcha = (value) => {
    let formData = { ...formValue, ...{ reCaptcha: value } };
    setFormValue(formData);
    // setValidateError(validation(formData));
  };

  // emill forgot
  // const handleFormSubmit = async (e) => {
  //     grecaptchaObject.reset();
  //     e.preventDefault();

  //     if (isEmpty(reCaptcha)) {
  //         toastAlert('error', 'Invalid ReCaptcha', 'forgotPassword');
  //         return
  //     }

  //     setLoader(true)
  //     let reqData = {
  //         email,
  //         reCaptcha
  //     }
  //     let { status, loading, error, message } = await forgotPassword(reqData);
  //     setLoader(loading);
  //     // setReCaptcha('')
  //     if (status == "success") {
  //         setFormValue(initialFormValue)
  //         setToched({})
  //         setValidateError(validation(initialFormValue))
  //         toastAlert('success', message, 'forgotPassword');
  //     } else {
  //         if (error) {
  //             // grecaptchaObject.reset();

  //             setValidateError(error);
  //         }
  //         toastAlert('error', message, 'forgotPassword');
  //         // grecaptchaObject.reset();

  //     }
  // }

  // const handleReCaptcha = useCallback((token) => {
  //     if (isEmpty(reCaptcha)) {
  //         setReCaptcha(token)
  //     }
  // }, [reCaptcha])

  const roleTypeChange = (type) => {
    const formData = { ...formValue, ...{ ["roleType"]: type } };
    setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (isEmpty(reCaptcha)) {
      toastAlert("error", "Invalid ReCaptcha", "forgotPassword");
      return;
    }

    setLoader(true);
    let reqData = {
      email,
      phoneCode,
      phoneNo: "+" + phoneCode + phoneNo,
      reCaptcha,
      otp,
      roleType,
    };
    let { status, loading, userToken, error, message } = await forgotPassword(
      reqData
    );
    setLoader(loading);
    console.log(
      "aaaaaaaaaaaaaaaaaaaaaaaa",
      status,
      loading,
      userToken,
      error,
      message
    );
    if (status == "success") {
      setFormValue(initialFormValue);
      // setToched({})
      setValidateError(initialFormValue);
      toastAlert("success", message, "forgotPassword");
    } else if (status == "MOBILE_USER") {
      toastAlert("success", message, "forgotPassword");

      history.push(`/reset-password/${userToken}`);
      setOtpBtn(false);
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "forgotPassword");
    }
  };

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    let phoneNo = value;
    let formData = formValue;
    if (dialCode) {
      formData = {
        ...formData,
        ...{
          phoneCode: dialCode,
          phoneNo: phoneNo.slice(dialCode.length),
        },
      };
    } else if (value) {
      formData = { ...formData, ...{ phoneNo } };
    }
    setFormValue(formData);
  };
  const otpSubmit = async () => {
    if (isEmpty(reCaptcha)) {
      toastAlert("error", "Invalid ReCaptcha", "forgotPassword");
      return;
    }
    let respData = {
      phoneNo: "+" + phoneCode + phoneNo,
      phoneCode: phoneCode,
    };

    const { status, messages, error } = await sentOTP(respData);
    console.log("aaaaaaaaaaaaaaaaaaaaaaaa", status, messages, error);
    if (status == "success") {
      toastAlert("success", messages, "sentOTP");
      setOtpBtn(true);
      setValidateError({});
    } else {
      setValidateError(error);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="login_container">
      <h2 className="text-center mb-md-4 pb-3" data-aos="fade-up">
        Forgot Password
      </h2>
      <div className="row w-100">
        <div className="col-lg-4 col-md-6 m-auto">
          <form className="login_form p-4 mb-4" data-aos="fade-up">
            <p className="paraLabel text-center mb-3">
              {t("RECOVERY_PASSWORD_TITLE")}
            </p>
            <div className="form_fonr_ad">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a
                    className="active"
                    data-toggle="tab"
                    href="#home"
                    onClick={() => roleTypeChange(1)}
                  >
                    Email
                  </a>
                </li>
                <li>
                  <a
                    data-toggle="tab"
                    href="#menu1"
                    onClick={() => roleTypeChange(2)}
                  >
                    Mobile
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div id="home" className="tab-pane fade in active show">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Email address"
                      name="email"
                      value={email}
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {validateError.email && (
                      <p className="error-message">{t(validateError.email)}</p>
                    )}
                  </div>
                </div>

                <div id="menu1" className="tab-pane fade">
                  <div className="form-group ">
                    <PhoneInput
                      country={"us"}
                      placeholder="Enter phone number"
                      value={phoneCode + phoneNo}
                      onChange={handlePhoneNumber}
                      placeholder={"Eg +911234567890"}
                    />
                    {validateError.phoneNo && (
                      <p className="error-message">{validateError.phoneNo}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form-group text-center">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                grecaptcha={grecaptchaObject}
                                sitekey={config.RECAPTCHA_SITE_KEY}
                                onChange={handleRecaptcha}
                            />
                            {validateError.reCaptcha != "" && <p className="error-message">{validateError.reCaptcha}</p>}
                        </div> */}
            {otpBtn && (
              <div className="form-row">
                <div id="home" className="tab-pane fade in active show">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Mobile OTP"
                      name="otp"
                      value={otp}
                      type="email"
                      onChange={handleChange}
                    />
                    {validateError.otp && (
                      <p className="error-message">{t(validateError.otp)}</p>
                    )}
                  </div>
                </div>
                <Button
                  type="button"
                  onClick={handleFormSubmit}
                  className="banner_btn"
                >
                  Submit
                </Button>
              </div>
            )}
            {otpBtn == false && (
              <div className="form-group text-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  grecaptcha={grecaptchaObject}
                  sitekey={config.RECAPTCHA_SITE_KEY}
                  onChange={handleRecaptcha}
                />
                {/* {validateError.reCaptcha != "" && <p className="error-message">{validateError.reCaptcha}</p>} */}
              </div>
            )}
            <div className="form-group">
              {roleType == 1 && otpBtn == false && (
                <Button
                  onClick={handleFormSubmit}
                  disabled={!isEmpty(validateError) || loader}
                >
                  {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
                </Button>
              )}
              {roleType == 2 && otpBtn == false && (
                <Button type="button" onClick={otpSubmit}>
                  Send Otp
                </Button>
              )}
            </div>
            <div className="d-flex">
              <Link to="/home" className="mr-auto">
                Home
              </Link>
              <Link to="/login" className="ml-auto">
                Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
