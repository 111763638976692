// import package
import React, { useState, useEffect } from "react";
import clsx from "classnames";

// import action
import { gettradeHistory } from "../../actions/dashboardAction";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import { transactionStatus } from "../../lib/displayStatus";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
const RecentTransaction = () => {
  // state
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [DownloadData, setDownloadData] = useState([]);

  // funtion
  const fetchTransaction = async () => {
    try {
      const { status, loading, result } = await gettradeHistory();
      setLoader(loading);
      if (status == "success") {
        setData(result);

        const headers = [
          [
            "Date",
            "Transaction Id",
            "Trade Type",
            "Contract",
            "Price",
            "Quantity",
            "Order Value",
            "Fee",
            "Side",
          ],
        ];

        const download_data =
          result &&
          result.length > 0 &&
          result.map((elt) => [
            dateTimeFormat(elt.createdAt, "YYYY-MM-DD HH:mm"),
            elt._id,
            elt.orderType == "limit" ? "Limit" : "Market",
            elt.firstCurrency + elt.secondCurrency,
            elt.price,
            elt.filledQuantity,
            elt.orderValue && elt.orderValue.toFixed(4),
            `${elt.Fees.toFixed(6)} ${
              elt.buyorsell == "buy" ? elt.firstCurrency : elt.secondCurrency
            }`,
            elt.buyorsell,
          ]);
        let download_csv = headers.concat(download_data);

        setDownloadData(download_csv);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchTransaction();
  }, []);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "Trade History";
    const headers = [
      [
        "Date",
        "Transaction Id",
        "Trade Type",
        "Contract",
        "Price",
        "Quantity",
        "Order Value",
        "Fee",
        "Side",
      ],
    ];

    const download_data =
      data &&
      data.length > 0 &&
      data.map((elt) => [
        dateTimeFormat(elt.createdAt, "YYYY-MM-DD HH:mm"),
        elt._id,
        elt.orderType == "limit" ? "Limit" : "Market",
        elt.firstCurrency + elt.secondCurrency,
        elt.price,
        elt.filledQuantity,
        elt.orderValue && elt.orderValue.toFixed(4),
        `${elt.Fees.toFixed(6)} ${
          elt.buyorsell == "buy" ? elt.firstCurrency : elt.secondCurrency
        }`,
        elt.buyorsell,
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: download_data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("tradehistory.pdf");
  };

  return (
    <div className="table-responsive">
      <br />
      <span>
        {data.length > 0 ? (
          <CSVLink data={DownloadData} filename={"tradehistory.csv"}>
            <button className="btn btn-primary text-uppercase py-2 m-0">
              Download csv
            </button>
          </CSVLink>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {data.length > 0 ? (
          <CSVLink data={DownloadData} filename={"tradehistory.xls"}>
            <button className="btn btn-primary text-uppercase py-2 m-0">
              Download xls
            </button>
          </CSVLink>
        ) : (
          ""
        )}
        &nbsp;&nbsp;
        {data.length > 0 ? (
          <button
            className="btn btn-primary text-uppercase py-2 m-0"
            onClick={exportPDF}
          >
            Download PDF
          </button>
        ) : (
          ""
        )}
      </span>

      <table className="table mb-0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Transaction Id</th>
            <th>Trade Type</th>
            <th>Contract</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Order Value</th>
            <th>Fee</th>
            <th>Side</th>
          </tr>
        </thead>
        <tbody>
          {loader && <div>Loading...</div>}
          {!loader &&
            data &&
            data.length > 0 &&
            data.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{dateTimeFormat(item.createdAt, "YYYY-MM-DD HH:mm")}</td>
                  <td>{item._id}</td>
                  <td>{item.orderType == "limit" ? "Limit" : "Market"}</td>
                  <td>{item.firstCurrency + item.secondCurrency}</td>
                  <td>{item.price}</td>
                  <td>{item.filledQuantity}</td>

                  <td>{item.orderValue.toFixed(4)}</td>
                  <td>
                    {item.Fees.toFixed(4)}{" "}
                    {item.buyorsell == "buy"
                      ? item.firstCurrency
                      : item.secondCurrency}
                  </td>
                  <td>{item.buyorsell}</td>
                </tr>
              );
            })}
          {!loader && data && data.length <= 0 && <div>No Record</div>}
        </tbody>
      </table>
    </div>
  );
};

export default RecentTransaction;
