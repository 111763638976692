import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// import action
import { getCMSPage } from '../actions/commonAction';

const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const TermsPage = (props) => {
    const { ...rest } = props;
    // state
   
    const [content, setContent] = useState('');

    // function
    const fetchCmsPage = async () => {
        try {
            const { status, loading, result } = await getCMSPage('terms');
            if (status == 'success') {
                setContent(result.content)
                document.title = result.title;
            }
        } catch (err) { }
    }

    useEffect(() => {
        fetchCmsPage()
    }, [])
// redux
const theme = useSelector(state => state.theme)
    return (
        <div className="page_wrap">
     <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />

            {/* <div className="dashboardContent userPages">
                <div className="container">
                <div class="col-lg-12 clas_heasdin aos-init aos-animate" data-aos="fade-up">
          <h1>Terms &amp; Conditions</h1>
        </div>
                    <div dangerouslySetInnerHTML={{ '__html': content }} />
                </div></div> */}
                <div className="login_container">
                <div className="container">
                <div className="dash_top_bar content_page padingtop_80 pdin_top_pos pb-5">
          <div className=" inner_pageheader height_121">
          <div className="logo_bg_shadow" data-aos="fade-up">
                <img src={require("../assets/images/logo_bg.svg")} alt="logo" className="img-fluid img_with" />
              </div>
                <h1>Coming Soon</h1>
                <a href="/home" className="coming_btn">Home</a>
          </div>
        </div>
      </div>
                </div>
            
        </div>
    );
}

export default TermsPage