// import package
import React, { useEffect, useState } from "react";

// import component
import CanvasChart from "../CanvasChart";

// import action
import { getDashBal } from "../../actions/dashboardAction";

const initialFormValue = [
  {
    label: "currency",
    y: 100,
    color: "grey",
    toolTipContent: "Asset is zero balance",
  },
];

const BalanceChart = () => {
  // state
  const [assetList, setAssetList] = useState([]);
  const [dataPts, setDataPts] = useState(initialFormValue);
  const [defaultChart, setDefaultChart] = useState(true);

  // function
  const fetchStakAmt = async () => {
    try {
      const { status, loading, result } = await getDashBal();
      if (status == "success") {
        let arr = [],
          isDefault = false;
        if (result && result.length > 0) {
          result.map((item) => {
            if (item.derivativeWallet + item.spotwallet > 0) {
              arr.push({
                label: item.currencySymbol,
                y: item.derivativeWallet + item.spotwallet,
                color: item.colorCode,
              });
               isDefault = false
            } else if(!isDefault){
              isDefault = true;
            }
          });
          if (arr.length > 0) {
            setDataPts(arr);
          }
          setAssetList(result);
          setDefaultChart(isDefault);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchStakAmt();
  }, []);

  return (
    <div className="balance_details_right">
      <div className="chartDash">
        <CanvasChart
          chartType={"doughnut"}
          dataPts={dataPts}
          defaultChart={defaultChart}
        />
      </div>
      <div className="chartLabel">
        <ul>
          {assetList &&
            assetList.length > 0 &&
            assetList.map((item, key) => {
              // console.log("assetListassetListassetListassetList",item)
              return (
                <li>
                  <label>
                    <i
                      className="fas fa-square-full"
                      style={{ color: item.colorCode }}
                    ></i>{" "}
                    {item.currencySymbol}
                  </label>
                  <span>{ item.spotwallet}</span>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default BalanceChart;
