// import package
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "classnames";
import { useSelector } from "react-redux";

// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import lib
import {
  documentStatus,
  documentType,
  userStatus,
} from "../../lib/displayStatus";
import isEmpty from "../../lib/isEmpty";

const UserKycDetail = () => {
  const { t, i18n } = useTranslation();

  // redux-state
  const { idProof, addressProof } = useSelector((state) => state.userKyc);
  const accountData = useSelector((state) => state.account);
  const {
    userId,
    uniqueId,
    firstName,
    lastName,
    email,
    emailStatus,
    phoneStatus,
    phonenumber,
    type,
    createAt,
    bankDetail,
  } = accountData;

  return (
    <div className="dashboard_box">
      <div className="kycTopCard">
        <h3>
          {firstName} {lastName}
        </h3>
        <p>
          <label>{t("USER_ID")}:</label> {uniqueId}{" "}
          <label>{t("MEMBER_SINCE")}:</label> {createAt}{" "}
        </p>
        
      </div>
      <div className="kycBottomCard">
        <GridContainer>
          <GridItem xs={12} sm={12} md={4} lg={4}>
            <div className="kycCardStatus">
              <div className="profileDetailView">
                <ul>
                  <li>
                    <label>Email</label>{" "}
                    <span>
                      {email}{" "}
                      <i
                        class={clsx(
                          {
                            "fas fa-check-circle enableGreen":
                              emailStatus == "verified",
                          },
                          {
                            "fas fa-times-circle disabledRed":
                              emailStatus == "unverified",
                          }
                        )}
                      ></i>
                    </span>
                  </li>
                  {/* <li><label>Phone</label>
                                        <span>
                                            {
                                                phoneStatus && phoneStatus == 'unverified' && <span>+91xxxxxxxx</span>
                                            }

                                            {
                                                phoneStatus && phoneStatus == 'verified' && <span>+{phonenumber}</span>
                                            } <i class={clsx({ "fas fa-check-circle enableGreen": phoneStatus == 'verified' }, { "fas fa-times-circle disabledRed": phoneStatus == 'unverified' })}></i>
                                        </span>
                                    </li> */}
                </ul>
              </div>
              {/* <h6>{t("EMAIL_PHONE")}</h6>
                            <ul>
                                <li>
                                    <label>{email}</label>
                                    <span>
                                        <i class={clsx({ "fas fa-check-circle enableGreen": emailStatus == 'verified' }, { "fas fa-times-circle disabledRed": emailStatus == 'unverified' })}></i>{' '}
                                        {emailStatus && t(userStatus(emailStatus))}
                                    </span>
                                </li>
                                <li>
                                    {
                                        phoneStatus && phoneStatus == 'unverified' && <label>+91xxxxxxxx</label>
                                    }

                                    {
                                        phoneStatus && phoneStatus == 'verified' && <label>+{phonenumber}</label>
                                    }


                                    <span>
                                        <i class={clsx({ "fas fa-check-circle enableGreen": phoneStatus == 'verified' }, { "fas fa-times-circle disabledRed": phoneStatus == 'unverified' })}></i>{' '}
                                        {phoneStatus && t(userStatus(phoneStatus))}
                                    </span>
                                </li>
                            </ul> */}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <div className="kycCardStatus labelWidth">
              <div className="profileDetailView">
                <ul>
                  <li>
                    <label>ID Document:</label>
                    {idProof && idProof.status == "new" && (
                      <span>Not Submitted</span>
                    )}
                    <span>
                      {" "}
                      <i
                        className={clsx(
                          {
                            "fas fa-check-circle enableGreen":
                              idProof.status == "approved",
                          },
                          {
                            "fas fa-times-circle disabledRed": [
                              "pending",
                              "rejected",
                            ].includes(idProof.status),
                          }
                        )}
                      ></i>{" "}
                      {t(documentStatus(idProof.status))}
                    </span>
                  </li>
                  {/* <li>
                                        <label>Address Proof:</label>
                                        <span>Pending</span>
                                    </li> */}

                  <li>
                    <label>Address Proof:</label>
                    {addressProof && addressProof.status == "new" && (
                      <span>Not Submitted</span>
                    )}
                    {addressProof && addressProof.status != "new" && (
                      <li>
                        
                        <span>
                          <i
                            className={clsx(
                              {
                                "fas fa-check-circle enableGreen":
                                  addressProof.status == "approved",
                              },
                              {
                                "fas fa-times-circle disabledRed": [
                                  "pending",
                                  "rejected",
                                ].includes(addressProof.status),
                              }
                            )}
                          ></i>{" "}
                          {t(documentStatus(addressProof.status))}
                        </span>
                      </li>
                    )}
                  </li>
                  {/* <li>
                                        <label>Pro User Verification:</label>
                                        <span>Pending</span>
                                    </li> */}
                </ul>
                {/* <ul>
                                    <li>
                                        <label>ID Document:</label>
                                        <span>Pending</span>
                                    </li>
                                    <li>
                                        <label>Address Proof:</label>
                                        <span>Pending</span>
                                    </li>
                                    <li>
                                        <label>Advanced User Verification:</label>
                                        <span>Pending</span>
                                    </li>
                                    <li>
                                        <label>Pro User Verification:</label>
                                        <span>Pending</span>
                                    </li>
                                </ul> */}
              </div>
              {/* <h6>{t("ID_ADDRESS_PROOF")}f</h6> */}

              {/* Address Proof Pending Verified */}
              {/* Advanced user verification: Pending/Verified */}
              {/* Pro user verification: Pending/Verified */}
              {/* <ul>
                                <li>
                                    <label>ID document:</label><span>Not verified</span>

                                </li>
                                {
                                    idProof && idProof.status == 'new' && <li>
                                        <label>{t("NOT_APPLY")}</label>
                                    </li>
                                }

x

                                {
                                    idProof && idProof.status != 'new' && <li>
                                        <label>{t(documentType(idProof.type))}</label>
                                        <span>
                                            <i className={clsx({ "fas fa-check-circle enableGreen": idProof.status == 'approved' }, { "fas fa-times-circle disabledRed": ['pending', 'rejected'].includes(idProof.status) })}></i>{' '}
                                            {t(documentStatus(idProof.status))}
                                        </span>
                                    </li>
                                }


                                {
                                    addressProof && addressProof.status == 'new' && <li>
                                        <label>{t("NOT_APPLY")}</label>
                                    </li>
                                }

                                {
                                    addressProof && addressProof.status != 'new' && <li>
                                        <label>{t(documentType(addressProof.type))}</label>
                                        <span>
                                            <i className={clsx({ "fas fa-check-circle enableGreen": addressProof.status == 'approved' }, { "fas fa-times-circle disabledRed": ['pending', 'approved', 'rejected'].includes(addressProof.status) })}></i>{' '}
                                            {t(documentStatus(addressProof.status))}
                                        </span>
                                    </li>
                                }

                            </ul> */}
            </div>
          </GridItem>
          {/*<GridItem xs={12} sm={12} md={4} lg={4}>
                        {
                            !isEmpty(bankDetail) && <div className="kycCardStatus">
                                <h6>{t("DEFAULT_BANK_ACCOUNT_DETAIL")}</h6>
                                <ul>
                                    <li><label>{bankDetail.bankName}</label>
                                        <span><i class="fas fa-check-circle enableGreen"></i> {t('VERIFIED')}</span>
                                    </li>
                                    <li><label>{bankDetail.accountNo},</label></li>
                                    <li><label>{bankDetail.city}, {bankDetail.country}</label></li>
                                </ul>
                            </div>
                        }

                    </GridItem>*/}
        </GridContainer>
      </div>
    </div>
  );
};

export default UserKycDetail;
