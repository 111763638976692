// import package
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

// import component
import FiatWithdraw from "./FiatWithdraw";
import FiatDeposit from "./FiatDeposit";
import CoinDeposit from "./CoinDeposit";
import CoinWithdraw from "./CoinWithdraw";
import INRDeposit from "./inrdeposit";

// import lib
import { currencySymbol } from "../../lib/pairHelper";
import { toFixed } from "../../lib/roundOf";

const UserWalletList = () => {
  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceConversion);
  const userSetting = useSelector((state) => state.userSetting);

  // state
  const [depositKey, setDepositKey] = useState(-1);
  const [withdrawKey, setWithdrawKey] = useState(-1);
  const [assetData, setAsstData] = useState({});
  const [currencyList, setCurrecnyList] = useState([]);

  useEffect(() => {
    if (walletData) setCurrecnyList(walletData);
  }, [walletData]);

  const search = async (e) => {
    e.preventDefault();

    try {
      const { name, value } = e.target;
      if (value) {
        let result = await walletData.filter(
          (el) =>
            el.currency.currencySymbol.includes(value.toUpperCase()) ||
            el.currency.currencyName.includes(value)
        );
        if (result) setCurrecnyList(result);
      } else {
        setCurrecnyList(walletData);
      }
    } catch (err) {}
  };
  return (
    <>
      <div className="dashboard_box mb-2">
        <div className="walletCard">
          <div className="walletCardLeft">
            <label className="currencyName">Search</label>
            <div class="input-group">
              <input
                type="text"
                placeholder="Search Currency"
                className="form-control"
                onChange={search}
              />
              <div class="input-group-append">
                <span class="btnType1">
                  <i class="fas fa-search"></i>
                </span>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>

      {currencyList &&
        currencyList.length > 0 &&
        currencyList.map((item, key) => {
          let priceCNV;

          if (userSetting && priceConversion && priceConversion.length > 0) {
            priceCNV = priceConversion.find(
              (el) =>
                el.baseSymbol == item.currency.currencySymbol &&
                el.convertSymbol == userSetting.currencySymbol
            );
          }

          // console.log("ITESSDSADSDSD",item)
          return (
            <>
              <div className="dashboard_box mb-2">
                <div className="walletCard">
                  <div className="walletCardLeft">
                    <div className="currencyName">
                      <img
                        src={item.currency.currencyImage}
                        alt=""
                        className="img-fluid"
                      />
                      {item.currency.currencyName}
                    </div>
                    {item.currency.type != "fiat" ? (
                      <div className="currencyPrice">
                        {toFixed(item.spotwallet, 8)}{" "}
                        <small>
                          {item.currency.type == "crypto"
                            ? item.currency.CoinpaymentCode
                            : `${
                                item.currency.currencySymbol
                              }.${item.currency.tokenType.toUpperCase()}`}
                        </small>
                      </div>
                    ) : (
                      <div className="currencyPrice">
                        {toFixed(item.spotwallet, 8)}{" "}
                        <small>{item.currency.currencySymbol}</small>
                      </div>
                    )}
                    <div className="currencyPrice">
                      {priceCNV && (
                        <>
                          <small>
                            {currencySymbol(userSetting.currencySymbol)}
                          </small>{" "}
                          {toFixed(item.spotwallet * priceCNV.convertPrice, 2)}
                        </>
                      )}
                    </div>
                  </div>
                  {item.currency.status == "active" && (
                    <div className="walletCardRight">
                      <div className="textLinkGroup">
                        [
                        <Link
                          href="#"
                          onClick={() => {
                            setDepositKey(key == depositKey ? -1 : key);
                            setAsstData(item);
                          }}
                        >
                          Deposit
                        </Link>
                        ] [
                        <Link
                          href="#"
                          onClick={() => {
                            setWithdrawKey(key == withdrawKey ? -1 : key);
                            setAsstData(item);
                          }}
                        >
                          Withdraw
                        </Link>
                        ]
                        {
                          // pairList && pairList.filter((details) =>{
                          //     if((details.firstCurrencySymbol == item.currency.currencySymbol) || (details.secondCurrencySymbol == item.currency.currencySymbol)){
                          //         console.log(details,'dtaillll')
                          //     }
                          // })
                          ["crypto", "token"].includes(item.currency.type) && (
                            <>
                              {" "}
                              [ <Link to={"/spot"}>Trade</Link> ]
                            </>
                          )
                        }
                      </div>
                    </div>
                  )}
                  {item.currency.status == "deactive" && (
                    <div className="walletCardRight">
                      <div className="textLinkGroup">
                        {["crypto", "token"].includes(item.currency.type) && (
                          <>
                            {" "}
                            [ <Link to={"/spot"}>Trade</Link> ]
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <Collapse in={key == depositKey}>
                  <div className="collapseWallet">
                    <div className="contact_form settingsSelect mb-0">
                      {assetData &&
                        assetData.currency &&
                        ["crypto", "token"].includes(
                          assetData.currency.type
                        ) && <CoinDeposit assetData={item} />}

                      {assetData &&
                        assetData.currency &&
                        ["fiat"].includes(assetData.currency.type) && (
                          //  assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) &&

                          <FiatDeposit assetData={item} />
                        )}
                      {/* {
                                             
                                             assetData && assetData.currency && ['INR'].includes(assetData.currencySymbol) && 
                                            //  assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && 
                                             
                                             <INRDeposit
                                                    assetData={item}
                                                />
                                            } */}
                    </div>
                  </div>
                </Collapse>

                <Collapse in={key == withdrawKey}>
                  <div className="collapseWallet">
                    <div className="contact_form settingsSelect mb-0">
                      {assetData &&
                        assetData.currency &&
                        ["crypto", "token"].includes(
                          assetData.currency.type
                        ) && <CoinWithdraw assetData={item} />}

                      {assetData &&
                        assetData.currency &&
                        ["fiat"].includes(assetData.currency.type) && (
                          <FiatWithdraw assetData={item} />
                        )}
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
          );
        })}
    </>
  );
};

export default UserWalletList;
