// import constant
import { SET_TOKEN_FEE } from "../constant";

const initialValue = {
  tokenfee: false,
};
const tokenfee = (state = initialValue, action) => {
  switch (action.type) {
    case SET_TOKEN_FEE:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default tokenfee;
