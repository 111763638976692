// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  let emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  if (value.phoneNo == "") {
    if (isEmpty(value.email)) {
      errors.email = "REQUIRED";
    } else if (!emailRegex.test(value.email)) {
      errors.email = "EMAIL_INVALID";
    }
  } else {
    if (value.phoneNo.length < 10) {
      errors.phoneNo = "Invalid Phonenumber";
    }
  }

  // if (isEmpty(value.email)) {
  //     errors.email = "REQUIRED"
  // } else if (!(emailRegex.test(value.email))) {
  //     errors.email = "EMAIL_INVALID"
  // }

  if (isEmpty(value.password)) {
    errors.password = "REQUIRED";
  }

  // if (!(value.isTerms == true)) {
  //     errors.isTerms = "REQUIRED"
  // }

  return errors;
};

export default validation;
