import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import DocumentMeta from 'react-document-meta';

// import action
import { getCMSPage } from "../actions/commonAction";

const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const PrivacyPolicyPage = (props) => {
  // const meta = {
  //   title: 'Some Meta Title',
  //   description: 'I am a description, and I can create multiple tags',
  //   canonical: 'http://example.com/path/to/page',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: 'react,meta,document,html,tags'
  //     }
  //   }
  // };
  const { ...rest } = props;
  // state
  const [content, setContent] = useState("");

  // function
  const fetchCmsPage = async () => {
    try {
      const { status, loading, result } = await getCMSPage("privacy_policy");
      console.log("result", result);
      if (status == "success") {
        setContent(result);
        console.log("content", content);
        document.title = result.title;
        const el = document.querySelector("meta[name='description']");
        el.setAttribute("content", result.metadescription);
        document.keywords = el;
        console.log("el", el);
        const els = document.querySelector("meta[name='keywords']");
        els.setAttribute("content", result.metakeywords);
        document.description = els;
        console.log("els", els);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchCmsPage();
  }, []);

    // redux
    const theme = useSelector(state => state.theme)
    
  return (
    // <DocumentMeta {...meta}>

    <div className="page_wrap">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Helmet application" />
      </Helmet>
      <ScrollToTopOnMount />
      <Header
        className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest}
      />

      
<div className="dashboardContent userPages">
                <div className="container">
                <div class="col-lg-12 clas_heasdin aos-init aos-animate" data-aos="fade-up">
          <h1>Privacy Policy</h1>
        </div>
                    <div dangerouslySetInnerHTML={{ '__html': content&&content.content }} />
                </div></div> 
      <Footer />
    </div>
    // </DocumentMeta>
  );
};

export default PrivacyPolicyPage;
