import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import component
import HeaderLinks from "components/Header/HeaderLinks.js";
import Header from "components/Header/Header.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// import action
import { getCMSPage } from '../actions/commonAction';
// Slick Carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Blog from "../components/Blog"


const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
var settingsBlogCategory = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Cryptonews = (props) => {
  const { ...rest } = props;
  // state
  const [content, setContent] = useState('');

  // function
  const fetchCmsPage = async () => {
    try {
      const { status, loading, result } = await getCMSPage('about_us');
      if (status == 'success') {
        setContent(result.content)
        document.title = result.title;
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchCmsPage()
  }, [])

  // redux
  const theme = useSelector(state => state.theme)

  return (
    <div className="page_wrap">
            <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Helmet application" />
      </Helmet>
      <ScrollToTopOnMount />
      <Header className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark",
        }}
        {...rest} />

        <Blog/>
      {/* <div className="dashboardContent userPages">
        <div className="container">


          <GridContainer>
            <GridItem md={12} className="text-center">
              <h2 class="main_title">India's Most Trusted Bitcoin & Crypto Blog</h2>
            </GridItem>
          </GridContainer>

          <div className="blog_inner wow fadeIn">
            <div className="blogCategory mb-4">
              <Slider {...settingsBlogCategory}>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 1</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 2</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 3</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 4</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 5</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 6</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 7</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 8</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 9</button>
                </div>
                <div className="blogCategoryItem" data-aos="flip-left" data-aos-delay="300">
                  <button className="btn btn-primary text-uppercase py-2 m-0">Category Name 10</button>
                </div>

              </Slider>
            </div>

            
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_1.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                          <p>The Sichuan Energy Regulatory Office said Thursday it will meet June 2 to discuss crypto mining activities amid China’s nationwide crackdown.</p>
                          <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_2.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>DeFi'Rasies Challenges' For Invester,Regulators Gensler Says </h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>DEFI</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_3.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_4.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Meme.com. a Platform Pairing Memes With Tokens Raises $5M</h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>MEMES</span></p>
                          <p><span className="first_span">Jamie Crawley</span><span>May,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_1.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                          <p>The Sichuan Energy Regulatory Office said Thursday it will meet June 2 to discuss crypto mining activities amid China’s nationwide crackdown.</p>
                          <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_2.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>DeFi'Rasies Challenges' For Invester,Regulators Gensler Says </h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>DEFI</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_1.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                          <p>The Sichuan Energy Regulatory Office said Thursday it will meet June 2 to discuss crypto mining activities amid China’s nationwide crackdown.</p>
                          <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_2.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>DeFi'Rasies Challenges' For Invester,Regulators Gensler Says </h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>DEFI</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_3.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_4.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Meme.com. a Platform Pairing Memes With Tokens Raises $5M</h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>MEMES</span></p>
                          <p><span className="first_span">Jamie Crawley</span><span>May,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_1.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>Sichuan Energy Regulator to meet to Bitcoin Mining:Report</h3>
                          <p>The Sichuan Energy Regulatory Office said Thursday it will meet June 2 to discuss crypto mining activities amid China’s nationwide crackdown.</p>
                          <p className="blog_category">Category: <span>Bitcoin Mining</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                  <GridItem sm={12} md={4} className="text-center">
                    <Link to="/blog-description">
                      <div className="news_section">
                        <img alt="App" src={require("../assets/images/news_2.png")} className="img-fluid" />
                        <div className="news_content">
                          <h3>DeFi'Rasies Challenges' For Invester,Regulators Gensler Says </h3>
                          <p className="d-flex justify-content-between">Decentralized finance (DeFi) could pose fresh challenges for U.S. investors, Securities and Exchange Commission (SEC) Chair Gary Gensler said Wednesday.</p>
                          <p className="blog_category">Category: <span>DEFI</span></p>
                          <p className="d-flex justify-content-between"><span className="first_span">Jamie Crawley</span><span>May 27,2021</span></p>
                        </div>
                      </div>
                    </Link>
                  </GridItem>
                </GridContainer>
                <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" tabindex="-1">Previous</a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#">Next</a>
                    </li>
                  </ul>
                </nav>
              </GridItem>

            </GridContainer>

          </div>

        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default Cryptonews;