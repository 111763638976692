import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "../../lib/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
// @material-ui/core components
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Switch from "@material-ui/core/Switch";
import { toastAlert } from "../../lib/toastAlert";
import { TimeAgo } from "@n1ru4l/react-time-ago";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CountdownTimer from "timer-countdown";
//import { io } from "socket.io-client";
import SocketContext from "../Context/SocketContext";

// import action file
import {
  getSingleBuyAdDetails,
  closeTradeAction,
  getSingleOrderDetails,
  getOrderStatus,
  check2FA,
  getChatDetails,
  saveChatData,
  confirmPay,
  releaseCryptocurrency,
  cancelTrade,
  disputeTrade,
  getbankdetail,
} from "../../actions/p2paction";

// import lib
import config from "../../config";
const socketURL = config.SOCKET_URL;

const chatFormValue = {
  senderid: "",
  userid: "",
  msg: "",
  proofImage: "",
};
console.log("configconffig", config.SOCKET_URL);
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function (props) {
  const { t, i18n } = useTranslation();
  const socketContext = useContext(SocketContext);

  const [twoFACode, setTwoFACode] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [timeleft, setTimeleft] = useState(0);
  const [timeStatus, setTimeStatus] = useState(true);
  const [timecomplete, setTimecomplete] = useState("");
  const [chats, setChats] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [tradeStatusNum, setTradeStatusNum] = useState(0);
  const [tradeStatus, setTradeStatus] = useState("");
  const [fromUserId, setFromUserId] = useState("");
  const [orderBookId, setOrderBookId] = useState("");
  const [confirmpayDisable, setConfirmpayDisable] = useState(false);
  const [releaseDisable, setReleaseDisable] = useState(false);
  const [cancelDisable, setCancelDisable] = useState(false);
  // const [twoFa, settwoFa]= useState("");
  const [chatformValue, setChatFormValue] = useState(chatFormValue);
  const [messenger, setMessenger] = useState();
  const authDetails = useSelector((state) => state.auth);
  const [filname, setFilename] = useState("");
  const chatsEndRef = useRef(null);
  const [bank, setbank] = useState({});
  const [showbank, setshowbank] = useState();

  const [upi, setupi] = useState({});
  const [gpay, setgpay] = useState({});

  const scrollToBottom = () => {
    chatsEndRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  //chat formvalue
  const { senderid, userid, msg, proofImage } = chatformValue;
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const formatRemainingTime = (time) => {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setTimecomplete("Yes");
      setTimeleft(0);
      return <div className="timer">Too late...</div>;
    }
    return (
      <div className="timer">
        <div className="value">{formatRemainingTime(remainingTime)}</div>
      </div>
    );
  };

  const handleChange1 = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    if (name == "twoFACode") {
      if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
        return;
      }
      setTwoFACode(value);
      // settwoFa(value)
    }
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;
  const handleMessageChange = (event) => {
    setChatMessage(event.target.value);
  };

  //  Send message
  const handleSend = async (e) => {
    let messenger;
    if (orderDetails.BuyorSell == "Sell") {
      setMessenger("Seller");
    }
    if (orderDetails.BuyorSell == "Buy") {
      setMessenger("Buyer");
    }
    let reqData = {
      id: id,
      userid: userId,
      msg: chatMessage,
    };

    const formData = new FormData();
    formData.append("senderid", id);
    formData.append("userid", userId);
    formData.append("msg", chatMessage);
    formData.append("proofImage", proofImage);

    var { result, status, message, error } = await saveChatData(
      formData,
      dispatch
    );
    setChatFormValue(chatFormValue);
    setFilename("");
    if (status == true) {
      toastAlert("success", message, "login");
      setChatMessage("");
    } else {
      toastAlert("error", message, "login");
    }
  };
  //  Confirm pay
  const handleConfirmPay = async (e) => {
    setConfirmpayDisable(true);
    if (timecomplete && timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }
    // let id = id;
    let userid = userId;
    var reqData = {};
    reqData.id = id;
    reqData.userid = userid;
    let { result, status, message, error } = await confirmPay(
      reqData,
      dispatch
    );
    if (status == false) {
      toastAlert("error", message, "login");
    } else {
      getBuyad();
      toastAlert("success", message, "login");
      setTradeStatus("Payment Confirmed.");
    }
    setConfirmpayDisable(false);
  };

  // Handle release asset
  const enable2FA = async (e) => {
    let { result, status, message, error } = await check2FA();
    if (status == "failed")
      toastAlert("error", "Activate 2FA to release asset", "login");
    else {
      window.$("#2fa_enable").modal("show");
    }
  };

  const handlereleaseCryptocurrency = async () => {
    if (timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }
    setReleaseDisable(true);
    let userid = userId;
    var reqData = {};
    reqData.id = id;
    reqData.userid = userid;
    reqData.twoFACode = twoFACode;
    let { result, status, message, error } = await releaseCryptocurrency(
      reqData,
      dispatch
    );

    if (status == false) {
      toastAlert("error", message);
    } else {
      toastAlert("success", message, "login");
      window.$("#2fa_enable").modal("hide");
    }
    setReleaseDisable(false);
  };

  //  Handle cancel trade
  const handleconfirmCancel = async (e) => {
    setCancelDisable(true);
    if (timecomplete == "Yes") {
      toastAlert("error", "Sorry. Time Exceeded..", "login");
      return false;
    }

    var reqData = {
      id,
      userid: userId,
    };
    let { status, message, error } = await cancelTrade(reqData, dispatch);
    window.$("#cancel_trade").modal("hide");
    if (status == "failed") {
      toastAlert("error", message, "login");
    } else {
      toastAlert("success", message, "login");
      getBuyad();
    }
    setCancelDisable(false);
  };

  // Handle dispute trade
  const handleDispute = async (e) => {
    let userid = userId;
    var reqData = {};
    reqData.id = id;
    reqData.userid = userid;
    let { result, status, message, error } = await disputeTrade(
      reqData,
      dispatch
    );
    if (status == "failed") {
      toastAlert("error", message, "login");
    } else {
      toastAlert("success", message, "login");
    }
    setCancelDisable(false);
  };

  // var socket = io(socketURL, { transports: ["polling"] });
  //   // this.socket = io('http://localhost:2053', { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false }); //live

  //   // let id = this.props.match.params.id;

  //   // useEffect(() => {
  //     socket.on("-" + id, function (data) {
  //         fetchGetChatData(data);
  //       });

  const closeTrade = async () => {
    try {
      let reqData = {
        orderId: id,
      };
      let { result, loading, message, error } = await closeTradeAction(reqData);
      if (result) {
        console.log("Trade Closed");
      }
    } catch (err) {
      console.log("err Closetrade---", err);
    }
  };

  const fetchGetChatData = async (data) => {
    try {
      let reqData = { orderId: id };
      let { result, loading, message, error } = await getOrderStatus(
        reqData,
        dispatch
      );
      if (result == 4) {
        setTimecomplete("Yes");
        toastAlert("success", "Trade Completed Successfully.", "login");
      }
      setTradeStatusNum(result);
    } catch (err) {}
    var finalChat = data[0];
    setChats((prev) => [...prev, finalChat]);
    scrollToBottom();
  };

  const getBuyad = async () => {
    try {
      let reqData = { orderId: id };
      setTimeStatus(false);
      let { result, status, message, error } = await getSingleOrderDetails(
        reqData,
        dispatch
      );
      if (result) {
        var result1 = result[0];
        console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnn", result1.tradedetails);
        setFromUserId(result1 && result1.from_userId);
        setOrderBookId(result[0]._id);
        setOrderDetails(result1);
        // test(result1.tradedetails.userId)
        if (result1.tradedetails.transferMode == "Bank") {
          result1 &&
            result1.tradedetails.bankDetails &&
            result1.tradedetails.bankDetails.length > 0 &&
            result1.tradedetails.bankDetails.map((item, i) => {
              console.log("bbbbbbbbbbbbbbbbbbbbbbbb", item);
              if (item.isPrimary) {
                setbank([item]);
              }
            });
        }
        if (result1.tradedetails.transferMode == "Gpay") {
          result1 &&
            result1.tradedetails.qrDetails &&
            result1.tradedetails.qrDetails.length > 0 &&
            result1.tradedetails.qrDetails.map((item, i) => {
              console.log("bbbbbbbbbbbbbbbbbbbbbbbb", item);
              if (item.isPrimary) {
                setgpay([item]);
              }
            });
        }
        if (result1.tradedetails.transferMode == "UPI") {
          result1 &&
            result1.tradedetails.upiDetails &&
            result1.tradedetails.upiDetails.length > 0 &&
            result1.tradedetails.upiDetails.map((item, i) => {
              console.log("bbbbbbbbbbbbbbbbbbbbbbbb", item);
              if (item.isPrimary) {
                setupi([item]);
              }
            });
        }
        setshowbank(result1.tradedetails.transferMode);
        // if()
        if (status == false) {
          this.props.history.push("/p2p");
        } else {
          var utcDate = new Date().toISOString();
          var currentTime = new Date(utcDate).getTime();
          var EndTime = new Date(result[0].end_time).getTime();
          setTimeStatus(true);
          if (EndTime > currentTime) {
            var remainleft = (EndTime - currentTime) / 1000;
            setTimeleft(remainleft);
          } else {
            setTimecomplete("Yes");
          }
          setTradeStatusNum(result[0].status);
        }
      }
    } catch (err) {}
  };

  const test = async (user) => {
    let { result, status, message, error } = await getbankdetail(user);
    console.log("aaaaaaaaaaaaaaaaaaaaaa", result);
    if (result) {
      result &&
        result.bankDetails.length > 0 &&
        result.bankDetails.map((item, i) => {
          console.log("bbbbbbbbbbbbbbbbbbbbbbbb", item);
          if (item.isPrimary) {
            setbank([item]);
          }
        });
      result &&
        result.upiDetails.length > 0 &&
        result.upiDetails.map((item, i) => {
          // console.log("bbbbbbbbbbbbbbbbbbbbbbbb", item)
          if (item.isPrimary) {
            setupi(item);
          }
        });
      result &&
        result.qrDetails.length > 0 &&
        result.qrDetails.map((item, i) => {
          console.log("bbbbbbbbbbbbbbbbbbbbbbbb", item);
          if (item.isPrimary) {
            setgpay(item);
          }
        });
    }
  };

  const getchatdata = async () => {
    try {
      let reqData = { orderId: id };
      let { result, status, message, error } = await getChatDetails(
        reqData,
        dispatch
      );
      if (status == false) {
        history.push("/p2p");
      } else {
        setChats(result.reverse());
        scrollToBottom();
      }
    } catch (err) {}
  };
  const handleFile = async (e) => {
    const { name, files } = e.target;
    let formData = { ...chatformValue, ...{ [name]: files[0] } };
    setFilename(files[0].name);
    setChatFormValue(formData);
  };
  useEffect(() => {
    getBuyad();
    getchatdata();
    if (authDetails.userId) {
      setUserId(authDetails.userId);
    }
  }, [authDetails]);

  const completed = () => {
    setTimecomplete("Yes");
  };
  const tick = (leftTime) => {
    console.log(leftTime);
  };
  console.log("order type======", orderDetails.BuyorSell);
  useEffect(() => {
    if (id && id != undefined) {
      socketContext.socket.on("p2pchat-" + id, (data) => {
        fetchGetChatData(data);
      });
    }
  }, [id]);

  // redux
  const theme = useSelector((state) => state.theme);

  return (
    // <div className="dashboard_container page_wrap">
    //   <ScrollToTopOnMount />

    <div className="dashboardMain">
      <div className="dashboardRight afterLoginHeader">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            theme == "dark" ? (
              <img
                src={require("../../assets/images/logo_white.svg")}
                alt="logo"
                className="img-fluid"
              />
            ) : (
              <img
                src={require("../../assets/images/logo.svg")}
                alt="logo"
                className="img-fluid"
              />
            )
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />
        <div className="dashboardContent userPages peer2peer_section">
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}>
                <h3 className="dash_title">
                  Peer-to-Peer{" "}
                  <span>
                    {" "}
                    <i class="fas fa-chevron-right arrow_key_titlw"></i>{" "}
                  </span>{" "}
                  <span className="color_gol-sd">
                    {fromUserId == userId ? "SELL" : "BUY"}{" "}
                    {orderDetails && orderDetails.firstCurrency}
                  </span>
                </h3>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="dashboard_box t2patid">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={7}>
                      <div className="chat_content_center">
                        <div>
                          <p>Final Amount</p>
                          <h4>
                            {orderDetails && orderDetails.fiat_amount}{" "}
                            {orderDetails && orderDetails.secondCurrency}
                          </h4>
                        </div>
                        <div>
                          <p>Price</p>
                          <h4>
                            {orderDetails && orderDetails.fiat_amount}{" "}
                            {orderDetails && orderDetails.secondCurrency}
                          </h4>
                        </div>
                        <div>
                          <p>Quantity</p>
                          <h4>
                            {orderDetails &&
                              orderDetails.crypto_amount &&
                              orderDetails.crypto_amount.toFixed(4)}{" "}
                            {orderDetails && orderDetails.firstCurrency}
                          </h4>
                        </div>
                      </div>
                      <div className="text-center chat_conte_po">
                        {timeleft != "" &&
                          (tradeStatusNum == 0 || tradeStatusNum == 1) && (
                            /*(orderDetails.status != 1) &&*/ <div>
                              <h3>Payment to be made</h3>
                              <div className="new_timer">
                                <CountdownCircleTimer
                                  isPlaying
                                  duration={1800}
                                  //duration={300}
                                  initialRemainingTime={timeleft}
                                  colors={[
                                    ["#0b3e8f", 0.33],
                                    ["#0b3e8f", 0.33],
                                    ["#0b3e8f", 0.33],
                                  ]}
                                >
                                  {renderTime}
                                </CountdownCircleTimer>
                                {/* <CountdownTimer
                                  className="timer_dolor"
                                  timeLeft={timeleft}
                                  completeCallback={() => completed()}
                                  tickCallback={() => tick()}
                                /> */}
                              </div>

                              <h3>Minutes</h3>
                              <p>
                                Please make a payment within 30 mins, otherwise,
                                the order will be cancelled automatically.
                              </p>
                              {/* <p>
                                Payment Details:{" "}
                                {orderDetails &&
                                orderDetails.tradedetails &&
                                orderDetails.tradedetails.transferMode ==
                                  "Gpay" ? (
                                  <img
                                    src={
                                      orderDetails.tradedetails.paymentDetail
                                    }
                                    height="100"
                                    width="100"
                                  />
                                ) : (
                                  orderDetails.tradedetails.paymentDetail
                                )}
                              </p> */}
                              <div className="button_section_po_chat">
                                {/*{fromUserId &&
                                      fromUserId &&
                                      userId &&
                                      userId &&
                                      fromUserId != userId && (*/}
                                {fromUserId &&
                                  fromUserId &&
                                  userId &&
                                  userId &&
                                  fromUserId != userId &&
                                  orderDetails.status == 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-primary text-uppercase py-2  ma-aut0pdd"
                                      onClick={() => handleConfirmPay()}
                                    >
                                      Transfered Payment
                                    </button>
                                  )}
                                {/*{fromUserId && userId && fromUserId == userId && (*/}
                                {fromUserId &&
                                  userId &&
                                  fromUserId == userId &&
                                  (orderDetails.status == 0 ||
                                    orderDetails.status == 1) && (
                                    <button
                                      type="button"
                                      className="btn btn-primary text-uppercase py-2  ma-aut0pdd"
                                      onClick={() => enable2FA()}
                                    >
                                      Release Asset
                                    </button>
                                  )}
                                {orderDetails.status == 0 && (
                                  <button
                                    className="buttonOrangeBorder"
                                    onClick={() => handleconfirmCancel()}
                                  >
                                    Cancel Order
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        {timecomplete == "Yes" && tradeStatusNum == 1 && (
                          <button
                            onClick={() => handleDispute()}
                            className="button_take_control btn btn-primary"
                          >
                            Dispute trade
                          </button>
                        )}

                        {tradeStatusNum == 5 && (
                          <h5>Time Exceeded - Trade Closed</h5>
                        )}
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={5}>
                      <div className="chat_box_dark">
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <div className="chat_message_section">
                              {chats &&
                                chats.length > 0 &&
                                chats.map((item, i) => {
                                  var imagepath =
                                    config.API_URL +
                                    "/images/chat/" +
                                    item.attachment;

                                  var email = item.senderdetails.email;

                                  var name_chat =
                                    item &&
                                    item.admin &&
                                    item.admin != undefined
                                      ? "Admin"
                                      : email &&
                                        email.substring(
                                          0,
                                          email.lastIndexOf("@")
                                        );

                                  return (
                                    <div
                                      className={
                                        item.Sender_userId != userId
                                          ? "message_section messAge_recive"
                                          : "message_section message_send"
                                      }
                                    >
                                      <div>
                                        <span className="new_caht_span_deati">
                                          <span>{name_chat && name_chat}</span>
                                          <span>
                                            <TimeAgo
                                              date={new Date(item.created_at)}
                                            >
                                              {({ value }) => value}
                                            </TimeAgo>
                                          </span>
                                        </span>{" "}
                                        <p>
                                          {item.message}
                                          {item.attachment != "" ? (
                                            <>
                                              <i class="fas fa-paperclip"></i>{" "}
                                              <a
                                                href={imagepath}
                                                target="_blank"
                                              >
                                                {item.attachment}
                                              </a>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                        <br />
                                      </div>
                                    </div>
                                  );
                                })}
                              <div ref={chatsEndRef} />
                            </div>
                          </GridItem>
                          {tradeStatusNum != 2 &&
                            tradeStatusNum != 4 &&
                            tradeStatusNum != 5 && (
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="footer_chat">
                                  <div className="form-group">
                                    <div className="input_position">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="write message..."
                                        name="message"
                                        value={chatMessage}
                                        onChange={handleMessageChange}
                                      />
                                      <div className="flx_amount_just">
                                        <div className="button_section_chat">
                                          <div>
                                            <i class="fas fa-paperclip"></i>
                                            <input
                                              type="file"
                                              name="proofImage"
                                              onChange={handleFile}
                                            />
                                          </div>
                                        </div>
                                        <i
                                          class="fas fa-paper-plane"
                                          onClick={() => handleSend()}
                                        ></i>
                                      </div>
                                      <span style={{ color: "red" }}>
                                        {filname && filname}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            )}
                        </GridContainer>
                      </div>
                    </GridItem>
                  </GridContainer>

                  <GridItem xs={12} sm={12} md={6} lg={12}>
                    <div className="tab_bank_s border_bottimdd">
                      <nav>
                        <div
                          class="nav nav-tabs primaryNav"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            class="nav-item nav-link active"
                            id="nav-bank-tab"
                            data-toggle="tab"
                            href="#nav-bank"
                            role="tab"
                            aria-controls="nav-bank"
                            aria-selected="true"
                          >
                            Bank{" "}
                          </a>
                          <a
                            class="nav-item nav-link"
                            id="nav-gpay-tab"
                            data-toggle="tab"
                            href="#nav-gpay"
                            role="tab"
                            aria-controls="nav-gpay"
                            aria-selected="false"
                          >
                            Gpay
                          </a>
                          <a
                            class="nav-item nav-link"
                            id="nav-upi-tab"
                            data-toggle="tab"
                            href="#nav-upi"
                            role="tab"
                            aria-controls="nav-upi"
                            aria-selected="false"
                          >
                            UPI
                          </a>
                        </div>
                      </nav>
                      <div className="tab-content">
                        <div
                          class="tab-pane fade show active p-3"
                          id="nav-bank"
                          role="tabpanel"
                          aria-labelledby="nav-bank-tab"
                        >
                          <div className="account_details_section">
                            <h4>
                              Transfer the funds to the sellers's account
                              provided below.
                            </h4>

                            {showbank == "Bank" && bank && !isEmpty(bank) && (
                              <GridContainer className="mt-3 grid_border">
                                <GridItem xs={12} sm={12} md={12} lg={5}>
                                  <div className="Upi_font_tessaas">
                                    <div>
                                      <div className="upli_lavelee">
                                        <label>Bank Name</label>
                                        <p>
                                          {bank[0].bankName}
                                          <CopyToClipboard
                                            text={bank[0] && bank[0].bankName}
                                            onCopy={() => {
                                              toastAlert(
                                                "success",
                                                "Copied!",
                                                "twoFa"
                                              );
                                            }}
                                          >
                                            <i className="fas fa-copy ml-2"></i>
                                          </CopyToClipboard>
                                        </p>
                                      </div>
                                      <div className="upli_lavelee">
                                        <label>Holder Name</label>
                                        <p>
                                          {bank[0].holderName}
                                          <CopyToClipboard
                                            text={bank[0] && bank[0].holderName}
                                            onCopy={() => {
                                              toastAlert(
                                                "success",
                                                "Copied!",
                                                "twoFa"
                                              );
                                            }}
                                          >
                                            <i className="fas fa-copy ml-2"></i>
                                          </CopyToClipboard>
                                        </p>
                                      </div>
                                      <div className="upli_lavelee">
                                        <label>Account Number</label>
                                        <p>
                                          {bank[0].accountNo}
                                          <CopyToClipboard
                                            text={bank[0] && bank[0].accountNo}
                                            onCopy={() => {
                                              toastAlert(
                                                "success",
                                                "Copied!",
                                                "twoFa"
                                              );
                                            }}
                                          >
                                            <i className="fas fa-copy ml-2"></i>
                                          </CopyToClipboard>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="upli_lavelee">
                                      <label>IFSC Code/IBAN Code</label>
                                      <p>
                                        {bank[0].bankcode}
                                        <CopyToClipboard
                                          text={bank[0] && bank[0].bankcode}
                                          onCopy={() => {
                                            toastAlert(
                                              "success",
                                              "Copied!",
                                              "twoFa"
                                            );
                                          }}
                                        >
                                          <i className="fas fa-copy ml-2"></i>
                                        </CopyToClipboard>
                                      </p>
                                    </div>
                                  </div>
                                </GridItem>
                              </GridContainer>
                            )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade p-3"
                          id="nav-gpay"
                          role="tabpanel"
                          aria-labelledby="nav-gpay-tab"
                        >
                          <div className="account_details_section">
                            <h4>
                              Transfer the funds to the sellers's account
                              provided below.
                            </h4>

                            {showbank == "Gpay" && gpay && !isEmpty(gpay) && (
                              <GridContainer className="mt-3 grid_border">
                                <GridItem xs={12} sm={12} md={12} lg={5}>
                                  <div className="Upi_font_tessaas">
                                    <img
                                      src={
                                        config.API_URL +
                                        "/images/qr/" +
                                        gpay[0].frontImage
                                      }
                                      alt="QR"
                                      className="img-fluid"
                                    />
                                  </div>
                                </GridItem>
                              </GridContainer>
                            )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade p-3"
                          id="nav-upi"
                          role="tabpanel"
                          aria-labelledby="nav-upi-tab"
                        >
                          <div className="account_details_section">
                            <h4>
                              Transfer the funds to the sellers's account
                              provided below.
                            </h4>
                            {console.log(
                              "vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv",
                              upi
                            )}
                            {showbank == "UPI" && upi && !isEmpty(upi) && (
                              <GridContainer className="mt-3 grid_border">
                                <GridItem xs={12} sm={12} md={12} lg={5}>
                                  <div className="Upi_font_tessaas">
                                    <div>
                                      <div className="upli_lavelee">
                                        <label>UPI ID</label>
                                        <p>
                                          {upi[0].upiId}
                                          <CopyToClipboard
                                            text={upi && upi.upiId}
                                            onCopy={() => {
                                              toastAlert(
                                                "success",
                                                "Copied!",
                                                "twoFa"
                                              );
                                            }}
                                          >
                                            <i className="fas fa-copy ml-2"></i>
                                          </CopyToClipboard>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </GridItem>
                              </GridContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </GridItem>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="2fa_enable"
        tabindex="-1"
        role="dialog"
        aria-labelledby="2fa_enable"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body ">
                <h2>2FA code Verification</h2>
                <img
                  src={require("../../assets/images/postconfirm.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="model_detail_text justify-content-center">
                  <div className="form-group pl-3 pr-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("ENTER_TWO_FA_CODE")}
                      name="twoFACode"
                      value={twoFACode}
                      onChange={handleChange1}
                    />
                    {/* {validateError.twoFACode && <p className="error-message">{t(validateError.twoFACode)}</p>} */}
                  </div>
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="button1"
                    onClick={handlereleaseCryptocurrency}
                  >
                    Release
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}

      <Footer />
    </div>
    // </div>
  );
}
