// import package
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import DataTable from 'react-data-table-component';
import { Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../lib/dateTimeHelper';
// import action
import { getTrnxHistory } from '../../actions/walletAction'
import isEmpty from '../../lib/isEmpty';

const columns = [
    {
        name: 'Date',
        selector: 'date',
        sortable: false,
    },
    {
        name: 'Type',
        selector: 'type',
        sortable: false,
    },
    {
        name: 'Currency',
        selector: 'currency',
        sortable: false,
    },
    {
        name: 'Amount',
        selector: 'amount',
        sortable: false,
    },
    {
        name: 'Transaction Ref.',
        selector: 'transRef',
        sortable: false,
    },
    {
        name: 'Address',
        selector: 'toaddress',
        sortable: false,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
    },
];

const CryptoHistory = (props) => {
    // props
    const { currencyOption } = props;
    const { t, i18n } = useTranslation();
    // state
    const [loader, setLoader] = useState(true)
    const [record, setRecord] = useState({
        'data': [],
        'count': 0
    })
    const [option, setOption] = useState([])
    const [filter, setFilter] = useState({
        'currencyId': '',
        'paymentType': 'all',
        'search': '',
        'page': 1,
        'limit': 5
    })

    const { currencyId, paymentType } = filter

    // function
    const fetchHistory = async (reqQuery) => {
        try {
            const { status, loading, result } = await getTrnxHistory('crypto', reqQuery)
            setLoader(loading)
            if (status == 'success') {
                if (result && result.data && result.data.length > 0) {

                    console.log("resultresultresultresult",result)
                    let resultArr = []
                    result.data.map((item) => {
                        resultArr.push({
                            'date':dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm'),
                            'type':  item.paymentType=="new"?"Pending":t(item.paymentType),
                            'currency': item.currencySymbol,
                            'amount': item.amount,
                            'transRef': item._id,
                            'bankAccount': isEmpty(item.bankDetail) ? '-' : `${item.bankDetail.bankName}-${item.bankDetail.accountNo}`,
                            'toaddress':isEmpty(item.toaddress) ? item.toaddress:"-",
                            'status': <div className="textStatusGreen">{item.status}</div>
                        })

                        //textStatusOrange,textStatusGreen
                    })
                    setRecord({
                        'data': resultArr,
                        count: result.count
                    })
                }
            }
        } catch (err) { }
    }

    const handlePageChange = page => {
        let filterData = { ...filter, ...{ 'page': page } }
        setFilter(filterData)
        fetchHistory(filterData)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
        setFilter(filterData)
        fetchHistory(filterData)
    };


    useEffect(() => {
        if (currencyOption && currencyOption.length > 0) {
            let optionData = currencyOption.filter((el => el.type == 'crypto'))
            if (optionData && optionData.length > 0) {
                setOption(optionData)
                let filterData = { ...filter, ...{ 'currencyId': optionData[0]._id } }
                setFilter(filterData)
                fetchHistory(filterData)
            }
        }
    }, [currencyOption])

    return (
        <div className="dashboard_box stakingHistoryTable">
            <div className="newUsersFilter contact_form settingsSelect mb-0 historyPageFilter">
                {/* <div className="newsSelectGroup">
                    <label>Filter by</label>
                    <Select value="0">
                        <MenuItem value={0}>Withdraw</MenuItem>
                        <MenuItem value={20}>Deposit</MenuItem>
                        <MenuItem value={30}>Transfer</MenuItem>
                    </Select>
                    <Select value={currencyId} className="marginSpace">
                        {
                            currencyOption && currencyOption.length > 0 && currencyOption.map((item) => {
                                if (item.type == 'fiat') {
                                    return (
                                        <MenuItem value={item._id}>
                                            {item.currencySymbol}
                                        </MenuItem>
                                    )
                                }
                            })
                        }
                    </Select>
                    <div className="tableSearchBox">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search by Date / Trans.Ref / Bank" />
                            <div class="input-group-append">
                                <span class="btnType1"><i class="fas fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="newsSelectGroup">
                    <button className="btn btn-outline text-uppercase py-1 m-0">Download PDF</button>
                </div> */}
            </div>
            <DataTable
            className="historyCryptoTable"
                columns={columns}
                data={record.data}
                paginationTotalRows={record.count}
                paginationRowsPerPageOptions={[5,10,15,20,500]}
                noHeader
                paginationPerPage={5}
                progressPending={loader}
                pagination
                paginationServer
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </div>
    )
}

CryptoHistory.propTypes = {
    currencyOption: PropTypes.array.isRequired
};

CryptoHistory.defaultProps = {
    currencyOption: [],
};

export default CryptoHistory;